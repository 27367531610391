import React from "react";
const RecoverPassword =()=>{
    return(
        <div className="form-page otpverify">
        <div className="row m-auto">

            <div className="col-lg-6 bg-white formRight position-relative d-flex align-items-center justify-content-center py-5">
                <img src={require("../../../assets/images/assistlogo.png")} alt="assistlogo" className="assistlogo position-absolute pb-5"/>
                <img src={require("../../../assets/images/form-top.png")} alt="formtop" className="formtop position-absolute" />
                <img src={require("../../../assets/images/form-bottom.png")} alt="formbottom" className="formbottom position-absolute bottom-0" />
                <div className="main-form font-poppins">
                <form action="#" method="post">
                        <div className="form-head">
                            <img src= {require("../../../assets/images/otpicon.png")} className="mb-5 otpicon" alt=""/>
                            <h2 className="fw-bold">Recover Password</h2>
                            <h6 className="text-muted">To reset your password, please follow the instructions below.</h6>
                        </div>

                        <div className="form-group input-group position-relative mt-3">
                            <label for="password" className="text-muted">New Password</label>
                            <div className="icon-input w-100 rounded mt-2 position-relative">
                                <img src= {require("../../../assets/images/lock.png")} alt="lockIcon" className="position-absolute" />
                                <input type="password" className="form-control border-0 py-3 w-100 text-black" placeholder="**********" aria-label="Username" aria-describedby="basic-addon1" />
                                <div className="eye-icons position-absolute">
                                <img src= {require("../../../assets/images/eye.png")} alt="emailIcon" className="me-2" />
                                </div>
                            </div>
                        </div>

                        <div className="form-group input-group position-relative mt-3">
                            <label for="password" className="text-muted">Confirm Password</label>
                            <div className="icon-input w-100 rounded mt-2 position-relative">
                                <img src= {require("../../../assets/images/lock.png")} alt="lockIcon" className="position-absolute" />
                                <input type="password" className="form-control border-0 py-3 w-100 text-black" placeholder="assiterp@#123" aria-label="Username" aria-describedby="basic-addon1" />
                                <div className="eye-icons position-absolute">
                                <img src= {require("../../../assets/images/eye-hide.png")} alt="eyehideIcon" className="me-2" />
                                </div>
                            </div>
                        </div>

                        <div className="error-text mt-3 d-flex align-items-start">
                            <img src=  {require("../../../assets/images/tick.png")} alt="tickIcon" className="me-2" />
                            <p className="mb-0">A combination of uppercase letters, lowercase letters, numbers, and symbols.</p>
                        </div>


                        <div className="form-group mt-3 text-center">
                            <input type="submit" value="Done" className="w-100 sub-button rounded-4 border-none border py-3 text-white" />
                        </div>
                    </form>
                </div>
            </div>
            
            <div className="col-lg-6 formLeft vh-100">
                <div className="left-text position-relative vh-100">
                    <div className="text-bottom pe-5 lg:pe-0 mt-0 lg:mt-5 position-absolute">
                        <h2 className="text-white font-impact mb-0 lg:mb-5">Assisterp</h2>
                        <p className="fw-light mt-4 text-white font-poppins">It is a long-established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                    </div>

                </div>
            </div>

        </div>
    </div>
    )
}
export default RecoverPassword;