import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
const GetOtp = () => {
  const [otpValue, setOtpValue] = useState();
  const navigate = useNavigate();

  const changePassword = () => {
    navigate("/admin/changepassword");
  };
  return (
    <div className="form-page otpverify">
      <div className="row m-auto">
        <div className="col-lg-6 bg-white formRight position-relative d-flex align-items-center justify-content-center py-5">
          <img
            src={require("../../../assets/images/assistlogo.png")}
            alt="assistlogo"
            className="assistlogo position-absolute pb-5"
          />
          <img
            src={require("../../../assets/images/form-top.png")}
            alt="formtop"
            className="formtop position-absolute"
          />
          <img
            src={require("../../../assets/images/form-bottom.png")}
            alt="formbottom"
            className="formbottom position-absolute bottom-0"
          />
          <div className="main-form font-poppins">
            <form action="#" method="post">
              <div className="form-head">
                <img
                  src={require("../../../assets/images/otpicon.png")}
                  className="mb-5 otpicon"
                  alt=""
                />
                <h2 className="fw-bold">Enter OTP</h2>
                <h6 className="text-muted">
                  Please enter the One-Time Password (OTP) that we have just
                  sent to your email address.
                </h6>
              </div>

              <div className="form-area mt-4">
                {/* <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2 align-items-center"> 
                                <input className="m-2 text-center form-control rounded" type="text" id="first" maxlength="1" /> 
                                <input className="m-2 text-center form-control rounded" type="text" id="second" maxlength="1" /> 
                                <input className="m-2 text-center form-control rounded" type="text" id="third" maxlength="1" /> 
                                <span className="fs-1">-</span>
                                <input className="m-2 text-center form-control rounded" type="text" id="fourth" maxlength="1" /> 
                                <input className="m-2 text-center form-control rounded" type="text" id="fifth" maxlength="1" /> 
                                <input className="m-2 text-center form-control rounded" type="text" id="sixth" maxlength="1" /> 
                            </div> */}
                {/* <div className="justify-content-between">
                            <OtpInput
                            value={otpValue} 
                            onChange={setOtpValue}
                            numInputs={6}
                            placeholder="------"
                            renderSeparator={(index) => index === 2 ? <span>-</span> : null}
                            renderInput={(props) => (
                                <input {...props} className="otp_control " />
                            )}
                            />
                            </div> */}
                <div className="mt-4 otp_box">
                  <OtpInput
                    value={otpValue}
                    onChange={setOtpValue}
                    numInputs={6}
                    //   placeholder="------"
                    renderSeparator={<span>&nbsp;</span>}
                    renderInput={(props) => (
                      <input {...props} className="otp_control" />
                    )}
                  />
                  <div className="getotp text-end pt-2">
                  <h4>Didn't get the OTP?<button  className="bg-transparent text-primary border-0 ms-1 p-0">Resend</button></h4>
                  </div>
                  <div class="form-group mt-3 text-center">
                    <button
                      type="submit"
                      className="w-100  btn btn-primary  rounded-4  border-none border  py-3 text-white"
                      disabled={otpValue?.length !== 6}
                       onClick={changePassword}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="col-lg-6 formLeft vh-100">
          <div className="left-text position-relative vh-100">
            <div className="text-bottom pe-5 lg:pe-0 mt-0 lg:mt-5 position-absolute">
              <h2 className="text-white font-impact mb-0 lg:mb-5">Assisterp</h2>
              <p className="fw-light mt-4 text-white font-poppins">
                It is a long-established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GetOtp;
