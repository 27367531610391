import React from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";

const SuperAdminSideBar = ({ isToggled, toggleSidebar }) => {
  const location = useLocation();

  const isNavLinkActive = (to) => {
    return location.pathname === to;
  }
  const handleCancelMenuClick = () => {
    toggleSidebar(false);
  };
  const handleClick = () => {
    const token = JSON.parse(localStorage.getItem('__assist_erp'));
    const url = `https://assisterp.com/backend/telescope?token=${token?.access_token}`;
    window.open(url, '_blank', 'noopener noreferrer');
  };
  return (
    <>
      <div className="overlay-sidebar">
        <div className="cancel-menu" onClick={handleCancelMenuClick}>
          <img src={require("../../../assets/images/cross.png")} alt="" />
        </div>
      </div>
      <div className={`bg-white sidebar-main ${isToggled ? "toggled" : ""}`} id="sidebar-wrapper">


        <div className="sidebar-heading text-center py-4 primary-text fs-4 fw-bold text-uppercase">
          <div className="logo-header text-center">
            <img src={require("../../../assets/images/header-logo.png")} alt="" />
          </div>
        </div>
        <ul className="mt-2 siderbar-nav font-poppins">
          <li
            className={`ms-4 position-relative ${isNavLinkActive("/superAdmin/dashboard") ? "active" : ""
              }`}
          >
            <NavLink to="/superAdmin/dashboard" className="pe-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <path
                  id="Dashboard"
                  d="M568.54,1167h3.38a2.52,2.52,0,0,1,.976.2,2.673,2.673,0,0,1,.825.55,2.542,2.542,0,0,1,.739,1.81v3.41a2.541,2.541,0,0,1-.737,1.81,2.36,2.36,0,0,1-.827.55,2.521,2.521,0,0,1-.976.2h-3.38a2.516,2.516,0,0,1-.975-.2,2.676,2.676,0,0,1-.826-.55,2.554,2.554,0,0,1-.739-1.81v-3.41a2.563,2.563,0,0,1,2.54-2.56Zm0,11.47h3.38a2.529,2.529,0,0,1,.977.2,2.365,2.365,0,0,1,.826.55,2.541,2.541,0,0,1,.737,1.81v3.41a2.553,2.553,0,0,1-.739,1.81,2.678,2.678,0,0,1-.826.55,2.516,2.516,0,0,1-.975.2h-3.38a2.563,2.563,0,0,1-2.54-2.56v-3.41a2.554,2.554,0,0,1,.739-1.81,2.676,2.676,0,0,1,.826-.55A2.516,2.516,0,0,1,568.54,1178.47ZM583.46,1167h-3.38a2.52,2.52,0,0,0-.976.2,2.673,2.673,0,0,0-.825.55,2.542,2.542,0,0,0-.739,1.81v3.41a2.541,2.541,0,0,0,.737,1.81,2.369,2.369,0,0,0,.827.55,2.521,2.521,0,0,0,.976.2h3.38a2.516,2.516,0,0,0,.975-.2,2.676,2.676,0,0,0,.826-.55,2.554,2.554,0,0,0,.739-1.81v-3.41a2.563,2.563,0,0,0-2.54-2.56Zm-3.38,11.47h3.38a2.52,2.52,0,0,1,.976.2,2.672,2.672,0,0,1,.825.55,2.554,2.554,0,0,1,.739,1.81v3.41a2.563,2.563,0,0,1-2.54,2.56h-3.38a2.516,2.516,0,0,1-.975-.2,2.678,2.678,0,0,1-.826-.55,2.553,2.553,0,0,1-.739-1.81v-3.41a2.541,2.541,0,0,1,.737-1.81,2.365,2.365,0,0,1,.826-.55A2.529,2.529,0,0,1,580.08,1178.47Z"
                  transform="translate(-566 -1167)"
                />
              </svg>
              <h5 className="mt-2">Dashboard</h5>
            </NavLink>
            <div className="border-li"></div>
          </li>
          <li className={`ms-4 position-relative ${isNavLinkActive("/superAdmin/tenant") ? "active" : ""
            }`}>
            <NavLink to="/superAdmin/tenant" className="pe-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="16.002"
                viewBox="0 0 22 16.002"
              >
                <path
                  id="Clients"
                  d="M1150.16,1283.23a4.221,4.221,0,0,1-3.41,4.11,4.261,4.261,0,0,1-2.8-.43,4.22,4.22,0,0,1-2.02-4.94,4.246,4.246,0,0,1,1.7-2.27,4.192,4.192,0,0,1,5.3.54,4.084,4.084,0,0,1,.92,1.37A4.318,4.318,0,0,1,1150.16,1283.23Zm-4.21,11.77c-3.43,0-6.36-.54-6.36-2.72s2.91-2.74,6.36-2.74c3.43,0,6.36.54,6.36,2.72S1149.4,1295,1145.95,1295Zm6.01-11.69a5.777,5.777,0,0,1-.99,3.24.184.184,0,0,0-.02.07.194.194,0,0,0,.01.08.1.1,0,0,0,.05.06.091.091,0,0,0,.07.03,2.616,2.616,0,0,0,.48.05,3.5,3.5,0,0,0,3.53-2.62,3.581,3.581,0,0,0-.6-3.1,3.533,3.533,0,0,0-2.83-1.41,3.323,3.323,0,0,0-.7.07.105.105,0,0,0-.09.05.109.109,0,0,0-.01.05l.03.06A5.8,5.8,0,0,1,1151.96,1283.31Zm2.72,5.2a2.783,2.783,0,0,1,1.22.39,2.825,2.825,0,0,1,.91.91,1.837,1.837,0,0,1,.19.83,1.879,1.879,0,0,1-.19.84c-.46,1-1.94,1.32-2.52,1.4a.2.2,0,0,1-.08-.01.18.18,0,0,1-.07-.05.16.16,0,0,1-.04-.07.2.2,0,0,1-.01-.08,4.429,4.429,0,0,0-2.66-4.38l-.02-.02v-.03a.01.01,0,0,1,.01-.01c0-.01.01-.01.02-.01a14.7,14.7,0,0,1,3.24.3Zm-14.24-1.67a3.928,3.928,0,0,0,.48-.05.15.15,0,0,0,.07-.03.265.265,0,0,0,.05-.06.113.113,0,0,0,.01-.08.144.144,0,0,0-.02-.07,5.834,5.834,0,0,1,.08-6.62.219.219,0,0,0,.03-.05.127.127,0,0,0-.01-.06.22.22,0,0,0-.08-.05,4.373,4.373,0,0,0-.71-.07,3.581,3.581,0,0,0-3.49,2.9,3.7,3.7,0,0,0,.06,1.62,3.5,3.5,0,0,0,3.53,2.62Zm.16,1.41a.037.037,0,0,1-.01.03c0,.01-.01.01-.02.02a4.467,4.467,0,0,0-2.66,4.38.215.215,0,0,1,0,.08.263.263,0,0,1-.12.12.214.214,0,0,1-.08,0c-.57-.08-2.06-.4-2.52-1.4a1.977,1.977,0,0,1-.19-.83,2.022,2.022,0,0,1,.19-.84,2.9,2.9,0,0,1,.91-.9,2.8,2.8,0,0,1,1.22-.4,16.1,16.1,0,0,1,3.25-.28.031.031,0,0,1,.02.01l.01.01Z"
                  transform="translate(-1135 -1278.998)"
                  fill=""
                />
              </svg>
              <h5 className="mt-2">Tenant</h5>
            </NavLink>
            <div className="border-li"></div>
          </li>
          <li className="ms-4 position-relative">
            <a onClick={handleClick} className="pe-3 nav-link" style={{ background: 'none', border: 'none' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="16.002"
                viewBox="0 0 22 16.002"
              >
                <path
                  id="Clients"
                  d="M1150.16,1283.23a4.221,4.221,0,0,1-3.41,4.11,4.261,4.261,0,0,1-2.8-.43,4.22,4.22,0,0,1-2.02-4.94,4.246,4.246,0,0,1,1.7-2.27,4.192,4.192,0,0,1,5.3.54,4.084,4.084,0,0,1,.92,1.37A4.318,4.318,0,0,1,1150.16,1283.23Zm-4.21,11.77c-3.43,0-6.36-.54-6.36-2.72s2.91-2.74,6.36-2.74c3.43,0,6.36.54,6.36,2.72S1149.4,1295,1145.95,1295Zm6.01-11.69a5.777,5.777,0,0,1-.99,3.24.184.184,0,0,0-.02.07.194.194,0,0,0,.01.08.1.1,0,0,0,.05.06.091.091,0,0,0,.07.03,2.616,2.616,0,0,0,.48.05,3.5,3.5,0,0,0,3.53-2.62,3.581,3.581,0,0,0-.6-3.1,3.533,3.533,0,0,0-2.83-1.41,3.323,3.323,0,0,0-.7.07.105.105,0,0,0-.09.05.109.109,0,0,0-.01.05l.03.06A5.8,5.8,0,0,1,1151.96,1283.31Zm2.72,5.2a2.783,2.783,0,0,1,1.22.39,2.825,2.825,0,0,1,.91.91,1.837,1.837,0,0,1,.19.83,1.879,1.879,0,0,1-.19.84c-.46,1-1.94,1.32-2.52,1.4a.2.2,0,0,1-.08-.01.18.18,0,0,1-.07-.05.16.16,0,0,1-.04-.07.2.2,0,0,1-.01-.08,4.429,4.429,0,0,0-2.66-4.38l-.02-.02v-.03a.01.01,0,0,1,.01-.01c0-.01.01-.01.02-.01a14.7,14.7,0,0,1,3.24.3Zm-14.24-1.67a3.928,3.928,0,0,0,.48-.05.15.15,0,0,0,.07-.03.265.265,0,0,0,.05-.06.113.113,0,0,0,.01-.08.144.144,0,0,0-.02-.07,5.834,5.834,0,0,1,.08-6.62.219.219,0,0,0,.03-.05.127.127,0,0,0-.01-.06.22.22,0,0,0-.08-.05,4.373,4.373,0,0,0-.71-.07,3.581,3.581,0,0,0-3.49,2.9,3.7,3.7,0,0,0,.06,1.62,3.5,3.5,0,0,0,3.53,2.62Zm.16,1.41a.037.037,0,0,1-.01.03c0,.01-.01.01-.02.02a4.467,4.467,0,0,0-2.66,4.38.215.215,0,0,1,0,.08.263.263,0,0,1-.12.12.214.214,0,0,1-.08,0c-.57-.08-2.06-.4-2.52-1.4a1.977,1.977,0,0,1-.19-.83,2.022,2.022,0,0,1,.19-.84,2.9,2.9,0,0,1,.91-.9,2.8,2.8,0,0,1,1.22-.4,16.1,16.1,0,0,1,3.25-.28.031.031,0,0,1,.02.01l.01.01Z"
                  transform="translate(-1135 -1278.998)"
                  fill=""
                />
              </svg>
              <h5 className="mt-2">Logs</h5>
            </a>
            <div className="border-li"></div>
          </li>

        </ul>
      </div>
    </>
  )
}
export default SuperAdminSideBar;