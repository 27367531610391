// Import the data from the static JSON file
import data from "../staticList.json";

// Main function that dynamically handles any data category
export const dataCategory = (category) => {
    // Function to get a list of all entries from the specified category
    const getList = () => {
        // Check if category exists in the data
        if (!data[category]) {
            return []; // Return empty array if the category does not exist
        }
        // Return list of items in the category
        return Object.entries(data[category]).map(([key, value]) => ({
            key: key,
            value: value
        }));
    };

    // Function to get a specific entry by id from the specified category
    const getName = (id) => {
        // Check if category exists and then check for the id
        if (!data[category] || !data[category][id]) {
            return 'Not found'; // Return 'Not found' if the id or category does not exist
        }
        // Return the specific name
        return data[category][id];
    };

    // Return the inner functions to make them accessible from outside
    return { getList, getName };
}

export const Gender = dataCategory('Gender');
export const Marital_Status = dataCategory('Marital_Status');
export const Residency_Status = dataCategory('Residency_Status');
export const Occupation_Category = dataCategory('Occupation_Category');
export const Client_Category = dataCategory('Client_Category');
export const Refer_by_Client = dataCategory('Client_Category');
export const Businesstype_id = dataCategory('Businesstype_id');
export const Businessnature_id = dataCategory("Businessnature_id");
export const Businesscategory_id = dataCategory("Businesscategory_id");
export const Authform_id = dataCategory("Authform_id");
export const Authform_id_1013 = dataCategory("Authform_id_1013");
export const Data_entry_style = dataCategory("Data_entry_style");
export const Business_status = dataCategory("Business_status");
export const Hst_open = dataCategory("Hst_open");
export const Hst_return = dataCategory("Hst_return");
export const Hst_style = dataCategory("Hst_style");
export const Payroll_ac = dataCategory("Payroll_ac");
export const Arc_setup = dataCategory("Arc_setup");
export const Arc_acess = dataCategory("Arc_acess");
export const Arc_grid = dataCategory("Arc_grid");
export const Agreement_sign = dataCategory("Agreement_sign");
export const Agreement_reason = dataCategory("Agreement_reason");
export const Advance_fee = dataCategory("Advance_fee");
export const Staff_id = dataCategory("Staff_id");
export const Assistant_id = dataCategory("Assistant_id");
export const Bookkeeper_id = dataCategory("Bookkeeper_id");
export const Communicator_id = dataCategory("Communicator_id");
export const Status_id = dataCategory("Status_id");



