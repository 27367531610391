import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
const Add_Task_Interaction = ({ showInderaction, setShowInderaction, handleInteractionData }) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    // const { showInderaction, setShowInderaction, handleInteractionData } = props;

    const addIntraction = () => {
        http(
            {
                method: "POST",
                url: `interaction_type/add`,
                isSecure: true,
                body: {
                    name: formValues.name,
                },
            },
            (res) => {
                toast.success("Add Intraction Successfully")
                setShowInderaction(false);
                handleInteractionData();
                reset();
            },
            (err) => {

            }
        );
    }
    return (
        <Modal
            show={showInderaction}
            onHide={setShowInderaction}
            centered

            contentClassName="mx-auto shadow-main p-3"
            className="modal pin-modal font-poppins shadow-main"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header border-0 justify-content-between p-0">
                <h4 className="fs-20">Add Business Type</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>

            <div class="services-form">
                <form
                    onSubmit={handleSubmit(addIntraction)}
                >
                    <label className="mt-2">Interaction Type</label>
                    <div className="text-start">
                        <input
                            name="name"
                            id="name"
                            class="form-input mt-2 mx-0"
                            type="text"
                            {...register("name", {
                                required: "required"
                            })}
                        />
                        {errors?.name && <span className="error">{errors?.name.message}</span>}
                    </div>
                    <div className="modal-footer border-0 justify-content-end p-0">
                        <button type="submit" class="btn-blue mt-4">Add</button>
                    </div>

                </form>
            </div>
        </Modal>
    )
}
export default Add_Task_Interaction;