
import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

const Breadcrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);
    const clientUser = JSON.parse(localStorage.getItem("ClientUserData"));
    const corporatetUser = JSON.parse(localStorage.getItem("CorporateUserData"));



    const breadcrumbNameMap = {
        "superAdmin": "Super Admin",
        "login": "Login",
        "dashboard": "Dashboard",
        "tenant": "Tenant Management",
        "edit_profile": "Edit Profile",
        "admin": "Admin",
        "signin": "Sign In",
        "review": "Review",
        "unverified": "Email Unverified",
        "forgetpassword": "Forget Password",
        "otp": "OTP",
        "changepassword": "Change Password",
        "client": "Client",
        "add_individual": "Add Individual Client",
        "add_business": "Add Business Client",
        "business_profile": "Business Profile",
        "individual_profile": "Individual Profile",
        "note": "Notes",
        "notedetail": "Note Details",
        "corporate_note": "Corporate Notes",
        "note_detail": "Note Detail",
        "corporate_task": "Corporate Task",
        "individual_task": "Individual Task",
        "individual_task_detail": "Task Detail",
        "add_task": "Add Task",
        "add_tasks": "Add Task",
        "add_recurring_task": "Add Recurring Task",
        "recurring_add_task": "Add Recurring Task",
        "business_recurring_add_task": "Add Recurring Task",
        "recurring_task_detail": "Recurring Task Details",
        "individual_recurring_task_detail": "Recurring Task Detail",
        "business_task_detail": "Task Detail",
        "recurring_task_detail": "Recurring Task Detail",        // "task": "Tasks",
        "edit_task": "Edit Task Details",
        "invoice_detail": "Invoice Details",
        "add_invoice": "Add Invoice",
        "add_reccuring_invoice": "Add Reccuring Invoice",
        "invoice_preview": "Invoice Preview",
        "reccuring_preview": "Reccuring Invoice",
        "edit_invoice": "Edit Invoice",
        "edit_reccuring_invoice": "Edit Reccuring Invoice",
        "add_custom_form": "Add Custom Form"
    };

    const clientImageUrl = "../../../assets/images/user-pic.png"; // Replace with your actual image URL
    // const clientName = ; // Replace with your actual client name

    const isNumeric = (str) => /^\d+$/.test(str);

    if (pathnames.length > 0 && isNumeric(pathnames[pathnames.length - 1])) {
        pathnames.pop();
    }

    const capitalize = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const generateBreadcrumbItems = () => {
        let items = [];
        let showClientInfo = false;

        for (let index = 0; index < pathnames.length; index++) {
            const value = pathnames[index];
            const isLast = index === pathnames.length - 1;

            let name = breadcrumbNameMap[value] || value;
            name = capitalize(name);

            if (value === "business_profile" || value === "individual_profile") {
                name = breadcrumbNameMap[value];
            }

            if (index === 0 && value === "admin") continue;

            const toPath = value === "invoice" ? `/admin/invoice/invoice_detail` : `/${pathnames.slice(0, index + 1).join("/")}`;

            if (value === "client") {
                items.push(
                    <li key={value} className={`breadcrumb-item`} aria-current={isLast ? 'page' : undefined} >
                        <Link className="fs-24 text-secondary" to={toPath}>
                            {name}
                        </Link>
                    </li >
                );
                showClientInfo = true;
            } else if (value === "note" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 20, height: 20, marginRight: 5 }} />
                        <Link className="fs-18 text-black" to={toPath}>{clientUser} {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            } else if (value === "individual_task" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 20, height: 20, marginRight: 5 }} />
                        <Link className="fs-18 text-black" to={toPath}>{clientUser} {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "corporate_task" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 20, height: 20, marginRight: 5 }} />
                        <Link className="fs-18 text-black" to={toPath}>{corporatetUser} {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }

            else if (value === "individual_profile" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 20, height: 20, marginRight: 5 }} />
                        {clientUser} {name}
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "business_profile" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 20, height: 20, marginRight: 5 }} />
                        {corporatetUser} {name}
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }

            else {
                items.push(
                    <li key={value} className={`breadcrumb-item  fs-18 text-black ${isLast ? ' active' : ''}`} aria-current={isLast ? 'page' : undefined}>
                        {isLast ? (
                            name
                        ) : (
                            <Link className="fs-24 text-black" to={toPath}>
                                {name}
                            </Link>
                        )}
                    </li>
                );
            }
        }

        return items;
    };

    const shouldUpdateTitle = false;

    useEffect(() => {
        if (shouldUpdateTitle) {
            const title = pathnames
                .map((path) => breadcrumbNameMap[path])
                .filter((name) => name)
                .join(" > ") || "Admin";
            document.title = title;
        }
    }, [pathnames, shouldUpdateTitle]);

    return (
        <Breadcrumb>
            {generateBreadcrumbItems()}
        </Breadcrumb>
    );
};

export default Breadcrumbs;








