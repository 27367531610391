import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { http } from "../../http/http";
import { GlobalContext } from "../../../App";
import { setUserData, selectUser } from "../../../reduxTool/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { domainName } from "../../../helpers/help";

const AdminSignIn = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectUser);
  const [domainName1, setDomainName1] = useState(domainName());

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({ mode: "all" });

  const { setShowLoader } = useContext(GlobalContext);
  const [users, setUsers] = useState([]); // Array to hold multiple users
  const [activeTab, setActiveTab] = useState("signup");
  const formValues = watch();
  const [selectedType, setSelectedType] = useState('2'); // Default to "Sub Domain"


  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleFormSubmit = (data, e) => {
    setShowLoader(true);
    e.preventDefault();
    const newUser = {
      ...formValues,
    };

    setUsers([...users, newUser]);

    const storedValues = newUser;
    Object.keys(storedValues).forEach((key) => {
      setValue(key, storedValues[key]);
      reset();
    });
    dispatch(setUserData(formValues));

    http(
      {
        method: "POST",
        url: "tenants/existing",
        isSecure: true,
        body: {
          email: formValues.email,
          domain: formValues.domain,
        },
      },
      (res) => {
        if (res?.status_code == 200) {
          localStorage.setItem("formValues", JSON.stringify(formValues));
          toast.error(res.message);
          setShowLoader(false);
          navigate("/admin/review");
        }
      },
      (err) => {
        setShowLoader(false); // Hide loader on error
        toast.error(err.message); // Show error message
        // if (err?.status == 401) {
        //   for (const key in err.message) {
        //     if (err.message.hasOwnProperty(key)) {
        //       const errorArray = err.message[key];
        //       errorArray.forEach((message) => {
        //         toast.error(` ${message}`);
        //         setShowLoader(false);
        //       });
        //     }
        //   }
        // }
      }
    );
  };

  const isFormEmpty = () => {
    // Check if each field is empty
    return (
      !formValues.first_name ||
      !formValues.last_name ||
      !formValues.email ||
      !formValues.company_name ||
      !formValues.domain
    );
  };

  return (
    <div className="form-page">
      <div className="row m-auto">
        <div className="col-lg-6 formLeft vh-100">
          <div className="left-text position-relative vh-100">
            <img
              src={require("../../../assets/images/assist-logo.png")}
              alt="logo"
              className="w-16"
            />

            <div className="text-bottom pe-5 lg:pe-0 mt-0 lg:mt-5 position-absolute">
              <h2 className="text-white font-impact mb-0 lg:mb-5">Assisterp</h2>
              <p className="fw-light mt-4 text-white font-poppins">
                It is a long-established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 bg-white formRight position-relative d-flex align-items-center justify-content-center py-5">
          <img
            src={require("../../../assets/images/form-top.png")}
            alt="formtop"
            className="formtop position-absolute"
          />
          <img
            src={require("../../../assets/images/form-bottom.png")}
            alt="formbottom"
            className="formbottom position-absolute bottom-0"
          />
          {/* FORM START */}
          <div className="main-form font-poppins">
            <form
              onSubmit={(e) => handleSubmit(handleFormSubmit(formValues, e))}
            >
              <div className="form-head">
                <h2 className="fw-bold">Create Account</h2>
                <h6 className="text-muted">Create your new account below.</h6>
              </div>

              <div className="form-area mt-4">
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <div className="form-group input-group position-relative">
                      <label for="firstname" className="text-muted">
                        First name
                      </label>
                      <div className="icon-input w-100 rounded mt-2 position-relative">
                        <img
                          src={require("../../../assets/images/user.png")}
                          alt="emailIcon"
                          className="position-absolute"
                        />
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          className="form-control border-0 py-3 w-100 bg-red"
                          placeholder="John"
                          {...register("first_name", {
                            required: true,
                            pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/,
                          })}
                        />
                        {errors.first_name?.type === "required" && (
                          <p
                            role="alert"
                            className="text-danger mb-0 pt-1 -fs-7 ms-1"
                          >
                            Required
                          </p>
                        )}
                        {errors.first_name &&
                          errors.first_name.type === "pattern" && (
                            <p
                              role="alert"
                              className="text-danger mb-0 pt-1 -fs-7 ms-1"
                            >
                              At least add one alphabetic
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="form-group input-group position-relative">
                      <label for="lastname" className="text-muted">
                        Last name
                      </label>
                      <div className="icon-input w-100 rounded mt-2 position-relative">
                        <img
                          src={require("../../../assets/images/user.png")}
                          alt="emailIcon"
                          className="position-absolute"
                        />
                        <input
                          type="text"
                          name="last_name"
                          id="last_name"
                          className="form-control border-0 py-3 w-100 bg-red"
                          placeholder="Smith"
                          {...register("last_name", {
                            required: true,
                            pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/,
                          })}
                        />
                        {errors.last_name?.type === "required" && (
                          <p
                            role="alert"
                            className="text-danger mb-0 pt-1 -fs-7 ms-1"
                          >
                            Required
                          </p>
                        )}
                        {errors.last_name &&
                          errors.last_name.type === "pattern" && (
                            <p
                              role="alert"
                              className="text-danger mb-0 pt-1-fs-7 ms-1"
                            >
                              At least add one alphabetic
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group input-group position-relative mt-3">
                  <label for="email" className="text-muted">
                    Email address
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/email.png")}
                      alt="emailIcon"
                      className="position-absolute"
                    />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control border-0 py-3 w-100 bg-red"
                      placeholder="eg.@gmail.com"
                      {...register("email", {
                        required: true,
                        pattern: /\S+@\S+\.\S+/,
                      })}
                    />
                    {errors.email?.type === "required" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1-fs-7 ms-1"
                      >
                        Required
                      </p>
                    )}
                    {errors.email?.type === "pattern" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1 -fs-7 ms-1"
                      >
                        Incorrect email format
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group input-group position-relative mt-3">
                  <label for="name" className="text-muted">
                    Company Name
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/company.png")}
                      alt="companyname"
                      className="position-absolute"
                    />
                    <input
                      type="text"
                      name="company_name"
                      id="company_name"
                      className="form-control border-0 py-3 w-100 bg-red"
                      placeholder="eg.amazone"
                      {...register("company_name", {
                        required: true,
                      })}
                    />
                    {errors.company_name?.type === "required" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1 -fs-7 ms-1"
                      >
                        Required
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group input-group position-relative mt-3">
                  <label htmlFor="type" className="text-muted">
                    Type
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <select
                      name="type"
                      id="type"
                      className="form-input mt-2 "
                      {...register("type", {
                        required: true,
                      })}
                      onChange={handleTypeChange}
                      value={selectedType}
                    >
                      <option value="" disabled="disabled">
                        Select
                      </option>
                      <option value="1">Domain</option>
                      <option value="2">Sub Domain</option>
                    </select>
                    {errors.type?.type === "required" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1 -fs-7 ms-1"
                      >
                        Required
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group input-group position-relative mt-3">
                  <label for="name" className="text-muted">
                    Domain Name
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/domain.png")}
                      alt="emailIcon"
                      className="position-absolute"
                    />
                    <input
                      type="text"
                      name="domain"
                      id="domain"
                      className="form-control border-0 py-3 w-100 bg-red"
                      placeholder="eg.amazone.com"
                      {...register("domain", {
                        required: true,
                      })}
                    />
                    {errors.domain?.type === "required" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1 -fs-7 ms-1"
                      >
                        Required
                      </p>
                    )}
                    {selectedType === '2' && ( // Show only if "Sub Domain" is selected
                      <span className="position-absolute domain-text fw-bold">
                        {domainName1}
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-group mt-3 text-center">
                  <button
                    type="submit"
                    className=" w-100  btn btn-primary  rounded-4  border-none border  py-3 text-white"
                    disabled={isFormEmpty()}
                  >
                    Create Account
                  </button>
                </div>

                <div className="or-area d-flex align-items-center py-4">
                  <span className="d-block bg-gray w-100"></span>
                  <p className="m-0 px-2">or</p>
                  <span className="d-block bg-gray w-100"></span>
                </div>

                <div className="account-text text-center">
                  <h4>
                    Already have an account?{" "}
                    <Link to="/admin/login" className="text-decoration-none">
                      Login
                    </Link>
                  </h4>
                </div>
              </div>
            </form>
          </div>
          {/* FORM END */}
        </div>
      </div>
    </div>
  );
};
export default AdminSignIn;
