import React, { useState, useEffect } from "react";
import { http } from "../../http/http";

const SuperAdminTenant = () => {
    const [tenantsList, setTenantList] = useState();


    useEffect(() => {
        http(
            {
                method: "GET",
                url: "superadmin/tenants",
                isSecure: true,
            },
            (res) => {
                // Check if the response status is success
                if (res.status === "success") {
                    setTenantList(res.tenant?.data, "dtata");
                    // Handle the tenant data or perform any other actions
                } else {
                    console.error("Error:", res.message);
                    // Handle error cases if needed
                }
            },
            (err) => { }
        );
    }, []);

    return (
        <div className="right_section font-poppins">
            <div className="table-area row tenants">
                <div className="col-md-12 table-heading mt-3">
                    <h2 className="font-semibold ps-2">Tenant</h2>
                </div>
                <div className="col-md-12 table_part">
                    <div className="table-responsive">
                        <table className="w-100">
                            <thead className="pb-2">
                                <tr>
                                    <th className="px-2 py-3 d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            id="client"
                                            className="w-8 h-8"
                                        />
                                        <label
                                            for="client"
                                            className="d-block ms-2"
                                        >
                                            Client Name
                                        </label>
                                    </th>
                                    <th className="px-4 py-3">Display Name</th>
                                    <th className="px-4 py-3">Email</th>
                                    <th className="px-4 py-3">Phone No.</th>
                                    <th className="px-4 py-3">Client Type</th>
                                    <th className="px-4 py-3">Domain Name</th>
                                    <th className="px-4 py-3">Domain Suffix</th>
                                    <th className="px-4 py-3">Status</th>
                                    <th className="px-4 py-3">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tenantsList &&
                                    tenantsList.map((tenant) => (
                                        <tr key={tenant.id}>
                                            <td className="px-2 py-3">
                                                <div className="d-flex align-items-center">
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            id="clientone"
                                                            className="w-8 h-8"
                                                        />
                                                    </span>
                                                    <img
                                                        src={require("../../../assets/images/user-pic.png")}
                                                        className="ms-2"
                                                        alt=""
                                                    />
                                                    <label
                                                        for="clientone"
                                                        className="d-block ms-2"
                                                    >
                                                        {tenant?.first_name}
                                                    </label>
                                                </div>
                                            </td>
                                            <td className="px-4 py-3">
                                                {tenant?.name}
                                            </td>
                                            <td className="px-4 py-3">
                                                {tenant?.email}
                                            </td>
                                            <td className="px-4 py-3">
                                                801-282-8097 x4003
                                            </td>
                                            <td className="px-4 py-3">Director</td>
                                            <td className="px-4 py-3">
                                                {tenant?.domain?.domain}
                                            </td>
                                            <td className="px-4 py-3">{tenant?.company_name}</td>
                                            <td className="px-4 py-3">
                                                <div className="status d-flex align-items-center">
                                                    <span className="rounded-circle me-2 active"></span>{" "}
                                                    Active
                                                </div>
                                            </td>
                                            <td className="px-4 py-3">
                                                <div className="dropdown">
                                                    <button
                                                        type="button"
                                                        className="bg-transparent dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                    >
                                                        <img
                                                            src={require("../../../assets/images/more-icon.png")}
                                                            alt=""
                                                        />
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li className="py-1">
                                                            <a
                                                                className="dropdown-item text-primary"
                                                                href="#"
                                                            >
                                                                <i className="fa fa-eye"></i>
                                                                View
                                                            </a>
                                                        </li>
                                                        <li className="py-1">
                                                            <a
                                                                className="dropdown-item text-success"
                                                                href="#"
                                                            >
                                                                <i className="fa fa-pen"></i>
                                                                Edit
                                                            </a>
                                                        </li>
                                                        <li className="py-1">
                                                            <a
                                                                className="dropdown-item text-danger"
                                                                href="#"
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                                Delete
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            {/* Render other columns */}
                                        </tr>
                                    ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SuperAdminTenant;
