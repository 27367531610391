import Auth from "../../auth/Auth";

const getHeaders = ({
    method,
    contentType = "application/json",
    body,
    isSecure,
}) => ({
    method: method,
    headers: {
        Accept: "application/json",
        ...(contentType === "application/json" && {
            "Content-Type": contentType,
        }),
        ...(isSecure && { Authorization: "Bearer " + Auth.token() }),
    },
    ...(body && {
        body: contentType === "application/json" ? JSON.stringify(body) : body,
    }),
});

// export const http = async (
//     { method, url, body, isSecure, contentType },
//     onSuccess = () => "",
//     onError = () => ""
// ) => {
//     let urldomain = window.location.href;
//     let domain = new URL(urldomain).origin;

//     domain = domain + "/backend/api";

//     if (domain.includes("localhost") && process.env.REACT_APP_LOCAL_URL) {
//         url = process.env.REACT_APP_LOCAL_URL + "/" + url;
//     } else {
//         url = domain + "/" + url;
//     }
//     console.log(url);
//     const response = await fetch(
//         url,
//         getHeaders({ method, contentType, body, isSecure })
//     );

//     // REQ SUCCESS WITH RESPONSE
//     if (
//         response.status === 200 ||
//         (response.status > 200 && response.status <= 299)
//     ) {
//         const res = await response.json();
//         if (res.errorMessage === "Unauthenticated.") {
//             Auth.logout();
//             // alert("Session Expired!");
//             // window.location.reload();
//         }
//         onSuccess(res);
//     }
//     // REQ SUCCESS WITHOUT RESPONSE
//     // else if (response.status > 200 && response.status <= 299) {
//     //   const res = await response.json();
//     //     onSuccess(res);
//     // }
//     // UNAUTHENTICATED ERROR
//     else if (response.status === 403) {
//         const res = await response.json();
//         Auth.logout();
//         onError({ status: 403, message: res.error_description });
//     } else if (response.status == 401) {
//         const res = await response.json();


//         if (res.message == "The token has been blacklisted" || res.message == "Token has expired") {
//             Auth.logout();
//             onError({ status: 401, message: res.error_description });
//         }

//     }
//     // VALIDATION ERRORS
//     else if (response.status === 400) {
//         const res = await response.json();
//         //  return res;
//         //Auth.logout();
//         onError({ status: response.status || 400, message: res.error });
//     }
// };



export const http = async (
    { method, url, body, isSecure, contentType },
    onSuccess = () => { },
    onError = () => { }
) => {
    try {
        let urldomain = window.location.href;
        let domain = new URL(urldomain).origin;

        domain = domain + "/backend/api";

        if (domain.includes("localhost") && process.env.REACT_APP_LOCAL_URL) {
            url = process.env.REACT_APP_LOCAL_URL + "/" + url;
        } else {
            url = domain + "/" + url;
        }
        const response = await fetch(
            url,
            getHeaders({ method, contentType, body, isSecure })
        );

        const res = await response.json();

        if (response.ok) {
            if (res.errorMessage === "Unauthenticated.") {
                Auth.logout();
                // alert("Session Expired!");
                // window.location.reload();
            }
            onSuccess(res);
        } else {
            handleHttpError(response, res, onError);
        }
    } catch (error) {
        onError({ status: 500, message: "An unexpected error occurred." });
    }
};

const handleHttpError = (response, res, onError) => {
    console.log(res, "res")

    if (response.status === 403) {
        Auth.logout();
        onError({ status: 403, message: res.error_description || "Forbidden" });
    } else if (response.status === 401) {
        if (res.message === "The token has been blacklisted" || res.message === "Token has expired") {
            Auth.logout();
            onError({ status: 401, message: res.error_description || "Unauthorized" });
        } else {
            onError({ status: 401, message: res.message });
        }
    } else if (response.status === 400) {
        onError({ status: 400, message: res.error || "Bad Request" });
    } else {
        onError({ status: response.status, message: res.message || "An error occurred" });
    }
};
