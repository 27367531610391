// AdminHeader.js

import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "../../../auth/Auth";
import { http } from "../../http/http";
import Breadcrumbs from "./Breadcrumbs";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";


const AdminHeader = ({ toggleSidebar }) => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [isSearchToggled, setIsSearchToggled] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassowrd, setShowConfirmPassword] = useState(false);


    const handleAdminLogout = () => {
        http(
            {
                method: "POST",
                url: "logout",
                isSecure: true,

                body: {},
            },
            (res) => {
                if (res.status_code == 200) {
                    localStorage.removeItem("createPassword");
                    localStorage.removeItem("formValues");
                    Auth.logout();
                    navigate("/admin/login");
                    toast.success("logout Successfully.");
                }
            },
            (err) => {
                if (err.status_code == 401) {
                    navigate("/admin/login");
                }
            }
        );
    };

    const handleChangePassword = () => {
        if (formValues.new_password !== formValues.new_password_confirmation) {
            toast.error("Passwords do not match");
            return;
        }
        http(
            {
                method: "POST",
                url: "change-password",
                isSecure: true,
                body: {
                    current_password: formValues.current_password,
                    new_password: formValues.new_password,
                    new_password_confirmation: formValues.new_password_confirmation,
                },
            },
            (res) => {
                toast.success(res.message);

            },
            (err) => {

            }
        );
    };

    // Define the breadcrumb based on the current location
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    // const renderBreadcrumb = () => {
    //     const pathnames = location.pathname.split("/").filter((x) => x); // Split the path and remove empty elements
    //     const startIndex = pathnames.indexOf("admin") + 1; // Find the index of 'admin' and start rendering from the next path

    //     return (
    //         <nav aria-label="breadcrumb">
    //             <ol className="breadcrumb align-items-baseline mb-0">
    //                 {pathnames.slice(startIndex).map((path, index) => {
    //                     const routeTo = `/${pathnames
    //                         .slice(0, startIndex + index + 1)
    //                         .join("/")}`;
    //                     const isLast = index === pathnames.length - 1;
    //                     const displayName = capitalizeFirstLetter(path);
    //                     return (
    //                         <li
    //                             key={index}
    //                             className={`breadcrumb-item ${isLast ? "active" : ""
    //                                 }`}
    //                             aria-current={isLast ? "page" : undefined}
    //                         >
    //                             {isLast ? (
    //                                 <h6 className="mb-0 d-inline ">
    //                                     {displayName}
    //                                 </h6>
    //                             ) : (
    //                                 <Link
    //                                     to={routeTo}
    //                                     className="-link-dark text-decoration-none text-black"
    //                                 >
    //                                     {displayName}
    //                                 </Link>
    //                             )}
    //                         </li>
    //                     );
    //                 })}
    //             </ol>
    //         </nav>
    //     );
    // };

    const toggleSearch = () => {
        setIsSearchToggled(!isSearchToggled);
    };

    useEffect(() => {
        const userDataFromStorage = JSON.parse(localStorage.getItem("__assist_erp"));
        setUserData(userDataFromStorage?.user
        );
    }, []);


    return (
        <>
            {/* <!-- HEADER START --> */}
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-2 px-4 header-main font-poppins bg-white">
                <div className="mobile-menu">
                    <i
                        className="fas fa-align-left primary-text fs-4 me-3"
                        id="menu-toggle"
                        onClick={toggleSidebar}
                    ></i>
                </div>
                <div className="left-header d-flex align-items-center">
                    {/* <h2 className="m-0"> {renderBreadcrumb()}</h2> */}

                    <h2 className="m-0"><Breadcrumbs /> </h2>
                </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 right-header d-flex align-items-center ">
                        <li>
                            <div
                                className="searchicon position-relative"
                                onClick={toggleSearch}
                            >
                                <img
                                    src={require("../../../assets/images/search.png")}
                                    alt=""
                                />
                                <span className="d-block rounded-circle position-absolute top-0 end-0"></span>
                            </div>
                        </li>
                        <hr />
                        <li>
                            <div className="messageicon position-relative">
                                <img
                                    src={require("../../../assets/images/message.png")}
                                    alt=""
                                />
                                <span className="d-block rounded-circle position-absolute top-0 end-0"></span>
                            </div>
                        </li>
                        <hr />
                        <li>
                            <div
                                className="bellicon position-relative"
                                data-bs-target="#notification-profile"
                                data-bs-toggle="modal"
                            >
                                <img
                                    src={require("../../../assets/images/bell.png")}
                                    alt=""
                                />
                                <span className="d-block rounded-circle position-absolute top-0 end-0"></span>
                            </div>
                        </li>
                        <hr />
                        <li className="nav-item dropdown dropdown-profile">
                            <a
                                className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center profile"
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className="user-name me-2">
                                    <h4 className="text-black">{userData?.name}</h4>
                                    <p>Super Admin</p>
                                </div>
                                <img
                                    src={require("../../../assets/images/user-pic.png")}
                                    alt=""
                                />
                            </a>

                            <ul
                                className="dropdown-menu position-absolute ps-2 pe-3 drop-profile"
                                aria-labelledby="navbarDropdown"
                            >
                                <li className="profile d-flex align-items-center justify-content-between">
                                    <div className="left-dropprofile d-flex align-items-center">
                                        <img
                                            src={require("../../../assets/images/user-pic.png")}
                                            alt=""
                                        />
                                        <div className="user-name ms-2">
                                            <h4 className="text-black">
                                                {userData?.name}
                                            </h4>
                                            <p>Super Admin</p>
                                        </div>
                                    </div>
                                    <img
                                        src={require("../../../assets/images/drop_up.png")}
                                        className="hide-profile"
                                        alt=""
                                    />
                                </li>
                                <li>
                                    <span className="d-flex align-items-center ">
                                        <img
                                            src={require("../../../assets/images/email.png")}
                                            className="me-2 my-3"
                                            alt=""
                                        />
                                        {userData?.email}
                                    </span>
                                </li>
                                <li className="bg-gray-li mt-1 py-2 d-flex align-items-center justify-content-between pe-3 edit">
                                    <div className="dropdown-item">
                                        Edit Profile
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16.001"
                                        height="16"
                                        viewBox="0 0 16.001 16"
                                    >
                                        <g
                                            id="write"
                                            transform="translate(-7.998 -8)"
                                        >
                                            <path
                                                id="Path_3528"
                                                data-name="Path 3528"
                                                d="M8.341,27.144l-.336,2.779a.835.835,0,0,0,.832.945.821.821,0,0,0,.106-.007l2.779-.336a2.648,2.648,0,0,0,1.543-.758l7.2-7.2a.333.333,0,0,0,0-.471l-3.694-3.7a.333.333,0,0,0-.471,0L9.1,25.6a2.648,2.648,0,0,0-.758,1.543Z"
                                                transform="translate(0 -6.868)"
                                            />
                                            <path
                                                id="Path_3529"
                                                data-name="Path 3529"
                                                d="M42.715,10.23,40.992,8.506a1.728,1.728,0,0,0-2.444,0L36.93,10.124a.333.333,0,0,0,0,.471l3.7,3.7a.333.333,0,0,0,.471,0l1.618-1.618A1.728,1.728,0,0,0,42.715,10.23Z"
                                                transform="translate(-19.223 0)"
                                            />
                                            <path
                                                id="Path_3530"
                                                data-name="Path 3530"
                                                d="M38,52.667A.667.667,0,0,0,37.333,52H29.667a.667.667,0,0,0,0,1.333h7.667A.667.667,0,0,0,38,52.667Z"
                                                transform="translate(-14.001 -29.333)"
                                            />
                                        </g>
                                    </svg>
                                </li>
                                <li className="bg-gray-li mt-1 py-2 d-flex align-items-center justify-content-between pe-3 change" onClick={() => setPasswordModal(true)}>
                                    <div className="dropdown-item">
                                        Change Password
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13.515"
                                        height="16"
                                        viewBox="0 0 13.515 16"
                                    >
                                        <path
                                            id="Path_3567"
                                            data-name="Path 3567"
                                            d="M4009.646,845.272v1.219a3.269,3.269,0,0,1,1.7,1.183,3.31,3.31,0,0,1,.668,1.964v3.925a3.379,3.379,0,0,1-.271,1.282,3.334,3.334,0,0,1-.74,1.081,3.353,3.353,0,0,1-2.385.956H4001.9a3.351,3.351,0,0,1-2.385-.956,3.342,3.342,0,0,1-.74-1.081,3.388,3.388,0,0,1-.271-1.282v-3.925a3.3,3.3,0,0,1,.668-1.963,3.347,3.347,0,0,1,1.7-1.185v-1.219a4.4,4.4,0,0,1,2.711-4.055,4.382,4.382,0,0,1,4.778.952,4.373,4.373,0,0,1,1.288,3.1Zm-4.38-2.907a2.942,2.942,0,0,1,2.965,2.907v1.048h-5.947v-1.064a2.939,2.939,0,0,1,2.982-2.895Zm.7,10.109a.7.7,0,0,1-.207.5.707.707,0,0,1-1,0,.7.7,0,0,1-.207-.5v-1.754a.7.7,0,0,1,.207-.5.708.708,0,0,1,1,0,.7.7,0,0,1,.207.5Z"
                                            transform="translate(-3998.5 -840.883)"
                                            fillRule="evenodd"
                                        />
                                    </svg>
                                </li>
                                <li className="bg-gray-li mt-1 py-2 d-flex align-items-center justify-content-between pe-3 logout">
                                    <div
                                        className="dropdown-item"
                                        onClick={handleAdminLogout}
                                    >
                                        Logout
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16.624"
                                        height="16.001"
                                        viewBox="0 0 16.624 16.001"
                                    >
                                        <path
                                            id="Path_3568"
                                            data-name="Path 3568"
                                            d="M1239.32,1285.384a.616.616,0,0,0,0,1.232h4.88v3.824a3.718,3.718,0,0,1-.288,1.376,3.547,3.547,0,0,1-.784,1.152,3.48,3.48,0,0,1-1.176.768,3.537,3.537,0,0,1-1.376.264h-3.96a3.538,3.538,0,0,1-1.376-.264,3.634,3.634,0,0,1-1.168-.76,3.638,3.638,0,0,1-1.072-2.528v-8.888a3.72,3.72,0,0,1,.288-1.376,3.551,3.551,0,0,1,.784-1.152,3.484,3.484,0,0,1,1.176-.768,3.542,3.542,0,0,1,1.376-.264h3.968a3.592,3.592,0,0,1,3.608,3.552v3.832Zm7.784-2.152,2.336,2.328a.684.684,0,0,1,.136.2.643.643,0,0,1,.048.24.575.575,0,0,1-.048.232.592.592,0,0,1-.136.2l-2.336,2.328a.624.624,0,0,1-.432.168.6.6,0,0,1-.608-.608.624.624,0,0,1,.168-.432l1.28-1.272H1244.2v-1.232h3.312l-1.28-1.272a.608.608,0,0,1,0-.872.59.59,0,0,1,.2-.136.574.574,0,0,1,.232-.048.629.629,0,0,1,.24.04A.681.681,0,0,1,1247.1,1283.232Z"
                                            transform="translate(-1233 -1277.999)"
                                        />
                                    </svg>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    {isSearchToggled && (
                        <div className="search_field">
                            <form>
                                <div className="search_bar">
                                    <input
                                        type="search"
                                        placeholder="Search"
                                        className="form-control"
                                    />
                                    <input
                                        type="button"
                                        value="Search"
                                        className="btn btn-lg"
                                    />
                                    <div className="close_search">
                                        <img
                                            src="assets/images/cross.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </nav>

            {/* CHANGE PASSWORD MODAL START */}
            <Modal
                show={passwordModal}
                onHide={setPasswordModal}
                centered
                className="font-poppins p-0"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header d-block border-0 justify-content-between px-4 pt-4">
                    <h4 className="fs-20">Change Password</h4>
                    <p className="mt-1 text-grays">You have the option to modify your password below.</p>
                </div>


                <div className="row">
                    <div className="col-lg-12 col-md-12 px-4 pb-4">
                        <form
                            onSubmit={handleSubmit(handleChangePassword)}

                        >
                            <div className="form-group input-group position-relative mt-3">
                                <label className="text-muted">
                                    Old Password
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/lock.png")}
                                        alt="emailIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type={showPassword ? "text" : "current_password"}
                                        name="current_password"
                                        id="current_password"
                                        className="form-control border-0 py-3 w-100 bg-red"
                                        placeholder="**********"
                                        {...register("current_password", {
                                            required: true,
                                        })}
                                    />
                                    {errors.current_password?.type === "required" && (
                                        <p
                                            role="alert"
                                            className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                        >
                                            Required
                                        </p>
                                    )}
                                    {/* EYES ICON */}
                                    <div className="eye-icons position-absolute">
                                        <>
                                            {showPassword ? (
                                                <img
                                                    src={require("../../../assets/images/eye.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/eye-hide.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            )}
                                        </>
                                    </div>
                                    {/* EYES ICON */}
                                </div>
                            </div>
                            <div className="form-group input-group position-relative mt-3">
                                <label className="text-muted">
                                    New Password
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/lock.png")}
                                        alt="emailIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type={showNewPassword ? "text" : "new_password"}
                                        name="new_password"
                                        id="new_password"
                                        className="form-control border-0 py-3 w-100 bg-red"
                                        placeholder="**********"
                                        {...register("new_password", {
                                            required: "Required",
                                            minLength: {
                                                value: 8,
                                                message: "Password must be at least 8 characters long"
                                            }
                                        })}
                                    />
                                    {errors.new_password && (
                                        <p role="alert" className="text-danger mb-0 pt-1 -fs-7 ms-1">
                                            {errors.new_password.message}
                                        </p>
                                    )}
                                    {/* EYES ICON */}
                                    <div className="eye-icons position-absolute">
                                        <>
                                            {showNewPassword ? (
                                                <img
                                                    src={require("../../../assets/images/eye.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/eye-hide.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                />
                                            )}
                                        </>
                                    </div>
                                    {/* EYES ICON */}
                                </div>
                            </div>
                            <div className="form-group input-group position-relative mt-3">
                                <label className="text-muted">
                                    Confirm Password
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/lock.png")}
                                        alt="emailIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type={showConfirmPassowrd ? "text" : "new_password_confirmation"}
                                        name="new_password_confirmation"
                                        id="new_password_confirmation"
                                        className="form-control border-0 py-3 w-100 bg-red"
                                        placeholder="**********"
                                        {...register("new_password_confirmation", {
                                            required: "Required",
                                            minLength: {
                                                value: 8,
                                                message: "Password must be at least 8 characters long"
                                            },
                                            validate: value =>
                                                value === formValues.new_password || "Passwords do not match",
                                        })}
                                    />
                                    {errors.new_password_confirmation && (
                                        <p role="alert" className="text-danger mb-0 pt-1 -fs-7 ms-1">
                                            {errors.new_password_confirmation.message}
                                        </p>
                                    )}
                                    {/* EYES ICON */}
                                    <div className="eye-icons position-absolute">
                                        <>
                                            {showConfirmPassowrd ? (
                                                <img
                                                    src={require("../../../assets/images/eye.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassowrd)}
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/eye-hide.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassowrd)}
                                                />
                                            )}
                                        </>
                                    </div>
                                    {/* EYES ICON */}
                                </div>
                            </div>
                            <div className="form-btns text-end d-flex items-center flex-wrap gap-2 mt-4 justify-content-center p-0">
                                <button className="btn-transparent p-4" type="button" onClick={() => setPasswordModal(false)}>Cancel</button>
                                <button className="add-new p-4" type="submit">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            {/* CHANGE PASSWORD MODAL END */}

            {/* <!-- HEADER END --> */}
        </>
    );
};

export default AdminHeader;
