import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Add_HstOpen = (props) => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { isHstOpenFirst, setIsHstOpenFirst, showHstOpenList } = props;



    const addHStOpenHandle = () => {
        http(
            {
                method: "POST",
                url: `hst_open/add`,
                isSecure: true,
                body: {
                    name: formValues.name,
                },
            },
            (res) => {
                toast.success("Add Hst Open Successfully")
                setIsHstOpenFirst(false);
                showHstOpenList();

                reset();
            },
            (err) => {

            }
        );
    }

    return (
        <Modal
            show={isHstOpenFirst}
            onHide={setIsHstOpenFirst}
            centered

            contentClassName="mx-auto"
            className="modal pin-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add HST</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>

            <div className="services-form row">
                <div className="col-lg-4 col-md-6 mt-4">
                    <form onSubmit={handleSubmit(addHStOpenHandle)}>
                        <div className="modal-footer border-0 justify-content-center p-0">
                            <input
                                name="name"
                                id="name"
                                className="form-input mt-2"
                                type="text"
                                {...register("name", {
                                })}
                            />
                        </div>
                        <button type="submit" className="btn-blue">Add</button>
                    </form>
                </div>
            </div>
        </Modal>
    )
}
export default Add_HstOpen;