import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { http } from "../../http/http";
import Operation_Detail from "../modals/Operation_Detail";
import Advance_Details from "../modals/Advance_Details";
import Directors_Details from "../modals/Directors_Details";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Services from "../modals/Services";
import Assigent_Member from "../modals/Assigend_Member";
import Upload_Files from "../modals/Upload_Files";
import { toast } from "react-toastify";
import { Authform_id, Authform_id_1013, Status_id } from "../../../helpers/staticList";
import BusinessTabs from "../../common/BusinessTabs";
import ClientTabs from "../../common/ClientTabs";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';

const BusClientProfie = () => {
    const { id } = useParams();
    const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
    const formValues = watch();
    const [useProfile, setUserProfile] = useState()
    const [editProfileData, setEditProfileData] = useState()
    const [page, setPage] = useState(1);
    const [operationModal, setOperationModal] = useState(false);
    const [advanceModal, setAdvanceModal] = useState(false);
    const [directorModal, setDirectorModal] = useState(false);
    const [servicesModal, setServicesModal] = useState(false);
    const [assigentModal, setAssigentModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [advanceList, setAdvanceList] = useState();
    const [advanceDetail, setAdvanceDetail] = useState();
    const [editAdvanceModel, setEditAdvanceModel] = useState(false);
    const [operationData, setOperationData] = useState([]);
    const [showEditOperationData, setShowEditOperationData] = useState();
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);
    const [editOperationModal, setEditOperationModel] = useState(false);
    const [directorData, setDirectorData] = useState([]);
    const [activeDirectorIndex, setActiveDirectorIndex] = useState(null);
    const [servicePage, setServicePage] = useState(1);
    const [serviceList, setServiceList] = useState();
    console.log(serviceList, "serviceList");
    const [editServiceModal, setEditServiceModal] = useState(false);
    const [serviceDetail, setServiceDetail] = useState();
    const [assignedList, setAssignedList] = useState();
    const [editAssignedDetail, setEditAssignedDetail] = useState();
    const [editAssignedModal, setEditAssignedModal] = useState(false);
    const [editDirectorModal, setEditDirectorModal] = useState(false);
    const [showEditDirData, setShowEditDirData] = useState()
    const [allStaffApis, setAllStaffApi] = useState({
        staff_id: [],
        assistant_id: [],
        bookkeeper_id: [],
        communicator_id: []
    })
    const [allAdvanceApi, setAllAdvanceApi] = useState({
        businesstype_id: [],
        businessnature_id: [],
        businesscategory_id: [],
        data_entry_style_id: [],
        business_planing_id: []

    })
    const [hstList, setHstList] = useState([]);
    const [hstStyleList, setHstStyleList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [assignedListNew, setAssignedListNew] = useState([]);
    const [bookeeperList, setBookeeperList] = useState([]);
    const [communiList, setCommuniList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [businessNatureList, setBusinessNatureList] = useState([]);
    const [businesCateList, setBusinessCateList] = useState([]);
    const [businessEnterStyleList, setBusinessEnteryStyleList] = useState([]);
    const [businessplaningList, setBusinessPlaningList] = useState([]);
    const [arcSetupList, setArcSetupList] = useState([]);
    const [hstOpenList, setHstOpenList] = useState([])
    const [payRollList, setPayRollList] = useState([]);
    const [ArcAccessList, setArcAccessList] = useState([])
    const [AdvanceFeeList, setAdvanceFeeList] = useState([])
    const [AgreemetList, setAgreementList] = useState([]);
    const [arcGridList, setArcGridList] = useState([]);
    const [AgreementReasonList, setAgrementReasonList] = useState([]);

    const [currentTask, setCurrentTask] = useState(null);
    const [showCustomFormModal, setShowCustomFormModal] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Track the selected tab
    const [showSurvey, setShowSurvey] = useState(false);
    const [formListing, setFormListing] = useState([])
    const [surveyFormId, setSurveyFormId] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [isFormUpdate, setIsFormUpdate] = useState(false);
    const [surveyModels, setSurveyModels] = useState([]);
    const [editForm, setEditForm] = useState(null);
    const handleAccordionClick = (index) => {
        setActiveAccordionIndex(index); // Update state with clicked index
    };

    const handleAccordionDirectorClick = (index) => {
        setActiveDirectorIndex(index === activeDirectorIndex ? null : index);
    };
    const handleModalOpen = (taskData) => {
        setCurrentTask(taskData);
        setShowCustomFormModal(true);
    };
    const handleTabSelect = async (index) => {
        setSelectedTabIndex(index);
        setShowSurvey(true);

        const form = currentTask.links[index];

        const formListingItem = formListing.find(item => item.id === form.id);
        console.log(formListingItem, "formListingItem")

        console.log('Selected form:', form);
        console.log('Form ID:', form?.id);

        if (!form?.id) {
            console.error(`No valid form or ID found for index ${index}`);
            return;
        }

        setSurveyFormId(form.id);
        setSelectedForm(form);

        // Parse the form_data structure
        let surveyData = form.form_data[0];
        console.log(surveyData, "surveyData");

        // Fetch data from the API using fetchSurveyDataForEdit
        let apiData = await fetchSurveyDataForEdit(formListingItem);

        if (!apiData) {
            console.error('Failed to fetch survey data from API.');
            return;
        }

        console.log('API Response Data:', apiData);

        const existingData = apiData.data?.data?.data || {};
        console.log("Existing Data Extracted from API Response:", existingData);

        // Check if there is existing data to decide if this form is in "update" mode
        setIsFormUpdate(Object.keys(existingData).length > 0);

        const surveyModel = new Model({
            title: surveyData.title,
            pages: surveyData.pages.map((page) => ({
                ...page,
                elements: page.elements.map((element) => {
                    const elementName = element.name;
                    const existingValue = existingData[elementName];

                    console.log(`Processing element: ${elementName}, Found value: ${existingValue}`);

                    const updatedElement = {
                        ...element,
                        value: existingValue !== undefined ? existingValue : element.value,
                        defaultValue: existingValue !== undefined ? existingValue : element.defaultValue,
                    };

                    console.log("Updated Element:", updatedElement);

                    return updatedElement;
                }),
            })),
        });

        console.log('Created Survey Model:', surveyModel);

        const updatedSurveyModels = [...surveyModels];
        updatedSurveyModels[index] = surveyModel;
        setSurveyModels(updatedSurveyModels);

        console.log('Updated Survey Models:', updatedSurveyModels);
    };

    const formListingData = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `custom_form/listing`,
                    isSecure: true,

                },
                (res) => {
                    setFormListing(res?.data?.data)
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    useEffect(() => {

        formListingData();
    }, []);

    const handleCompleteSurvey = (sender, context = 'profile') => {
        try {
            // Capture the filled survey data
            const surveyData = sender.data;

            // Dynamically retrieve module_name based on the context
            const moduleName = context === 'profile'
                ? useProfile?.links?.[0]?.module_name
                : serviceDetail?.links?.[0]?.module_name;

            if (!moduleName) {
                console.error('Module name not found in the given context.');
                return;
            }

            // Determine the URL based on whether the form is being updated or saved for the first time
            const url = isFormUpdate
                ? `custom_form/link/update/${surveyFormId}`
                : `custom_form/link/${surveyFormId}/save`;

            // Make the HTTP request to save the survey data
            http(
                {
                    method: "POST",
                    url: url,
                    isSecure: true,
                    body: {
                        "module_name": moduleName, // Dynamic module name
                        "data": {
                            "data": surveyData // Captured survey data
                        }
                    }
                },
                (res) => {
                    console.log('Survey data saved successfully:', res);
                    if (!isFormUpdate) {
                        // After first save, mark the form as updateable
                        setIsFormUpdate(true);
                    }
                },
                (error) => {
                    console.error('Error saving survey data:', error);
                }
            );
        } catch (error) {
            console.error('Error completing survey:', error);
        }
    };


    const fetchSurveyDataForEdit = async (formListingItem) => {
        try {
            console.log('Fetching data for form ID:', formListingItem?.id); // Debugging: Log the ID being used
            return new Promise((resolve, reject) => {
                http(
                    {
                        method: "GET",
                        url: `custom_form/link/${formListingItem?.id}/edit`,  // Use the id from the formListingItem passed in
                        isSecure: true,
                    },
                    (res) => {
                        console.log(res, "API Response Data");
                        resolve(res?.data);  // Return the nested data object
                        setEditForm(res?.data)
                    },
                    (error) => {
                        console.error('Error fetching survey data for edit:', error);
                        reject(error);
                    }
                );
            });
        } catch (error) {
            console.error('Error fetching survey data for edit:', error);
            return null;
        }
    };
    const showEditProfile = () => {
        http(
            {
                method: "GET",
                url: `corporate/client/${id}/edit`,
                isSecure: true,
            },
            (res) => {
                setEditProfileData(res?.data);
                setEditProfile(true);
                // Set form values
                Object.keys(res?.data).forEach((key) => {
                    setValue(key, res?.data[key]);
                });
            },
            (err) => { }
        );
    };


    const updateProfile = (updatedData) => {
        http(
            {
                method: "POST",
                url: `corporate/client/update/${id}`,
                body: updatedData,
                isSecure: true,
            },
            (res) => {
                setEditProfile(false)
            },
            (err) => {
                console.error("Error updating profile:", err);
            }
        );
    };

    const onSubmit = (formData) => {
        // Call the updateProfile function with the formData
        updateProfile(formData);
        window.location.reload();
    };



    useEffect(() => {
        http(
            {
                method: "GET",
                url: `corporate/client/${id}/view`,
                isSecure: true,
            },
            (res) => {
                setUserProfile(res?.data, "dtata");
                localStorage.setItem("CorporateUserData", JSON.stringify(res?.data?.name));
            },
            (err) => { },
        );
    }, []);


    // Corporate Advance Listing Api --->>    



    const fetchAdvData = () => {
        http(
            {
                method: "GET",
                url: `corporate/advance/${id}/listing?page=${page}&limit=${1}`,
                isSecure: true,
            },
            (res) => {
                setAdvanceList(res?.data?.data, "dtata");
            },
            (err) => { },
        );
    }

    useEffect(() => {

        fetchAdvData();
    }, []);

    ////// Coporate Advance Edit Api Calling -->

    const fetchAdvanceData = () => {
        if (advanceList && advanceList.length > 0) {
            http(
                {
                    method: "GET",
                    url: `corporate/advance/${advanceList[0]?.corporate_id}/${advanceList[0]?.id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setAdvanceDetail(res?.data);
                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });
                },
                (err) => { }
            );
        }
    }


    useEffect(() => {

        fetchAdvanceData()
    }, [advanceList]);


    //// Coporate Advance Update Api calling -->
    const updateAdvance = (updatedData) => {
        http(
            {
                method: "PUT",
                url: `corporate/advance/update/${advanceDetail?.corporate_id}/${advanceDetail?.id}`,
                body: updatedData,
                isSecure: true,
            },
            (res) => {
                setEditAdvanceModel(false);
                // Optionally, you can perform additional actions after the profile is updated
                fetchAdvData();
            },
            (err) => {
                console.error("Error updating profile:", err);
            }
        );
    };
    const onAdvanceSubmit = (formData) => {
        // Call the updateProfile function with the formData
        updateAdvance(formData);


    };


    /// Show Listing Operation Api Calling -->

    const fetchData = async () => {

        try {
            http(
                {
                    method: "GET",
                    url: `corporate/operating/${id}/listing`,
                    isSecure: true,
                },
                (res) => {
                    setOperationData(res?.data?.data, "dtata");
                }

            );
        } catch (error) {

        }
    };



    useEffect(() => {

        fetchData()
    }, [id]);

    /// Edit Operation Api Calling -->

    const editOperationData = async (corporateId, operationId) => {
        try {
            http(
                {
                    method: "GET",
                    url: `corporate/operating/${corporateId}/${operationId}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setShowEditOperationData(res?.data); // Set data for edit modal
                    setEditOperationModel(true); // Show the edit modal

                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };

    const handleEditIconClick = (corporateId, operationId) => {
        editOperationData(corporateId, operationId);
    };


    ///// Update Operation Api Calling -->
    const updateOperationData = async (corporateId, operationId) => {

        try {
            http(
                {
                    method: "PUT",
                    url: `corporate/operating/update/${corporateId}/${operationId}`,
                    isSecure: true,
                    body: {
                        name: showEditOperationData?.name, // Assuming showEditOperationData contains the updated name
                        registration_date: showEditOperationData?.registration_date // Assuming showEditOperationData contains the updated registration_date
                    }
                },
                (res) => {
                    hideEditOperationModel();
                    fetchData();
                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };



    /// Show Listing Director Api Calling -->

    const fetchDirData = async () => {

        try {
            http(
                {
                    method: "GET",
                    url: `corporate/director/${id}/listing`,
                    isSecure: true,
                },
                (res) => {
                    setDirectorData(res?.data?.data);
                }

            );
        } catch (error) {

        }
    };



    useEffect(() => {
        fetchDirData();
    }, [id]);

    ///// Edit Director Api Calling -->
    const editDirectorData = async (corporateId, operationId) => {
        try {
            http(
                {
                    method: "GET",
                    url: `corporate/director/${corporateId}/${operationId}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setShowEditDirData(res?.data); // Set data for edit modal
                    setEditDirectorModal(true); // Show the edit modal
                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };

    const handleEditDirectorClick = (corporateId, operationId) => {
        editDirectorData(corporateId, operationId);
    };

    //// Update Director Api Calling -->

    const updateDirectorData = async (corporateId, operationId) => {

        try {
            http(
                {
                    method: "PUT",
                    url: `corporate/director/update/${corporateId}/${operationId}`,
                    isSecure: true,
                    body: {
                        first_name: showEditDirData?.first_name, // Assuming showEditDirData contains the updated name
                        middle_name: showEditDirData?.middle_name,
                        last_name: showEditDirData?.last_name,
                        sin: showEditDirData?.sin,
                        date_of_birth: showEditDirData?.date_of_birth,
                        email: showEditDirData?.email,
                        phone_number: showEditDirData?.phone_number,
                        address: showEditDirData?.address,
                        city: showEditDirData?.city,
                        province: showEditDirData?.province,
                        postal_code: showEditDirData?.postal_code,
                        shareholder_part: showEditDirData?.shareholder_part


                    }
                },
                (res) => {
                    setEditDirectorModal();
                    fetchDirData();
                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };



    /// Show Service List Api callig -->    
    const fetchSerData = () => {
        http(
            {
                method: "GET",
                url: `corporate/service/${id}/listing?page=${servicePage}&limit=${1}`,
                isSecure: true,
            },
            (res) => {
                setServiceList(res?.data?.data);
            },
            (err) => { },
        );
    }


    useEffect(() => {
        fetchSerData();
    }, []);

    /// Edit Service Api Calling -->
    const editServiceData = () => {
        if (serviceList && serviceList.length > 0) {
            http(
                {
                    method: "GET",
                    url: `corporate/service/${serviceList[0]?.corporate_id}/${serviceList[0]?.id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setServiceDetail(res?.data);
                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });
                },
                (err) => { },
            );
        }
    }

    useEffect(() => {
        editServiceData();

    }, [serviceList]);

    // Udapte Service Api Calling -->
    const updateService = (updatedData) => {
        http(
            {
                method: "PUT",
                url: `corporate/service/update/${serviceDetail?.corporate_id}/${serviceDetail?.id}`,
                body: updatedData,
                isSecure: true,
            },
            (res) => {
                setEditServiceModal(false);
                toast.success("Update Service Successfully")
                fetchSerData();
            },
            (err) => {
                console.error("Error updating profile:", err);
            }
        );
    };
    const onServiceSubmit = (formData) => {
        // Call the updateProfile function with the formData
        updateService(formData);
    };

    /// Show Assigned List Api callig -->    
    const fetchAsiData = () => {
        http(
            {
                method: "GET",
                url: `corporate/assign/${id}/listing`,
                isSecure: true,
            },
            (res) => {
                setAssignedList(res?.data?.data);
            },
            (err) => { },
        );
    }
    useEffect(() => {
        fetchAsiData();

    }, []);

    // Edit Assigned Api Calling -->

    const editAssignedSubmit = (assignedItem) => {
        http(
            {
                method: "GET",
                url: `corporate/assign/${assignedItem?.corporate_id}/${assignedItem?.id}/edit`,
                isSecure: true,
            },
            (res) => {
                setEditAssignedDetail(res?.data);
                setEditAssignedModal(true);
                setValue("staff_id", res?.data?.staff_id);
                setValue("assistant_id", res?.data?.assistant_id);
                setValue("bookkeeper_id", res?.data?.bookkeeper_id);
                setValue("communicator_id", res?.data?.communicator_id);

            },
            (err) => { },
        );
    }



    const updateAssignedSubmit = async (data) => {
        try {

            http(
                {
                    method: "PUT",
                    url: `corporate/assign/update/${editAssignedDetail?.corporate_id}/${editAssignedDetail?.id}`,
                    isSecure: true,
                    body: {
                        staff_id: data.staff_id,
                        assistant_id: data.assistant_id,
                        bookkeeper_id: data.bookkeeper_id,
                        communicator_id: data.communicator_id,
                    }
                },
                (res) => {
                    hideEditAssigendModal();
                    toast.success("Update assigned member Successfully");
                    fetchAsiData();
                },
            );
        } catch (error) {
            console.error(error);
        }
    };


    ///HST Reture LIST Api Calling -->
    const showHstList = () => {
        http(
            {
                method: "GET",
                url: `hst_return/listing`,
                isSecure: true,
            },
            (res) => {
                setHstList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHstList();
    }, []);

    /// HST Style Api Calling -->
    const showHstStyle = () => {
        http(
            {
                method: "GET",
                url: `hst_style/listing`,
                isSecure: true,
            },
            (res) => {
                setHstStyleList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHstStyle();
    }, []);

    /// Arc Setup Listing Api Calling -->
    const showArsetupList = () => {
        http(
            {
                method: "GET",
                url: `arc_setup/listing`,
                isSecure: true,
            },
            (res) => {
                setArcSetupList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showArsetupList();
    }, []);

    /// Hst Open Listing Api Calling -->
    const showHstOpenList = () => {
        http(
            {
                method: "GET",
                url: `hst_open/listing`,
                isSecure: true,
            },
            (res) => {
                setHstOpenList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHstOpenList();
    }, []);


    /// PayRoll Account Listing Api Calling -->
    const showPayRollList = () => {
        http(
            {
                method: "GET",
                url: `payroll_account/listing`,
                isSecure: true,
            },
            (res) => {
                setPayRollList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showPayRollList();
    }, []);

    /// Arc Access Listing Api Calling -->
    const showArcAccessList = () => {
        http(
            {
                method: "GET",
                url: `arc_access/listing`,
                isSecure: true,
            },
            (res) => {
                setArcAccessList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showArcAccessList();
    }, []);

    /// Advanse Fee Listing Api Calling -->
    const showAdvanceList = () => {
        http(
            {
                method: "GET",
                url: `advance_fee/listing`,
                isSecure: true,
            },
            (res) => {
                setAdvanceFeeList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showAdvanceList();
    }, []);

    /// Advanse Fee Listing Api Calling -->
    const showAgreementList = () => {
        http(
            {
                method: "GET",
                url: `agreement_sign/listing`,
                isSecure: true,
            },
            (res) => {
                setAgreementList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showAgreementList();
    }, []);



    /// Arc Grid Listing Api Calling -->
    const showArcGridList = () => {
        http(
            {
                method: "GET",
                url: `arc_grid/listing`,
                isSecure: true,
            },
            (res) => {
                setArcGridList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showArcGridList();
    }, []);


    /// Agreement Reason Listing Api Calling -->
    const showAgreementReasonList = () => {
        http(
            {
                method: "GET",
                url: `agreement_reason/listing`,
                isSecure: true,
            },
            (res) => {
                setAgrementReasonList(res?.data?.data); // Update hstList with the fetched data
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showAgreementReasonList();
    }, []);



    useEffect(() => {
        // Fetch staff data from API
        fetchStaffData();
    }, []);

    const fetchStaffData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `staff/listing`,
                    isSecure: true,

                },
                (res) => {
                    setStaffList(res?.data?.data);
                    allStaffApis['staff_id'] = res?.data?.data;
                    setAllStaffApi(allStaffApis);

                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    useEffect(() => {
        // Fetch staff data from API
        fetchAssisgnedData();
    }, []);

    const fetchAssisgnedData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `assistant/listing`,
                    isSecure: true,

                },
                (res) => {
                    setAssignedListNew(res?.data?.data);
                    allStaffApis['assistant_id'] = res?.data?.data;
                    setAllStaffApi(allStaffApis);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };


    useEffect(() => {
        // Fetch staff data from API
        fetchBookeeperData();
    }, []);

    const fetchBookeeperData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `bookkeeper/listing`,
                    isSecure: true,

                },
                (res) => {
                    setBookeeperList(res?.data?.data);
                    allStaffApis['bookkeeper_id'] = res?.data?.data;
                    setAllStaffApi(allStaffApis);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };


    useEffect(() => {
        // Fetch staff data from API
        fetchCommuniData();
    }, []);

    const fetchCommuniData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `communicator/listing`,
                    isSecure: true,

                },
                (res) => {
                    setCommuniList(res?.data?.data);
                    allStaffApis['communicator_id'] = res?.data?.data;
                    setAllStaffApi(allStaffApis);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    ///Business Type LIST Api Calling -->
    const showHsBusinessTypeList = () => {
        http(
            {
                method: "GET",
                url: `business-type/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['businesstype_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHsBusinessTypeList();
    }, []);

    ///Business Nature LIST Api Calling -->
    const showHsBusinessNatureList = () => {
        http(
            {
                method: "GET",
                url: `business-nature/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessNatureList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['businessnature_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showHsBusinessNatureList();
    }, []);

    ///Business category LIST Api Calling -->
    const showBusinessCateList = () => {
        http(
            {
                method: "GET",
                url: `business-category/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessCateList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['businesscategory_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);


            },
            (err) => { },
        );
    };
    useEffect(() => {
        showBusinessCateList();
    }, []);

    ///Business Data Entery Style LIST Api Calling -->
    const showBusinessEnteryList = () => {
        http(
            {
                method: "GET",
                url: `data_entry_style/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessEnteryStyleList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['data_entry_style_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showBusinessEnteryList();
    }, []);

    ///Business Planing LIST Api Calling -->
    const showBusinessPlaningList = () => {
        http(
            {
                method: "GET",
                url: `business_planning/listing`,
                isSecure: true,
            },
            (res) => {
                setBusinessPlaningList(res?.data?.data); // Update hstList with the fetched data
                allAdvanceApi['business_planing_id'] = res?.data?.data;
                setAllAdvanceApi(allAdvanceApi);
            },
            (err) => { },
        );
    };
    useEffect(() => {
        showBusinessPlaningList();
    }, []);

    const onHideHandler = () => {
        setEditProfile(false);

    };
    const showOpreationModal = () => {
        setOperationModal(true);
    };
    const showAdvanceModal = () => {
        setAdvanceModal(true);
    };
    const showDirectorModal = () => {
        setDirectorModal(true);
    }
    const showServicesModal = () => {
        setServicesModal(true);
    }
    const showAssigendModal = () => {
        setAssigentModal(true);
    }
    const showUploadModal = () => {
        setUploadModal(true);
    }
    const showEditAdvanceModel = () => {
        setEditAdvanceModel(true);
    }
    const hideEditAdvanceModel = () => {
        setEditAdvanceModel(false);
    }
    const hideEditOperationModel = () => {
        setEditOperationModel(false);
    }
    const hideEditDirectModal = () => {
        setEditDirectorModal(false);
    }
    const showEditServiceModal = () => {
        setEditServiceModal(true);
    }
    const hideEditServiceModal = () => {
        setEditServiceModal(false);
    }

    const hideEditAssigendModal = () => {
        setEditAssignedModal(false);
    }


    return (
        <>

            <div className="right_section font-poppins">
                <div className="clients-detail-area py-3">
                    <div className="row client-detail-head align-items-center">
                        {/* <div className="col-md-12 client-detail-tabs">
                            <ul className="nav nav-tabs border-0">
                                <li className="nav-item me-2 rounded mt-2">
                                    <button className="nav-link active" data-bs-toggle="tab" href="#businesshome">Home</button>
                                </li>
                                <li className="nav-item me-2 mt-2">
                                    <button className="nav-link" data-bs-toggle="tab" href="#businesscommunication">Communication</button>
                                </li>
                                <li className="nav-item me-2 mt-2">
                                    <button className="nav-link" data-bs-toggle="tab" href="#businessnotes">Notes</button>
                                </li>
                                <li className="nav-item me-2 mt-2">
                                    <button className="nav-link" data-bs-toggle="tab" href="#businessfiles">Files</button>
                                </li>
                                <li className="nav-item me-2 mt-2">
                                    <button className="nav-link" data-bs-toggle="tab" href="#businesstasks">Tasks</button>
                                </li>
                            </ul>
                        </div> */}
                        <BusinessTabs />
                    </div>

                    <div className="row tabs-content">
                        <div className="col-md-12 tab-content">

                            {/* <!-- HOME TAB START --> */}
                            <div className="tab-pane active" id="businesshome">
                                <div className="row mt-2 justify-content-between">
                                    <div className="col-md-6 left_head">
                                        <h4 className="fs-24 mt-3">Profile</h4>
                                    </div>
                                    <div className="col-md-6 mt-2 right_head">
                                        <ul className="ps-0 d-flex align-items-center gap-2 justify-content-end">
                                            <li
                                                onClick={() => handleModalOpen(useProfile)}
                                            >
                                                <span className="file-form"
                                                //  style={style}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="13.999" viewBox="0 0 12.599 13.999">
                                                        <path id="Path_506" data-name="Path 506" d="M402.367,1279h5.866a3.067,3.067,0,0,1,3.366,3.381v7.231a3.056,3.056,0,0,1-3.366,3.388h-5.866a3.072,3.072,0,0,1-3.367-3.388v-7.231A3.083,3.083,0,0,1,402.367,1279Zm.189,3.262v-.007h2.092a.541.541,0,0,1,.388.161.549.549,0,0,1,0,.777.541.541,0,0,1-.388.161h-2.092a.546.546,0,0,1,0-1.092Zm0,4.256h5.488a.546.546,0,0,0,0-1.092h-5.488a.546.546,0,0,0,0,1.092Zm0,3.2h5.488a.531.531,0,0,0,.35-.182.55.55,0,0,0,0-.735.531.531,0,0,0-.35-.182h-5.488a.5.5,0,0,0-.3.056.5.5,0,0,0-.225.2.56.56,0,0,0,0,.588.541.541,0,0,0,.525.252Z" transform="translate(-399 -1279)" fill="#202529" fillRule="evenodd" />
                                                    </svg>
                                                    Fill Required Forms
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-md-12 mt-4">
                                        <div className="profile-box position-relative">
                                            <div className="login-box d-flex align-items-center position-absolute top-0 start-0">
                                                <span></span>
                                                <p className="ms-2">Active</p>
                                            </div>
                                            <div className="position-absolute start-0 end-0 bottom-0">
                                                <div className="position-relative p-detail-box px-4 py-3 d-flex align-items-center justify-content-between flex-wrap align-items-center">
                                                    <div className="left-prof d-flex align-items-center mt-2">
                                                        <div className="dp-box me-2">
                                                            <img src={require("../../../assets/images/dp.jpg")} alt="" />
                                                        </div>
                                                        <div className="dp-detail">
                                                            <h5>{useProfile?.name}</h5>
                                                            <span className="d-flex align-items-center mt-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.333" viewBox="0 0 20 18.333">
                                                                    <path id="Path_3524" data-name="Path 3524" d="M468.939,1224a5,5,0,0,1,3.576,1.507,5.182,5.182,0,0,1,1.485,3.636v8.046a5.182,5.182,0,0,1-1.485,3.636,5,5,0,0,1-3.576,1.507H459.06a5,5,0,0,1-3.575-1.507A5.183,5.183,0,0,1,454,1237.19v-8.046a5.212,5.212,0,0,1,.385-1.966,5.139,5.139,0,0,1,2.739-2.791,5.056,5.056,0,0,1,1.936-.387Zm1.591,6.661.08-.082a.8.8,0,0,0-.011-1.019.825.825,0,0,0-.529-.265.759.759,0,0,0-.3.041.857.857,0,0,0-.26.163L465,1233.167a1.547,1.547,0,0,1-2,0l-4.5-3.667a.771.771,0,0,0-.512-.153.739.739,0,0,0-.486.224.794.794,0,0,0-.073,1.018l.131.132,4.55,3.616a3.1,3.1,0,0,0,3.909,0Z" transform="translate(-454 -1224)" fill="#fff" fillRule="evenodd" />
                                                                </svg>

                                                                <p className="ms-2">{useProfile?.email}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="right-prof mt-2 ms-0">
                                                        <ul className="d-flex align-items-center">
                                                            <li className="d-flex align-items-center gap-2 px-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 75.789 60">
                                                                    <g id="folder" transform="translate(0 -53.334)">
                                                                        <path id="Path_3646" data-name="Path 3646" d="M82.948,53.334H29.264a7.875,7.875,0,0,0-7.819,8.921,1.579,1.579,0,0,0,2.365,1.159,4.472,4.472,0,0,1,2.3-.606H37.348a4.762,4.762,0,0,1,4.491,3.215l.268.865A7.932,7.932,0,0,0,49.6,72.281H86.106a4.736,4.736,0,0,1,2.359.632,1.582,1.582,0,0,0,2.378-1.364V61.229A7.9,7.9,0,0,0,82.948,53.334Z" transform="translate(-18.211)" fill="#ffa000" />
                                                                        <path id="Path_3647" data-name="Path 3647" d="M71.852,106.54a7.848,7.848,0,0,0-3.957-1.067H31.389a4.762,4.762,0,0,1-4.491-3.215l-.268-.865A7.932,7.932,0,0,0,19.137,96H7.895a7.555,7.555,0,0,0-3.865,1.02A7.876,7.876,0,0,0,0,103.894v37.895a7.9,7.9,0,0,0,7.895,7.895h60a7.9,7.9,0,0,0,7.895-7.895V113.367A7.841,7.841,0,0,0,71.852,106.54Z" transform="translate(0 -36.349)" fill="#ffc107" />
                                                                    </g>
                                                                </svg>
                                                                <p>{useProfile?.folder_number}</p>
                                                            </li>
                                                            <li className="d-flex align-items-center gap-2 px-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.849" height="20" viewBox="0 0 16.849 20">
                                                                    <path id="Path_3527" data-name="Path 3527" d="M343.5,1287.244a8.424,8.424,0,0,1,16.849,0,10.242,10.242,0,0,1-2.211,6.234,21.807,21.807,0,0,1-5.491,5.094,1.179,1.179,0,0,1-1.446,0,21.27,21.27,0,0,1-5.491-5.094A10.247,10.247,0,0,1,343.5,1287.244Zm5.643.258a2.8,2.8,0,0,0,.469,1.546,2.78,2.78,0,0,0,4.881-2.607,2.7,2.7,0,0,0-1.024-1.249,2.782,2.782,0,0,0-4.326,2.309Z" transform="translate(-343.5 -1278.82)" fill="#fff" fillRule="evenodd" />
                                                                </svg>
                                                                <p>{useProfile?.office_address}</p>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                                <a onClick={() => showEditProfile()}>
                                                    <div className="write-icon">
                                                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                                                            <path className="a" d="m0.6 21.5l-0.6 4.8q0 0.4 0.1 0.7 0.1 0.3 0.3 0.5 0.2 0.2 0.5 0.4 0.3 0.1 0.6 0.1 0 0 0 0 0 0 0.1 0 0 0 0 0 0 0 0.1 0l4.8-0.6q0.4 0 0.8-0.2 0.3-0.1 0.7-0.2 0.3-0.2 0.6-0.4 0.4-0.3 0.6-0.5l12.6-12.6q0.1-0.1 0.1-0.2 0.1-0.1 0.1-0.2 0-0.2-0.1-0.3 0-0.1-0.1-0.2l-6.4-6.4q-0.1-0.1-0.2-0.1-0.1-0.1-0.3-0.1-0.1 0-0.2 0.1-0.1 0-0.2 0.1l-12.6 12.6q-0.2 0.3-0.5 0.6-0.2 0.3-0.4 0.6-0.1 0.4-0.2 0.7-0.1 0.4-0.2 0.8z" /><path className="a" d="m27.1 3.9l-3-3q-0.4-0.4-1-0.7-0.5-0.2-1.1-0.2-0.6 0-1.2 0.2-0.6 0.3-1 0.7l-2.8 2.8q-0.1 0.1-0.1 0.2-0.1 0.1-0.1 0.2 0 0.1 0.1 0.3 0 0.1 0.1 0.1l6.5 6.5q0 0.1 0.1 0.1 0.2 0.1 0.3 0.1 0.1 0 0.2-0.1 0.1 0 0.2-0.1l2.8-2.8q0.4-0.4 0.7-1 0.2-0.6 0.2-1.2 0-0.6-0.2-1.1-0.3-0.6-0.7-1z" /><path className="a" d="m28 26.8q0-0.2-0.1-0.4-0.1-0.2-0.2-0.4-0.2-0.2-0.4-0.2-0.2-0.1-0.5-0.1h-13.4c-0.3 0-0.6 0.1-0.8 0.3-0.2 0.2-0.3 0.5-0.3 0.8 0 0.3 0.1 0.6 0.3 0.8 0.2 0.3 0.5 0.4 0.8 0.4h13.4q0.3 0 0.5-0.1 0.2-0.1 0.4-0.2 0.1-0.2 0.2-0.4 0.1-0.2 0.1-0.5z" /></svg>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="assig-member p-4 bg-white shadow-lg rounded-4">
                                            <div className="prof-insides">
                                                <div className="d-flex align-items-center justify-content-between" onClick={() => showOpreationModal()}>
                                                    <h6 className="fs-20" >Operating Details</h6>
                                                    <a ><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                        <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                    </svg>
                                                    </a>
                                                </div>
                                            </div>
                                            <div>
                                                {operationData.length === 0 ? (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-18 c-text">No Data found</h4>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    operationData.map((operation, index) => (
                                                        <div key={index} className="operator-drop mt-3">
                                                            <div className="accordion mb-2" id={`accordion${index}`}>
                                                                <div className="accordion-item position-relative" id={`heading${index}`}>
                                                                    <h2 className="accordion-header fs-18 position-relative">
                                                                        <button
                                                                            className="accordion-button"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#collapse${index}`}
                                                                            aria-expanded={activeAccordionIndex === index ? "true" : "false"}
                                                                            aria-controls={`collapse${index}`}
                                                                            onClick={() => handleAccordionClick(index)}
                                                                        >
                                                                            Business Operating {operation?.name}
                                                                            <a
                                                                                className="position-absolute edt_icon"
                                                                                onClick={() => handleEditIconClick(operation.corporate_id, operation.id)}
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                                                    <g id="write" transform="translate(-7.998 -8)">
                                                                                        <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                                        <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                                        <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                                    </g>
                                                                                </svg>
                                                                            </a>
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={`collapse${index}`}
                                                                        className={`accordion-collapse collapse${activeAccordionIndex === index ? ' show' : ''}`}
                                                                        aria-labelledby={`heading${index}`}
                                                                        data-bs-parent={`#accordion${index}`}
                                                                    >
                                                                        {activeAccordionIndex === index && (
                                                                            <div className="row accordion-body">
                                                                                <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                    <p className="fs-14">Business Name</p>
                                                                                    <h4 className="fs-18">{operation.name}</h4>
                                                                                </div>
                                                                                <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                    <p className="fs-14">Registration Date</p>
                                                                                    <h4 className="fs-18">{operation.registration_date}</h4>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="prof-inside p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h6 className="fs-20">Advance Details</h6>
                                                {advanceList?.length > 0 ? (
                                                    <a onClick={() => showEditAdvanceModel()}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                            <g id="write" transform="translate(-7.998 -8)">
                                                                <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                            </g>
                                                        </svg>
                                                    </a>

                                                ) : (
                                                    <a onClick={() => showAdvanceModal()}>
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                            <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                        </svg>
                                                    </a>
                                                )}
                                            </div>

                                            {advanceList && advanceList.length > 0 ? (
                                                advanceList.map((advanceList) => (
                                                    <div className="row mt-3 other-info" key={advanceList?.id}>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Business Type</p>
                                                            <h4 className="fs-18">  {advanceList?.business_type?.name}</h4>

                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Nature of Business</p>
                                                            <h4 className="fs-18">  {advanceList?.business_nature?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Category</p>
                                                            <h4 className="fs-18">  {advanceList?.business_category?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Business No.</p>
                                                            <h4 className="fs-18">{advanceList?.business_no}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Authorization Form (RC59)</p>
                                                            <h4 className="fs-18">{Authform_id.getName(advanceList?.authform_id_59)}</h4>
                                                        </div>

                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Authorization Form (T1013)</p>
                                                            <h4 className="fs-18">{Authform_id_1013.getName(advanceList?.authform_id_1013)}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Incorporation Date</p>
                                                            <h4 className="fs-18">{advanceList?.incorporation_date}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Data Entry Style</p>
                                                            <h4 className="fs-18">{advanceList?.data_entry_style?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Business Planning Status</p>
                                                            <h4 className="fs-18">{advanceList?.business_planing?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Date of Business Planning</p>
                                                            <h4 className="fs-18">{advanceList?.date_planning}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Corporation Year Ending</p>
                                                            <h4 className="fs-18">{advanceList?.year_ending}</h4>
                                                        </div>
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <p className="fs-14">HST Year Ending</p>
                                                            <h4 className="fs-18">{advanceList?.hstyear_ending}</h4>
                                                        </div>
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <p className="fs-14">Business Planning Session Notes</p>
                                                            <h4 className="fs-18">{advanceList?.business_notes}</h4>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="row contact-info mt-2 position-relative">
                                                    <div className="col-md-12 other-info-box mt-2">
                                                        <h4 className="fs-18 c-text">No contact information found</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="assig-member p-4 bg-white shadow-lg rounded-4">
                                            <div className="prof-insides">
                                                <div className="d-flex align-items-center justify-content-between" onClick={() => showDirectorModal()}>
                                                    <h6 className="fs-20" >Director Details</h6>
                                                    <a ><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                        <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                    </svg>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="director-container">
                                                <div>
                                                    {directorData.length === 0 ? (
                                                        <div className="row contact-info mt-2 position-relative">
                                                            <div className="col-md-12 other-info-box mt-2">
                                                                <h4 className="fs-18 c-text">No Data found</h4>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        directorData.map((director, index) => (
                                                            <div className={`director ${activeDirectorIndex === index ? 'active' : ''}`} key={index}>
                                                                <div className="director-head" onClick={() => handleAccordionDirectorClick(index)}>
                                                                    <p>{director?.first_name}</p>
                                                                    <a onClick={() => handleEditDirectorClick(director.corporate_id, director.id)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                                            <g id="write" transform="translate(-7.998 -8)">
                                                                                <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                                <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                                <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                            </g>
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                                {activeDirectorIndex === index && (
                                                                    <div className="director-content">
                                                                        <div className="row">
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Full Name</p>
                                                                                <h4 className="fs-18">{director?.first_name}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Middle Name</p>
                                                                                <h4 className="fs-18">{director?.middle_name}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Last Name</p>
                                                                                <h4 className="fs-18">{director?.last_name}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">SIN</p>
                                                                                <h4 className="fs-18">{director?.sin}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Date of Birth</p>
                                                                                <h4 className="fs-18">{director?.date_of_birth}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Email</p>
                                                                                <h4 className="fs-18">{director?.email}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Phone Number</p>
                                                                                <h4 className="fs-18">{director?.phone_number}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Address</p>
                                                                                <h4 className="fs-18">{director?.address}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">City</p>
                                                                                <h4 className="fs-18">{director?.city}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Province</p>
                                                                                <h4 className="fs-18">{director?.province}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Postal Code</p>
                                                                                <h4 className="fs-18">{director?.postal_code}</h4>
                                                                            </div>
                                                                            <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                <p className="fs-14">Shareholder Part</p>
                                                                                <h4 className="fs-18">{director?.shareholder_part}</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="prof-inside p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between" >
                                                <h6 className="fs-20">Services</h6>
                                                {serviceList?.length > 0 ? (
                                                    <>
                                                        <span className="file-form"
                                                            //  style={style}
                                                            onClick={() => handleModalOpen(serviceDetail)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="13.999" viewBox="0 0 12.599 13.999">
                                                                <path id="Path_506" data-name="Path 506" d="M402.367,1279h5.866a3.067,3.067,0,0,1,3.366,3.381v7.231a3.056,3.056,0,0,1-3.366,3.388h-5.866a3.072,3.072,0,0,1-3.367-3.388v-7.231A3.083,3.083,0,0,1,402.367,1279Zm.189,3.262v-.007h2.092a.541.541,0,0,1,.388.161.549.549,0,0,1,0,.777.541.541,0,0,1-.388.161h-2.092a.546.546,0,0,1,0-1.092Zm0,4.256h5.488a.546.546,0,0,0,0-1.092h-5.488a.546.546,0,0,0,0,1.092Zm0,3.2h5.488a.531.531,0,0,0,.35-.182.55.55,0,0,0,0-.735.531.531,0,0,0-.35-.182h-5.488a.5.5,0,0,0-.3.056.5.5,0,0,0-.225.2.56.56,0,0,0,0,.588.541.541,0,0,0,.525.252Z" transform="translate(-399 -1279)" fill="#202529" fillRule="evenodd" />
                                                            </svg>
                                                            Fill Required Forms
                                                        </span>

                                                        <a
                                                            onClick={() => showEditServiceModal()}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                                <g id="write" transform="translate(-7.998 -8)">
                                                                    <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                    <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                    <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                </g>
                                                            </svg>
                                                        </a>

                                                    </>

                                                ) : (
                                                    <a onClick={() => showServicesModal()}>
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                            <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                        </svg>
                                                    </a>
                                                )}
                                            </div>
                                            {serviceList && serviceList?.length > 0 ? (
                                                serviceList.map((serviceList) => (
                                                    <div className="row mt-3 other-info" key={serviceList?.id}>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">HST Open</p>
                                                            <h4 className="fs-18">{serviceList?.hst_open?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">HST Return</p>
                                                            <h4 className="fs-18">{serviceList?.hst_return?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">HST Style</p>
                                                            <h4 className="fs-18">{serviceList?.hst_style?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Payroll Account</p>
                                                            <h4 className="fs-18">{serviceList?.payroll_account?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">ARC Setup</p>
                                                            <h4 className="fs-18">{serviceList?.arc_setup?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">ARC Access</p>
                                                            <h4 className="fs-18">{serviceList?.arc_access?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">ARC Grid Saved</p>
                                                            <h4 className="fs-18">{serviceList?.arc_grid?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">ARC Details</p>
                                                            <h4 className="fs-18">{serviceList?.arc_detail}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Agreement Sign</p>
                                                            <h4 className="fs-18">{serviceList?.agreement_sign?.name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Agreement Reason</p>
                                                            <h4 className="fs-18">{serviceList?.agreement_reason}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Agreement Reason</p>
                                                            <h4 className="fs-18">{serviceList?.advance_fee}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Agreement Signing Amount</p>
                                                            <h4 className="fs-18">{serviceList?.advance_amount}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Reason if not Signed </p>
                                                            <h4 className="fs-18">{serviceList?.advance_reason}</h4>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="row contact-info mt-2 position-relative">
                                                    <div className="col-md-12 other-info-box mt-2">
                                                        <h4 className="fs-18 c-text">No Service information found</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="prof-inside assig-member p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between" onClick={() => showAssigendModal()}>
                                                <h6 className="fs-20">Assigned Member</h6>
                                                <a ><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                    <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                </svg>
                                                </a>
                                            </div>
                                            {
                                                assignedList && assignedList.length > 0 ? (
                                                    assignedList.map((assignedItem, index) => (
                                                        <ul className="mt-2 assigned-box ps-0" key={index}>
                                                            <li className="rounded-4 mt-1 p-3">
                                                                <a className="d-flex justify-content-end" onClick={() => editAssignedSubmit(assignedItem)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                                        <g id="write" transform="translate(-7.998 -8)">
                                                                            <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                            <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                            <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                        </g>
                                                                    </svg>
                                                                </a>
                                                                <div className="d-flex align-items-center justify-content-between mt-3">
                                                                    <span className="d-block w-50">
                                                                        <h4 className="fs-18">{assignedItem?.staff?.first_name}</h4>
                                                                        <p className="fs-14">Account Manager</p>
                                                                    </span>
                                                                    <span className="d-block w-50">
                                                                        <h4 className="fs-18">{assignedItem?.assistant?.first_name}</h4>
                                                                        <p className="fs-14">Assistant</p>
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-between mt-3">
                                                                    <span className="d-block w-50">
                                                                        <h4 className="fs-18">{assignedItem?.bookkeeper?.first_name}</h4>
                                                                        <p className="fs-14">Bookkeeper</p>
                                                                    </span>
                                                                    <span className="d-block w-50">
                                                                        <h4 className="fs-18">{assignedItem?.communicator?.first_name}</h4>
                                                                        <p className="fs-14">Communicator</p>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ))
                                                ) : (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-18 c-text">No Data Found</h4>
                                                        </div>
                                                    </div>
                                                )}

                                        </div>

                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="prof-inside p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between" onClick={() => showUploadModal()}>
                                                <h6 className="fs-20">Upload Files</h6>
                                                <a ><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                    <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Modal
                show={editProfile}
                onHide={onHideHandler}
                centered
                // size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >


                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Edit Profile Details</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body px-4 pb-0">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="operating-form">
                            <div className="">
                                <label className="fs-18">Client Name</label>
                                <input {...register("name")} name="name" id="name" className="form-input mt-2" type="text" placeholder="Name" />
                            </div>
                            <div className="mt-4">
                                <label className="fs-18">Email Address</label>
                                <input {...register("email")} name="email" id="email" className="form-input mt-2" type="email" placeholder="Email" disabled />
                            </div>
                            <div className="mt-4">
                                <label className="fs-18">Address</label>
                                <input {...register("office_address")} name="office_address" id="office_address" className="form-input mt-2" type="text" placeholder="Address" />
                            </div>
                            <div className="mt-4">
                                <label className="fs-18">Folder</label>
                                <input {...register("folder_number")} name="folder_number" id="folder_number" type="text" className="form-input mt-2" placeholder="Folder Number" />
                            </div>
                            <div className="mt-4">
                                <label htmlFor="status" className="fs-18">Status</label>
                                <select
                                    name="status"
                                    id="status"
                                    className="form-input mt-2"
                                // {...register("status", {
                                //     onChange: (e) => setShowEditStatusData(prev => ({
                                //         ...prev,
                                //         status: e.target.value,
                                //     }))
                                // })}
                                >
                                    <option value="" disabled>
                                        Select
                                    </option>
                                    {Status_id && Status_id.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="modal-footer border-0 px-0 pb-4 mt-4">
                            <a href="business-client-profile.html"><button className="btn-blue">Update</button></a>
                        </div>
                    </form>



                </div>
            </Modal>
            <Modal
                show={editAdvanceModel}
                onHide={hideEditAdvanceModel}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >


                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Edit Advance</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body px-4 pt-0 pb-0">
                    <form
                        onSubmit={handleSubmit(onAdvanceSubmit)}
                    >
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Business Type</label>
                                <select
                                    name="businesstype_id"
                                    id="businesstype_id"
                                    className="form-input mt-2"
                                    {...register("businesstype_id", {

                                    })}

                                >
                                    <option
                                        value=""
                                        selected
                                        disabled="disabled"
                                    >
                                        Select
                                    </option>

                                    {allAdvanceApi.businesstype_id.length > 0 && (
                                        allAdvanceApi.businesstype_id.map((businesstype) => (
                                            <option key={businesstype.id} value={businesstype.id}>
                                                {businesstype.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>

                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Nature of Business</label>
                                <select
                                    name="businessnature_id"
                                    id="businessnature_id"
                                    className="form-input mt-2"
                                    {...register("businessnature_id", {

                                    })}
                                >
                                    <option value="0" disabled selected>Select</option>
                                    {allAdvanceApi.businessnature_id.length > 0 && (
                                        allAdvanceApi.businessnature_id.map((businessnature) => (
                                            <option key={businessnature.id} value={businessnature.id}>
                                                {businessnature.name}
                                            </option>
                                        ))
                                    )}

                                </select>
                            </div>

                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Category</label>
                                <select
                                    name="businesscategory_id"
                                    id="businesscategory_id"
                                    className="form-input mt-2"
                                    {...register("businesscategory_id", {

                                    })}
                                >
                                    <option value="0" disabled selected>Select</option>
                                    {allAdvanceApi.businesscategory_id.length > 0 && (
                                        allAdvanceApi.businesscategory_id.map((businesscategory) => (
                                            <option key={businesscategory.id} value={businesscategory.id}>
                                                {businesscategory.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Business No.</label>
                                <input
                                    name="business_no"
                                    id="business_no"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    {...register("business_no", {

                                    })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Authorization Form</label>
                                <div className="position-relative select-text">
                                    <p>RC 59</p>
                                    <select
                                        name="authform_id_59"
                                        id="authform_id_59"
                                        className="form-input mt-2"
                                        {...register("authform_id_59", {

                                        })}
                                    >
                                        <option value="0" disabled="disabled" selected>Select</option>
                                        {Authform_id && Authform_id.getList().map((data) => (
                                            <option key={data.key} value={data.key}>
                                                {data.value}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Authorization Form</label>
                                <div className="position-relative select-text">
                                    <p>T1013</p>
                                    <select
                                        name="authform_id_1013"
                                        id="authform_id_1013"
                                        className="form-input mt-2"
                                        {...register("authform_id_1013", {

                                        })}
                                    >
                                        <option value="0" disabled="disabled" selected>Select</option>

                                        {Authform_id_1013 && Authform_id_1013.getList().map((data) => (
                                            <option key={data.key} value={data.key}>
                                                {data.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Incorporation Date</label>
                                <input
                                    className="form-input mt-2"
                                    name="incorporation_date"
                                    id="incorporation_date"
                                    type="date"
                                    {...register("incorporation_date", {


                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Data Entry Style</label>
                                <select
                                    name="data_entry_style_id"
                                    id="data_entry_style_id"
                                    className="form-input mt-2"
                                    {...register("data_entry_style_id", {


                                    })}

                                >
                                    <option value="" disabled selected>Select</option>
                                    {allAdvanceApi.data_entry_style_id.length > 0 && (
                                        allAdvanceApi.data_entry_style_id.map((businessenetryStyle) => (
                                            <option key={businessenetryStyle.id} value={businessenetryStyle.id}>
                                                {businessenetryStyle.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Business Planning Status</label>
                                <select
                                    name="business_planing_id"
                                    id="business_planing_id"
                                    className="form-input mt-2"
                                    {...register("business_planing_id", {


                                    })}
                                >
                                    <option value="" disabled selected>Select</option>
                                    {allAdvanceApi.business_planing_id.length > 0 && (
                                        allAdvanceApi.business_planing_id.map((businessplaning) => (
                                            <option key={businessplaning.id} value={businessplaning.id}>
                                                {businessplaning.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Date of Business Planning</label>
                                <input
                                    name="date_planning"
                                    id="date_planning"
                                    className="form-input mt-2"
                                    type="date"
                                    {...register("date_planning", {


                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Corporation Year Ending</label>
                                <input
                                    name="year_ending"
                                    id="year_ending"
                                    className="form-input mt-2"
                                    type="date"
                                    {...register("year_ending", {


                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">HST Year Ending</label>
                                <input
                                    name="hstyear_ending"
                                    id="hstyear_ending"
                                    className="form-input mt-2"
                                    type="date"
                                    {...register("hstyear_ending", {


                                    })}

                                />
                            </div>
                            <div className="col-md-12 mt-4">
                                <label className="fs-18">Business Planning Session Notes</label>
                                <textarea
                                    className="mt-2 fs-18"
                                    name="business_notes"
                                    id="business_notes"
                                    cols="20"
                                    rows="10"
                                    placeholder="Type here..."
                                    {...register("business_notes", {


                                    })}
                                >
                                </textarea>
                            </div>

                        </div>

                        <div className="modal-footer border-0 px-0 pb-4 mt-4">
                            <button type="submit" className="btn-blue">Update</button>
                        </div>
                    </form>



                </div>
            </Modal>

            <Modal
                show={editOperationModal}
                onHide={hideEditOperationModel}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
            >


                <div className="modal-dialog modal-dialog-centered m-0">
                    <div className="modal-content font-poppins ">
                        <div className="modal-header border-0 justify-content-between">
                            <h4 className="fs-20">Add Operating Details</h4>
                            <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                        </div>
                        <div class="modal-body px-4 pb-0">
                            <form onSubmit={(e) => {
                                e.preventDefault(); // Prevent the default form submission behavior
                                // Call the function to update operation data
                                updateOperationData(showEditOperationData?.corporate_id, showEditOperationData?.id);
                            }}>
                                <div className="modal-body p-0">

                                    <div className="input_wrap" >

                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <label className="fs-18">Operating Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="form-input mt-2"
                                                    placeholder="Type here..."

                                                    value={showEditOperationData?.name} // Assuming 'registration_date' is the property to be displayed
                                                    onChange={(e) => setShowEditOperationData({ ...showEditOperationData, name: e.target.value })} // Update editModalData on change

                                                />
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <label className="fs-18">Registration Date</label>
                                                <input
                                                    type="date"
                                                    name="registration_date"
                                                    id="registration_date"
                                                    className="form-input mt-2"
                                                    placeholder="Type here..."

                                                    value={showEditOperationData?.registration_date} // Assuming 'registration_date' is the property to be displayed
                                                    onChange={(e) => setShowEditOperationData({ ...showEditOperationData, registration_date: e.target.value })} // Update editModalData on change

                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer border-0 px-0 pb-4 mt-4">
                                    <button type="submit" className="btn-blue">Update</button>
                                </div>
                            </form>


                        </div>


                    </div>
                </div>
            </Modal>

            <Modal
                show={editServiceModal}
                onHide={hideEditServiceModal}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >


                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Edit Service Details</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body px-4 pt-0 pb-0">
                    <form
                        onSubmit={handleSubmit(onServiceSubmit)}
                    >
                        <div className="services-form row">
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">HST Open</label>
                                <select
                                    name="hst_open_id"
                                    className="form-input mt-2"
                                    id="hst_open_id"
                                    {...register("hst_open_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {hstOpenList && hstOpenList.map((data) => (
                                        <option key={data.key} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">HST Return</label>
                                <select
                                    name="hst_return_id"
                                    className="form-input mt-2"
                                    id="hst_return_id" {...register("hst_return_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {hstList && hstList.map((data) => (
                                        <option key={data.key} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">HST Style</label>
                                <select
                                    name="hst_style_id"
                                    className="form-input mt-2"
                                    id="hst_style_id"
                                    {...register("hst_style_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {hstStyleList && hstStyleList.map((data) => (
                                        <option key={data.key} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Payroll Account</label>
                                <select
                                    name="payroll_account_id"
                                    className="form-input mt-2"
                                    id="payroll_account_id"
                                    {...register("payroll_account_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {payRollList && payRollList.map((data) => (
                                        <option key={data.key} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">ARC Setup</label>
                                <select
                                    name="arc_setup_id"
                                    className="form-input mt-2"
                                    id="arc_setup_id"
                                    {...register("arc_setup_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {arcSetupList && arcSetupList.map((data) => (
                                        <option key={data.key} value={data.id}>
                                            {data?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">ARC Access</label>
                                <select
                                    name="arc_accesses_id"
                                    className="form-input mt-2"
                                    id="arc_accesses_id"
                                    {...register("arc_accesses_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {ArcAccessList && ArcAccessList.map((data) => (
                                        <option key={data.key} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">ARC Grid Saved</label>
                                <select
                                    name="arc_grid_id"
                                    className="form-input mt-2"
                                    id="arc_grid_id"
                                    {...register("arc_grid_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {arcGridList && arcGridList.map((data) => (
                                        <option key={data.key} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">ARC Details</label>
                                <textarea
                                    name="arc_detail"
                                    id="arc_detail"
                                    className="mt-2"
                                    cols="20"
                                    rows="10"

                                    {...register("arc_detail", {

                                    })}
                                ></textarea>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Agreement Signed</label>
                                <select
                                    name="agreement_sign_id"
                                    className="form-input mt-2"
                                    id="agreement_sign_id"
                                    {...register("agreement_sign_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {AgreemetList && AgreemetList.map((data) => (
                                        <option key={data.key} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Agreement Reason</label>
                                <select
                                    name="agreement_reason"
                                    className="form-input mt-2"
                                    id="agreement_reason"
                                    {...register("agreement_reason", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {AgreementReasonList && AgreementReasonList.map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Agreement Fee</label>
                                <select
                                    name="advance_fee"
                                    className="form-input mt-2"
                                    id="advance_fee"
                                    {...register("advance_fee", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {AdvanceFeeList && AdvanceFeeList.map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Agreement Signing Amount</label>
                                <input
                                    className="form-input mt-2"
                                    type="text"
                                    name="advance_amount"
                                    id="advance_amount"
                                    placeholder="Type here..."
                                    {...register("advance_amount", {

                                    })}
                                />
                            </div>
                            <div className="col-md-12 mt-4">
                                <label className="fs-18">Reason If Not Signed</label>
                                <textarea
                                    name="advance_reason"
                                    id="advance_reason"
                                    className="mt-2"
                                    cols="20"
                                    rows="10"
                                    {...register("advance_reason", {

                                    })}
                                ></textarea>
                            </div>
                            <div className="modal-footer border-0 px-0 pb-4 mt-4">
                                <button className="btn-blue">Update</button>
                            </div>

                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={editDirectorModal}
                onHide={hideEditDirectModal}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >


                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Update Director Details</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body px-4 pt-0 pb-0">
                    <form onSubmit={(e) => {
                        e.preventDefault(); // Prevent the default form submission behavior
                        // Call the function to update operation data
                        updateDirectorData(showEditDirData?.corporate_id, showEditDirData?.id);
                    }}>

                        <div className="director-form row mt-3">
                            <div className="col-lg-6 mt-2">
                                <div className="search-input position-relative">
                                    <i className="fa fa-search position-absolute top-4"></i>
                                    <input type="search" placeholder="Search by SIN number..." className="" />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4" >
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">First Name</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.first_name} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, first_name: e.target.value })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Last Name</label>

                                <input
                                    type="text"
                                    name="middle_name"
                                    id="middle_name"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.middle_name} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, middle_name: e.target.value })}

                                />

                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">SIN</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.last_name} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, last_name: e.target.value })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Date of Birth</label>
                                <input
                                    type="date"
                                    name="date_of_birth"
                                    id="date_of_birth"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.date_of_birth} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, date_of_birth: e.target.value })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Email Address</label>
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.email} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, email: e.target.value })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Phone No.</label>
                                <input
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.phone_number} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, phone_number: e.target.value })}

                                />
                            </div>
                            <div className="col-lg-8 col-md-12 mt-4">
                                <label className="fs-18">Street Address</label>
                                <input
                                    type="text"
                                    name="address"
                                    id="address"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.address} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, address: e.target.value })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.city} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, city: e.target.value })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Province</label>
                                <input
                                    type="text"
                                    name="province"
                                    id="province"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.province} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, province: e.target.value })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Postal Code</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.postal_code} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, postal_code: e.target.value })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label className="fs-18">Shareholder(%)</label>
                                <input
                                    type="text"
                                    name="shareholder_part"
                                    id="shareholder_part"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={showEditDirData?.shareholder_part} // Assuming 'registration_date' is the property to be displayed
                                    onChange={(e) => setShowEditDirData({ ...showEditDirData, shareholder_part: e.target.value })}

                                />
                            </div>

                        </div>


                        <div className="modal-footer border-0 px-0 pb-4 mt-4">
                            <a ><button className="btn-blue">Update</button></a>
                        </div>

                    </form>
                </div>
            </Modal>
            <Modal
                show={editAssignedModal}
                onHide={hideEditAssigendModal}
                centered
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >

                <div className="modal-header p-3 border-0 justify-content-between">
                    <h4 className="fs-20">Edit Asssigend Member</h4>
                    <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
                </div>
                <div className="modal-body py-0">
                    <form
                        onSubmit={handleSubmit(updateAssignedSubmit)}
                    >
                        <div className="services-form row">
                            <div className="col-lg-12 mt-3 p-0">
                                <label className="fs-18">Account Manger</label>
                                <select
                                    name="staff_id"
                                    className="form-input mt-2"
                                    id="staff_id"
                                    {...register("staff_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {allStaffApis.staff_id.length > 0 && (
                                        allStaffApis.staff_id.map((staff) => (
                                            <option key={staff.id} value={staff.id}>
                                                {staff.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-12 mt-3 p-0">
                                <label className="fs-18">Assistant</label>
                                <select
                                    name="assistant_id"
                                    className="form-input mt-2"
                                    id="assistant_id" {...register("assistant_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>

                                    {allStaffApis.assistant_id.length > 0 && (
                                        allStaffApis.assistant_id.map((staff) => (
                                            <option key={staff.id} value={staff.id}>
                                                {staff.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-12 mt-3 p-0">
                                <label className="fs-18">Bookkeeper</label>
                                <select
                                    name="bookkeeper_id"
                                    className="form-input mt-2"
                                    id="bookkeeper_id"
                                    {...register("bookkeeper_id", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>

                                    {allStaffApis.bookkeeper_id.length > 0 && (
                                        allStaffApis.bookkeeper_id.map((staff) => (
                                            <option key={staff.id} value={staff.id}>
                                                {staff.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-12 mt-3 p-0">
                                <label className="fs-18">Communicator</label>
                                <select
                                    name="communicator_id"
                                    className="form-input mt-2"
                                    id="communicator_id"
                                    {...register("communicator_id", {

                                    })}
                                >
                                    <option value="" disabled="disabled" selected>Select</option>
                                    {allStaffApis.communicator_id.length > 0 && (
                                        allStaffApis.communicator_id.map((staff) => (
                                            <option key={staff.id} value={staff.id}>
                                                {staff.name}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>

                            <div className="modal-footer border-0 px-0 pb-3 mt-2">
                                <button className="btn-blue">Update</button>
                            </div>

                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                show={showCustomFormModal}
                onHide={() => setShowCustomFormModal(false)}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal modal-lg email-modal"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-body position-relative">
                    <div className="modal-head d-flex align-items-center justify-content-between gap-3 px-3 pt-3">
                        <h4 className="fs-20 fw-semibold">Fill Required Forms</h4>
                        <svg onClick={() => setShowCustomFormModal(false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="circle-xmark" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.707,14.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12l2.293,2.293Z" fill="#212529" />
                        </svg>
                    </div>
                    <form>
                        <div className="tabs-section mt-2">
                            <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                                <div className="p-2">
                                    <TabList>
                                        {currentTask?.links?.map((form, index) => (
                                            <Tab key={index}>
                                                {form.form_data?.length > 0 && form.form_data[0]?.title
                                                    ? form.form_data[0].title
                                                    : form.title || `Form ${index + 1}`}
                                            </Tab>
                                        ))}
                                    </TabList>
                                </div>


                                <div className="tabs-contentmain pb-3">
                                    {currentTask?.links?.map((form, index) => (
                                        <TabPanel key={index}>
                                            <div className="row p-0">
                                                {selectedForm?.id === form.id && surveyModels[index] && (
                                                    <div className="survey-container">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger mt-3 del-survey"
                                                        // onClick={() => deleteCustomForm()}
                                                        >
                                                            Delete Survey Form
                                                        </button>
                                                        <Survey model={surveyModels[index]} onComplete={handleCompleteSurvey} />

                                                    </div>
                                                )}
                                            </div>
                                        </TabPanel>
                                    ))}
                                </div>
                            </Tabs>

                        </div>
                    </form>
                </div >
            </Modal >

            <Operation_Detail
                operationModal={operationModal}
                setOperationModal={setOperationModal}
                fetchData={fetchData}


            />
            <Advance_Details
                advanceModal={advanceModal}
                setAdvanceModal={setAdvanceModal}
                showHsBusinessTypeList={showHsBusinessTypeList}
                businessList={businessList}
                setBusinessList={setBusinessList}
                businessNatureList={businessNatureList}
                setBusinessNatureList={setBusinessNatureList}
                showHsBusinessNatureList={showHsBusinessNatureList}
                businesCateList={businesCateList}
                setBusinessCateList={setBusinessCateList}
                showBusinessCateList={showBusinessCateList}
                showBusinessEnteryList={showBusinessEnteryList}
                businessEnterStyleList={businessEnterStyleList}
                setBusinessEnteryStyleList={setBusinessEnteryStyleList}
                setBusinessPlaningList={setBusinessPlaningList}
                businessplaningList={businessplaningList}
                showBusinessPlaningList={showBusinessPlaningList}
                fetchAdvData={fetchAdvData}


            />
            <Directors_Details
                directorModal={directorModal}
                setDirectorModal={setDirectorModal}
                fetchDirData={fetchDirData}
            />
            <Services
                servicesModal={servicesModal}
                setServicesModal={setServicesModal}
                hstList={hstList}
                setHstList={setHstList}
                showHstList={showHstList}
                hstStyleList={hstStyleList}
                setHstStyleList={setHstStyleList}
                showHstStyle={showHstStyle}
                showArsetupList={showArsetupList}
                arcSetupList={arcSetupList}
                showHstOpenList={showHstOpenList}
                hstOpenList={hstOpenList}
                showPayRollList={showPayRollList}
                payRollList={payRollList}
                showArcAccessList={showArcAccessList}
                ArcAccessList={ArcAccessList}
                showAdvanceList={showAdvanceList}
                AdvanceFeeList={AdvanceFeeList}
                showAgreementList={showAgreementList}
                AgreemetList={AgreemetList}
                showArcGridList={showArcGridList}
                arcGridList={arcGridList}
                showAgreementReasonList={showAgreementReasonList}
                AgreementReasonList={AgreementReasonList}
                fetchSerData={fetchSerData}
            />
            <Assigent_Member
                assigentModal={assigentModal}
                setAssigentModal={setAssigentModal}
                allStaffApis={allStaffApis}
                setAllStaffApi={setAllStaffApi}
                setStaffList={setStaffList}
                staffList={staffList}
                fetchStaffData={fetchStaffData}
                assignedListNew={assignedListNew}
                setAssignedListNew={setAssignedListNew}
                fetchAssisgnedData={fetchAssisgnedData}
                setBookeeperList={setBookeeperList}
                bookeeperList={bookeeperList}
                fetchBookeeperData={fetchBookeeperData}
                communiList={communiList}
                setCommuniList={setCommuniList}
                fetchCommuniData={fetchCommuniData}
                fetchAsiData={fetchAsiData}
            />
            <Upload_Files
                uploadModal={uploadModal}
                setUploadModal={setUploadModal}

            />

        </>
    )
}
export default BusClientProfie;