import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "../../../App";
import { setUserData, selectUser } from "../../../reduxTool/features/userSlice";
import { domainName } from "../../../helpers/help";

const AdminReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [domainName1, setDomainName1] = useState(domainName());
  const [active, setActive] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({ mode: "all" });
  const data = JSON.parse(localStorage.getItem("formValues"));
  const { setShowLoader } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [users, setUsers] = useState([]); // Array to hold multiple users
  const passValues = watch(); // Get all form values

  // useEffect(() => {
  //   const modalState = localStorage.getItem("openLogoutModal");
  //   if (modalState === "true") {
  //     setOpenLogoutModal(true);
  //   }
  // }, []);

  const handleReviewSubmit = async () => {
    setShowLoader(true);
    const newUser = {
      // id: uuidv4(),
      ...passValues,
    };

    setUsers([...users, newUser]);
    const storedValues = newUser;
    Object.keys(storedValues).forEach((key) => {
      setValue(key, storedValues[key]);
      reset();
    });
    const updatedFormValues = {
      ...data,
      password: passValues.password,
      password_confirmation: passValues.password_confirmation,
    };

    try {
      const response = await axios.post(
        // "{{ url('/') }}/api/tenants/add",
        `${process.env.REACT_APP_BASE_URL}/tenants/add`,

        updatedFormValues,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // const authToken = uuidv4();
      localStorage.setItem("formValues", JSON.stringify(updatedFormValues));
      // Auth.login({ token: authToken }); // Assuming your user data has a 'token' field
      setShowLoader(false);
      dispatch(setUserData(updatedFormValues));
      // navigate("/admin/unverified");
      toast.success("Please check your Email.");
      setActive(true);
      setOpenLogoutModal(true);
    } catch (error) {
      let errorMessage;
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.password
      ) {
        errorMessage = error.response.data.error.password[0];
      }
      toast.error(errorMessage);
      setShowLoader(false);
    }
  };

  // useEffect(() => {
  //   localStorage.setItem("openLogoutModal", openLogoutModal);
  // }, [openLogoutModal]);

  const isFormEmpty = () => {
    // Check if each field is empty
    return (
      !passValues.password ||
      !passValues.password_confirmation

    );
  };

  return (
    <div className="form-page">
      <div className="row m-auto">
        <div className="col-lg-6 formLeft vh-100">
          <div className="left-text position-relative vh-100">
            <img
              src={require("../../../assets/images/assist-logo.png")}
              alt="logo"
              className="w-16"
            />

            <div className="text-bottom pe-5 lg:pe-0 mt-0 lg:mt-5 position-absolute">
              <h2 className="text-white font-impact mb-0 lg:mb-5">Assisterp</h2>
              <p className="fw-light mt-4 text-white font-poppins">
                It is a long-established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 bg-white formRight position-relative d-flex align-items-center justify-content-center py-5">
          <img
            src={require("../../../assets/images/form-top.png")}
            alt="formtop"
            className="formtop position-absolute"
          />
          <img
            src={require("../../../assets/images/form-bottom.png")}
            alt="formbottom"
            className="formbottom position-absolute bottom-0"
          />
          {/* FORM START */}
          <div className="main-form font-poppins">
            <form onSubmit={handleSubmit(handleReviewSubmit)}>
              <div className="form-head">
                <h2 className="fw-bold">Review Details</h2>
                <h6 className="text-muted pe-3">
                  Please review the details & create your password below.
                </h6>
              </div>

              <div className="form-area mt-4">
                {/* <div class="review-text d-flex align-items-center">
                              <img src={require("../../../assets/images/email.png")} alt="emailIcon" className="" />
                              <div className="ms-3">
                                  <h3>Your Email address</h3>
                                  <h4>johnsmith@gmail.com</h4>
                              </div>
                          </div>

                          <div class="review-text d-flex align-items-center">
                              <img src={require("../../../assets/images/email.png")} alt="emailIcon" className="" />
                              <div className="ms-3">
                                  <h3>Your Domain Name</h3>
                                  <h4>assisterp.com</h4>
                              </div>
                          </div> */}

                <div className="form-group input-group position-relative mt-3">
                  <label for="name" className="text-muted">
                    Email
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/company.png")}
                      alt="companyname"
                      className="position-absolute"
                    />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control border-0 py-3 w-100 bg-red"
                      value={data?.email}
                      disabled
                    />
                  </div>
                </div>

                <div className="form-group input-group position-relative mt-3">
                  <label for="name" className="text-muted">
                    Domain Name
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/domain.png")}
                      alt="emailIcon"
                      className="position-absolute"
                    />
                    <input
                      type="text"
                      className="form-control border-0 py-3 w-100 bg-red"
                      name="domain"
                      id="domain"
                      value={data?.domain}
                      disabled
                    />
                    <span className="position-absolute domain-text fw-bold">
                      {domainName1}
                    </span>
                  </div>
                </div>

                <div className="form-group input-group position-relative mt-3">
                  <label for="password" className="text-muted">
                    Create Password
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/lock.png")}
                      alt="lockIcon"
                      className="position-absolute"
                    />
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control border-0 py-3 w-100 text-black"
                      placeholder="**********"
                      name="password"
                      id="password"
                      {...register("password", {
                        required: true,
                        minLength: 8,
                      })}
                    />
                    {errors.password?.type === "required" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 Pt-1 -fs-7 ms-1"
                      >
                        Required
                      </p>
                    )}
                    {errors.password?.type === "minLength" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1 -fs-7 ms-1"
                      >
                        Password must be at least 8 characters
                      </p>
                    )}
                    {/* EYES ICON */}
                    <div className="eye-icons position-absolute">
                      <>
                        {showPassword ? (
                          <img
                            src={require("../../../assets/images/eye.png")}
                            className="me-2"
                            alt="emailIcon"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <img
                            src={require("../../../assets/images/eye-hide.png")}
                            className="me-2"
                            alt="emailIcon"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </>
                    </div>
                    {/* EYES ICON */}
                  </div>
                </div>

                <div className="form-group input-group position-relative mt-3">
                  <label for="password" className="text-muted">
                    Confirm Password
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/lock.png")}
                      alt="lockIcon"
                      className="position-absolute"
                    />
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control border-0 py-3 w-100 text-black"
                      name="password_confirmation"
                      id="password_confirmation"
                      placeholder="Confirm Password"
                      {...register("password_confirmation", {
                        required: true,
                        minLength: 8,
                      })}
                    />
                    {errors.password_confirmation?.type === "minLength" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1 -fs-7 ms-1"
                      >
                        Password must be at least 8 characters
                      </p>
                    )}
                    {/* EYES ICON */}
                    <div className="eye-icons position-absolute">
                      <>
                        {showConfirmPassword ? (
                          <img
                            src={require("../../../assets/images/eye.png")}
                            className="me-2"
                            alt="emailIcon"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          />
                        ) : (
                          <img
                            src={require("../../../assets/images/eye-hide.png")}
                            className="me-2"
                            alt="emailIcon"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          />
                        )}
                      </>
                    </div>
                    {/* EYES ICON */}
                  </div>
                </div>

                {/* <div className="error-text mt-3 d-flex align-items-start">
                  <img
                    src={require("../../../assets/images/tick.png")}
                    alt="tickIcon"
                    className="me-2"
                  />
                  <p className="mb-0">
                    A combination of uppercase letters, lowercase letters,
                    numbers, and symbols.
                  </p>
                </div> */}

                <div className="form-group mt-3 text-center">
                  {/* <input
                    type="submit"
                    value="Continue"
                    className="w-100 sub-button rounded-4 border-none border py-3 text-white"
                  /> */}
                  <button
                    type="submit"
                    className="w-100  btn btn-primary  rounded-4  border-none border  py-3 text-white"
                    disabled={isFormEmpty()}
                  >
                    Continue
                  </button>
                </div>

                <div className="or-area d-flex align-items-center py-4">
                  <span className="d-block bg-gray w-100"></span>
                  <p className="m-0 px-2">or</p>
                  <span className="d-block bg-gray w-100"></span>
                </div>

                <div className="account-text text-center">
                  <h4>
                    Already have an account?{" "}
                    <Link className="text-decoration-none">
                      Login
                    </Link>
                  </h4>
                </div>
              </div>
            </form>
          </div>
          {/* FORM END */}

        </div>
      </div>
      <Modal
        show={openLogoutModal}
        onHide={() => setOpenLogoutModal(false)}
        backdrop="static"
        centered
        className="logoutmodal center-close-icon"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <div className="bodyModal p-4">
          <div className="popup-area position-relative">
            <div className="position-absolute right-pic end-0"><img className="text-end" src={require("../../../assets/images/review-right.png")}
            /></div>
            <div className="review-main font-poppins p-4">
              <img className="text-end" src={require("../../../assets/images/mail.png")}
              />
              <h4 className="fw-bold mt-4">Confirm Email Address</h4>
              <p>We have just sent you an email requesting confirmation of your email address. Please check your inbox and follow the instructions provided to confirm your email address. Thank you.</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AdminReview;
