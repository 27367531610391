import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
const Add_Task_Status = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showStatus, setShowStatus, handleStatusData } = props;

    const addStatus = () => {
        http(
            {
                method: "POST",
                url: `status_task/add`,
                isSecure: true,
                body: {
                    name: formValues.name,
                },
            },
            (res) => {
                toast.success("Add Status Successfully")
                setShowStatus(false);
                handleStatusData();
                reset();
            },
            (err) => {

            }
        );
    }
    return (
        <Modal
            show={showStatus}
            onHide={setShowStatus}
            centered

            contentClassName="mx-auto shadow-main p-0"
            className="modal pin-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header px-4 pt-4 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add Priority</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>

            <div class="services-form row px-2 mt-2">
                <div class="col-lg-12 mt-1">
                    <form
                        onSubmit={handleSubmit(addStatus)}
                    >
                        <div className="modal-footer border-0 justify-content-end p-0">
                            <input
                                name="name"
                                id="name"
                                class="form-input mt-2"
                                type="text"
                                {...register("name", {
                                })}
                            />
                            <button type="submit" class="btn-blue mt-5">Add</button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}
export default Add_Task_Status;