import "./App.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, createContext } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import Spinner from "react-bootstrap/Spinner";
import SuperAdminLogin from "./components/superAdmin/onboarding/SuperAdminLogin";
import SuperAdminDashboard from "./components/superAdmin/dashboard/SuperAdminDashboard";
import SuperAdminTenant from "./components/superAdmin/dashboard/SuperAdminTenant";
import AdminLogin from "./components/admin/onboarding/AdminLogin";
import AdminSignIn from "./components/admin/onboarding/AdminSignIn";
import AdminReview from "./components/admin/onboarding/AdminReview";
import AdminEmailVerified from "./components/admin/onboarding/AdminEmailVerified";
import AdminDashBoard from "./components/admin/dashboard/AdminDashBoard";
import AdminClient from "./components/admin/dashboard/AdminClient";
import ClientSignIn from "./components/client/onboarding/ClientSignIn";
import Auth from "./auth/Auth";
import ClientLogin from "./components/client/onboarding/ClientLogin";
import Edit_Profile from "./components/superAdmin/editProfile/Edit_Profile";
import ForgetPassword from "./components/admin/onboarding/ForgetPassword";
import GetOtp from "./components/admin/onboarding/GetOtp";
import RecoverPassword from "./components/admin/onboarding/RecoverPassword";
import DashboardLayout from "./components/layout/DashboardLayout";
import AddIndividualClient from "./components/admin/dashboard/AddIndividualClient";
import AddBusiness from "./components/admin/dashboard/AddBusiness";
import BusClientProfie from "./components/admin/businessProfile/BusClientProfile";
import IndClientProfile from "./components/admin/individulprofile/IndClientProfile";
import Notes from "./components/admin/notes/Notes";
import Notes_Detail from "./components/admin/notes/Notes_Detail";
import Task from "./components/admin/task/Task";
import Breadcrumbs from "./components/admin/header/Breadcrumbs";
import Add_Business_Notes from "./components/admin/businessNotes/Add_Business_Notes";
import Add_Invoice from "./components/admin/invoice/Add_Invoice";
import Business_Task from "./components/admin/businessTask/Business_Task";
import Add_Main_Task from "./components/admin/SidebarTask/Add_Main_Task"
import Invoice_Details from "./components/admin/invoice/Invoice_Details";
import Invoice_Preview from "./components/admin/invoice/Invoice_Preview";
import Edit_Invoice from "./components/admin/invoice/Edit_Invoice";
import Task_Details from "./components/admin/SidebarTask/Task_Details";
import Payment from "./components/admin/invoice/Payment";
import Edit_Task from "./components/admin/task/taskModal/Edit_Task";
import Add_Reccuring_Invoice from "./components/admin/invoice/Add_Reccuring_Invoice";
import Reccuring_Preview from "./components/admin/invoice/Reccuring_Preview";
import Edit_Reccuring_Invoice from "./components/admin/invoice/Edit_Reccuring_Invoice";
import Corporate_Notes_Details from "./components/admin/businessNotes/Corporarte_Notes_Details";
import Individual_Task_Detail from "./components/admin/task/Individual_Task_Detail";
import Add_Main_Tasks from "./components/admin/SidebarTask/tsidebarTaskModal/Add_Main_Tasks";
import Add_Task from "./components/admin/task/taskModal/Add_Task";
import Add_Tasks from "./components/admin/businessTask/businessTaskModal/Add_Tasks";
import Business_Task_Detail from "./components/admin/businessTask/Business_Task_Detail";
import Add_Recurring_Task from "./components/admin/SidebarTask/tsidebarTaskModal/Add_Recurring_Task";
import Recurring_Task_Details from "./components/admin/SidebarTask/Recurring_Task_Details";
import Recurring_Add_Task from "./components/admin/task/taskModal/Recurring_Add_Task";
import Business_Recurring_Add_Task from "./components/admin/businessTask/businessTaskModal/Business_Recurring_Add_Task";
import Individual_Recurring_Task_Detail from "./components/admin/task/Individual_Recurring_Task_Detail";
import Recurring_Business_Task_Detail from "./components/admin/businessTask/Recurring_Business_Task_Detail";
import Setting from "./components/admin/setting/Setting";
import CustomForms from "./components/admin/setting/customization/CustomForms";
import Add_Custom_Form from "./components/admin/setting/customization/Add_Custom_Form";
import Edit_Servay_Form from "./components/admin/setting/customization/Edit_Servay_Form";


// Global context
export const GlobalContext = createContext(null);

function App() {
    const [showLoader, setShowLoader] = useState(false);
    const [userRole, setUserRole] = useState(
        window.location.pathname.split("/")[1] === "superAdmin"
            ? "superAdmin"
            : window.location.pathname.split("/")[1] === "client"
                ? "client"
                : "admin"
    ); // Example role

    return (
        <>
            {showLoader && (
                <div className="loaderDiv">
                    <Spinner
                        animation="border"
                        className="loader"
                        variant="white"
                    />
                </div>
            )}

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <GlobalContext.Provider value={{ setShowLoader, setUserRole }}>
                <Router>
                    <Routes>
                        {userRole === "superAdmin" && (
                            <Route path="/superAdmin/*">
                                <Route
                                    path="login"
                                    element={<SuperAdminLogin />}
                                />
                                <Route
                                    path="dashboard"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <SuperAdminDashboard />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="tenant"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <SuperAdminTenant />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="edit_profile"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Edit_Profile />
                                        </DashboardLayout>
                                    }
                                />
                            </Route>
                        )}
                        {userRole === "admin" && (

                            <Route path="/admin/*">
                                <Route element={<Breadcrumbs />} />
                                <Route path="login" element={<AdminLogin />} />
                                <Route
                                    path="signin"
                                    element={<AdminSignIn />}
                                />
                                <Route
                                    path="review"
                                    element={<AdminReview />}
                                />
                                <Route
                                    path="unverified"
                                    element={<AdminEmailVerified />}
                                />
                                <Route
                                    path="forgetpassword"
                                    element={<ForgetPassword />}
                                />
                                <Route path="otp" element={<GetOtp />} />
                                <Route
                                    path="changepassword"
                                    element={<RecoverPassword />}
                                />
                                <Route
                                    path="dashboard"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <AdminDashBoard />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <AdminClient />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="task"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Add_Main_Task />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="task/add_task"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Add_Main_Tasks />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="task/add_recurring_task"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Add_Recurring_Task />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="task/taskdetail/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Task_Details />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="task/recurring_task_detail/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Recurring_Task_Details />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/add_individual"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <AddIndividualClient />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/add_business"
                                    element={
                                        <DashboardLayout userRole={userRole}><AddBusiness /></DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/business_profile/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}><BusClientProfie /></DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/individual_profile/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}><IndClientProfile /> </DashboardLayout>
                                    }

                                />
                                <Route
                                    path="client/note"
                                    element={
                                        <DashboardLayout userRole={userRole}><Notes /> </DashboardLayout>
                                    }

                                />
                                <Route
                                    path="client/note/notedetail/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}><Notes_Detail /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/individual_task"
                                    element={
                                        <DashboardLayout userRole={userRole}><Task /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/individual_task/add_task"
                                    element={
                                        <DashboardLayout userRole={userRole}><Add_Task /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/individual_task/recurring_add_task"
                                    element={
                                        <DashboardLayout userRole={userRole}><Recurring_Add_Task /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/individual_task/individual_task_detail/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Individual_Task_Detail />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/individual_task/individual_recurring_task_detail/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Individual_Recurring_Task_Detail />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/task/edit_task/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}><Edit_Task /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/corporate_note"
                                    element={
                                        <DashboardLayout userRole={userRole}><Add_Business_Notes /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/corporate_note/note_detail/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}><Corporate_Notes_Details /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/corporate_task"
                                    element={
                                        <DashboardLayout userRole={userRole}><Business_Task /> </DashboardLayout>
                                    }
                                />

                                <Route
                                    path="client/corporate_task/add_tasks"
                                    element={
                                        <DashboardLayout userRole={userRole}><Add_Tasks /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/corporate_task/business_recurring_add_task"
                                    element={
                                        <DashboardLayout userRole={userRole}><Business_Recurring_Add_Task /> </DashboardLayout>
                                    }
                                />

                                <Route
                                    path="client/corporate_task/business_task_detail/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Business_Task_Detail />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="client/corporate_task/recurring_task_detail/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Recurring_Business_Task_Detail />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="invoice/add_invoice"
                                    element={
                                        <DashboardLayout userRole={userRole}><Add_Invoice /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="invoice/add_reccuring_invoice"
                                    element={
                                        <DashboardLayout userRole={userRole}><Add_Reccuring_Invoice /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="invoice/invoice_detail"
                                    element={
                                        <DashboardLayout userRole={userRole}><Invoice_Details /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="invoice/invoice_preview/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}><Invoice_Preview /> </DashboardLayout>
                                    }
                                />

                                <Route
                                    path="invoice/reccuring_preview/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}><Reccuring_Preview /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="invoice/edit_invoice/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}><Edit_Invoice /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="invoice/edit_reccuring_invoice/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}><Edit_Reccuring_Invoice /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="invoice/payment"
                                    element={
                                        <DashboardLayout userRole={userRole}><Payment /> </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="setting"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Setting />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="setting/custom_forms"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <CustomForms />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="setting/add_custom_form"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Add_Custom_Form />
                                        </DashboardLayout>
                                    }
                                />
                                <Route
                                    path="setting/edit_servay_form/:id"
                                    element={
                                        <DashboardLayout userRole={userRole}>
                                            <Edit_Servay_Form />
                                        </DashboardLayout>
                                    }
                                />




                                {/* Added route for AdminClient */}
                            </Route>
                        )}

                        {/* Default route for the entire application */}
                        <Route
                            path="/"
                            element={<Navigate to="/admin/login" replace />}
                        />
                    </Routes>
                </Router>
            </GlobalContext.Provider>
        </>
    );
}

export default App;
