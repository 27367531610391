import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
const Add_Interaction = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showInderaction, setShowInderaction, handleInteractionData } = props;

    const addIntraction = () => {
        http(
            {
                method: "POST",
                url: `interaction_type/add`,
                isSecure: true,
                body: {
                    name: formValues.name,
                },
            },
            (res) => {
                toast.success("Add Intraction Successfully")
                setShowInderaction(false);
                handleInteractionData();
                reset();
            },
            (err) => {

            }
        );
    }
    return (
        <Modal
            show={showInderaction}
            onHide={setShowInderaction}
            centered

            contentClassName="mx-auto"
            className="modal pin-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add Business Type</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>

            <div className="services-form row px-2 mt-2">
                <div className=" col-lg-12 mt-1">
                    <form
                        onSubmit={handleSubmit(addIntraction)}
                    >
                        <div className="modal-footer border-0 justify-content-center p-0">
                            <input
                                name="name"
                                id="name"
                                className="form-input mt-2 w-100"
                                type="text"
                                {...register("name", {
                                })}

                            />
                        </div>
                        <button type="submit" className="btn-blue mt-3 block ms-auto">Add</button>
                    </form>
                </div>
            </div>
        </Modal >
    )
}
export default Add_Interaction;