import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { http } from "../../http/http";
import { toast } from "react-toastify";

const AddBusiness = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customFormList, setCustomFormList] = useState([]);

    const handleCheckboxCustomForm = (event) => {
        const value = parseInt(event.target.value, 10); // Parse the value as an integer

        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const handleCustomFormList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFormList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleCustomFormList();
    }, []);

    const handleBusieness = () => {
        // Create an array for form IDs
        const formIds = [];
        selectedOptions.forEach(id => {
            formIds.push(id);
        });
        http(
            {
                method: "POST",
                url: "corporate/add",
                isSecure: true,
                body: {
                    email: formValues.email,
                    folder_number: formValues.folder_number,
                    name: formValues.name,
                    status: formValues.status,
                    office_address: formValues.office_address,
                    form_id: formIds  // Add form_ids array to the request body
                },
            },
            (res) => {
                toast.success("Business Client Add Successfully")
                reset();
                navigate(`/admin/client/business_profile/${res?.data[0]?.id}`);
            },
            (err) => {
                if (err?.status == 401) {
                    for (const key in err.message) {
                        if (err.message.hasOwnProperty(key)) {
                            const errorArray = err.message[key];
                            errorArray.forEach((message) => {
                                toast.error(` ${message}`);

                            });
                        }
                    }
                }
            }
        );

    }

    return (
        <div className="right_section font-poppins">
            <div className="business-form-area py-3">
                <div className="row business-head align-items-center">
                    <div className="col-md-12 left_head">
                        <h4 className="fs-24 mt-2">Add Business Client</h4>
                    </div>
                    <div className="col-md-12">
                        <div className="main-form-area p-4 mt-3 bg-white shadow-lg">
                            <form onSubmit={handleSubmit(handleBusieness)}>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <h6 className="fs-20">Basic Details</h6>
                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">Name of Incorporation <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("name", {
                                                required: true,
                                                pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/,
                                            })}
                                        />
                                        {errors.name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.name &&
                                            errors.name.type === "pattern" && (
                                                <p
                                                    role="alert"
                                                    className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                                >
                                                    At least add one alphabetic
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="name">Folder Name <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="folder_number"
                                            id="folder_number"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("folder_number", {
                                                required: true,
                                            })}
                                        />
                                        {errors.folder_number?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="email">Email Address <span className="text-danger">*</span></label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("email", {
                                                required: true,
                                                pattern: /\S+@\S+\.\S+/,
                                            })}
                                        />
                                        {errors.email?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1-fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.email?.type === "pattern" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Incorrect email format
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="name">Status <span className="text-danger">*</span></label>
                                        <select
                                            name="status"
                                            id="status"
                                            className="form-input mt-2"
                                            {...register("status", {
                                                required: true,
                                            })}

                                        >
                                            <option
                                                value=""
                                                selected
                                                disabled="disabled"
                                            >
                                                Status
                                            </option>
                                            <option value="1">Active</option>
                                            <option value="2">InActive</option>
                                        </select>
                                        {errors.status?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-6 mt-4">
                                        <label htmlFor="address">Reg. Office Address</label>
                                        <input
                                            type="text"
                                            name="office_address"
                                            id="office_address"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("office_address", {
                                                required: true,
                                            })}

                                        />
                                    </div>

                                    <div className=" mt-3">
                                        <label htmlFor="Required Forms" className="inv-title text-black mb-2">Required Forms</label>
                                        {customFormList && customFormList.map((option) => (
                                            <div key={option.id} className="d-flex align-items-center gap-2 mt-2">
                                                <input
                                                    type="checkbox"
                                                    value={option.id}
                                                    checked={selectedOptions.includes(option.id)}
                                                    onChange={handleCheckboxCustomForm}
                                                />
                                                <label className="text-black">{option.data[0]?.title}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-md-12 individual-footer">
                                        <div className="btn-groups mt-4">
                                            <button
                                                type="submit"
                                                className="btn save-btn ms-2"
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddBusiness; 