import React, { useState, useEffect } from "react";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { Modal } from "react-bootstrap";
import InputMask from 'react-input-mask';

const Edit_Task = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });

    const storedId = JSON.parse(localStorage.getItem("clientId"));
    const [editTaskDetail, setEditTaskDetail] = useState({
        documents: [],
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [deleteImageIDs, setDeleteImageIDs] = useState([]);
    const [showEditAssignOpen, setShowEditAssignOpen] = useState(false);
    const [assigneeIds, setAssigneeIds] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [interactionList, setInteractionList] = useState([]);
    const [StatusList, setStatusList] = useState([]);
    const [assignData, setAssignData] = useState([]);
    const [taskDetails, setTaskDetails] = useState({
        interaction_type_id: 0,
        priority_id: 0,
        status_task_id: 0,
    });
    const [timeFormats, setTimeFormats] = useState({
        start_time_format: editTaskDetail?.start_time_format || 'AM',
        end_time_format: editTaskDetail?.end_time_format || 'AM',
    });
    const [searchTerm, setSearchTerm] = useState('');


    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };
    const handleFieldChange = (fieldName, value) => {
        setTaskDetails(prevTaskDetails => ({
            ...prevTaskDetails,
            [fieldName]: value,
        }));
    };
    const handleFormatChange = (e) => {
        const { name, value } = e.target;
        setTimeFormats({ ...timeFormats, [name]: value });
    };
    const toggleAssignOpen = () => {
        setShowEditAssignOpen(!showEditAssignOpen);
    };

    const toggleAssignee = (id) => {
        if (assigneeIds.includes(id)) {
            setAssigneeIds(prevIds => prevIds.filter(assigneeId => assigneeId !== id));
        } else {
            setAssigneeIds(prevIds => [...prevIds, id]);
        }
    };




    const filteredList = interactionList.filter((data) =>
        data.name.toLowerCase().includes(searchTerm.toLowerCase())
    );



    const editTaskHandle = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/task/${storedId}/${id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setEditTaskDetail(res?.data);
                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });

                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    useEffect(() => {
        editTaskHandle();
    }, []);

    const updateTaskHandle = async () => {
        try {
            const formData = new FormData();
            formData.append('name', editTaskDetail?.name);
            formData.append('description', editTaskDetail?.description);
            formData.append('interaction_type_id', taskDetails.interaction_type_id);
            formData.append('priority_id', taskDetails.priority_id);
            formData.append('status_task_id', taskDetails.status_task_id);
            formData.append('start_date', editTaskDetail?.start_date);
            formData.append('start_time', editTaskDetail?.start_time);
            formData.append('start_time_format', timeFormats?.start_time_format);
            formData.append('end_date', editTaskDetail?.end_date);
            formData.append('end_time', editTaskDetail?.end_time);
            formData.append('end_time_format', timeFormats?.end_time_format);
            formData.append('estimation_time', editTaskDetail?.estimation_time);
            formData.append('time_spent', editTaskDetail?.time_spent);
            formData.append('id', editTaskDetail?.id);
            formData.append('client_id', editTaskDetail?.client_id);
            formData.append('task_type', editTaskDetail?.task_type);
            formData.append('image_delete_ids', deleteImageIDs.join(','));

            assigneeIds.forEach(id => {
                formData.append('staff_id[]', id);
            });
            // editTaskDetail.documents.forEach(doc => {
            //     formData.append('hidden_file[]', doc?.document_name);
            // });
            selectedFiles.forEach(file => {
                formData.append('file[]', file);
                console.log('File:', file);
            });
            // if (selectedFiles.length === 0) {
            //     editTaskDetail.documents.forEach(doc => {
            //         formData.append('hidden_file[]', doc?.document_name);
            //     });
            // }


            http(
                {
                    method: "POST",
                    url: `client/task/update/${storedId}/${editTaskDetail?.id}`,
                    isSecure: true,
                    body: formData,
                    contentType: "multipart/form-data",
                },
                (res) => {
                    console.log('Task updated successfully', res);
                    setSelectedFiles([]);
                    setDeleteImageIDs([]);
                },
            );
        } catch (error) {
            console.error('Error updating task:', error);
        }

    };


    const deleteImage = async (documentId, isNew = false) => {
        if (isNew) {
            setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== documentId));
        } else {
            setDeleteImageIDs(prevState => [...prevState, documentId]);
            setEditTaskDetail(prevState => ({
                ...prevState,
                documents: prevState.documents.filter(doc => doc.id !== documentId)
            }));
        }
    };
    const handlePriorityData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `priority/listing`,
                    isSecure: true,

                },
                (res) => {
                    setPriorityList(res?.data?.data);

                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    const handleInteractionData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `interaction_type/listing`,
                    isSecure: true,
                },
                (res) => {

                    setInteractionList(res?.data?.data);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    const handleStatusData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `status_task/listing`,
                    isSecure: true,
                },
                (res) => {
                    setStatusList(res?.data?.data);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    const handleAssisgnData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `staff/listing`,
                    isSecure: true,
                },
                (res) => {
                    setAssignData(res?.data?.data);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };


    useEffect(() => {
        if (editTaskDetail) {
            setTaskDetails(prevTaskDetails => ({
                ...prevTaskDetails,
                interaction_type_id: editTaskDetail?.interactiontype?.id || 0,
                priority_id: editTaskDetail?.priority?.id || 0,
                status_task_id: editTaskDetail?.statustask?.id || 0,
            }));
        }
    }, [editTaskDetail?.interactiontype?.id, editTaskDetail?.priority?.id, editTaskDetail?.statustask?.id]);

    useEffect(() => {
        if (editTaskDetail) {
            setTimeFormats(prevTimeFormats => ({
                ...prevTimeFormats,
                start_time_format: editTaskDetail?.start_time_format || 'AM',
                end_time_format: editTaskDetail?.end_time_format || 'AM',
            }));
        }
    }, [editTaskDetail?.start_time_format, editTaskDetail?.end_time_format]);

    useEffect(() => {
        if (editTaskDetail && editTaskDetail.assignees) {
            const ids = editTaskDetail.assignees.map(assignee => assignee.id);
            setAssigneeIds(ids);
        }
    }, [editTaskDetail?.start_time_format, editTaskDetail?.end_time_format]);
    useEffect(() => {
        handleInteractionData();
        handlePriorityData();
        handleStatusData();
        handleAssisgnData();

    }, []);

    return (
        <div className="right_section font-poppins">
            <form onSubmit={handleSubmit(updateTaskHandle)}>
                <div className="task-detail-area">

                    <div className="row">

                        <div className="col-md-8 mt-4 ps-0">
                            <div className="edit-task-form">
                                <h4 className="fs-20 fw-bold">Edit Task Details</h4>

                                {/* <div className="row">
                                    <div className="col-md-4 ps-0 mt-3">
                                        <label for="">Select Customer Type (Optional)</label>
                                        <select className="form-input mt-2">
                                            <option>Customer 1</option>
                                            <option>Customer 2</option>
                                            <option>Customer 3</option>
                                            <option>Customer 4</option>
                                            <option>Customer 5</option>
                                            <option>Customer 6</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 ps-0 mt-3">
                                        <label for="">Individual Client</label>
                                        <select className="form-input mt-2">
                                            <option>John Jacobs</option>
                                            <option>Alex Hales</option>
                                            <option>Joe Root</option>
                                            <option>Jos Buttler</option>
                                            <option>Virat Kohli</option>
                                            <option>Jasprit B</option>
                                        </select>
                                    </div>
                                </div> */}

                                <div class="d-flex align-items-center justify-content-between border-input">
                                    <input type="text"
                                        class="w-bold text-black p-0 fs-30 h-0 det-input w-100 mt-3"
                                        placeholder="Title"
                                        name="name"
                                        value={editTaskDetail?.name} // Bind the value from editTaskDetail
                                        onChange={(e) => {
                                            setEditTaskDetail({ ...editTaskDetail, name: e.target.value });
                                        }}
                                    />
                                    <div class="close-icon border-0 p-0 modal-header">
                                        <button type="button" class="btn-close" aria-label="Close"></button>
                                    </div>
                                </div>
                                <textarea
                                    rows="8"
                                    cols="10"
                                    className="mt-4 bg-white text-black"
                                    value={editTaskDetail?.description} // Bind the value from editTaskDetail
                                    onChange={(e) => {
                                        setEditTaskDetail({ ...editTaskDetail, description: e.target.value });
                                    }}
                                >
                                </textarea>
                                <div class="mt-3">
                                    <div class="main-wrapper">
                                        <div class="upload-main-wrapper d-flex align-items-center justify-content-between w-100 border-1 border-dashed gap-2 bg-white">
                                            <h4 class="fs-14">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" width="13" height="20" viewBox="0 0 13 20"><path id="XMLID_197_" d="M68.356,0h0a4.663,4.663,0,0,0-3.283,1.331,4.469,4.469,0,0,0-1.36,3.214v8.182a2.786,2.786,0,0,0,5.571,0V8.006a.929.929,0,0,0-1.857,0v4.721a.929.929,0,0,1-1.857,0V4.545a2.681,2.681,0,0,1,.816-1.928,2.8,2.8,0,0,1,1.97-.8,2.76,2.76,0,0,1,2.786,2.727v9.091A4.6,4.6,0,0,1,66.5,18.182a4.663,4.663,0,0,1-3.283-1.331,4.468,4.468,0,0,1-1.36-3.214V4.545a.929.929,0,0,0-1.857,0v9.091a6.256,6.256,0,0,0,1.9,4.5A6.528,6.528,0,0,0,66.5,20,6.44,6.44,0,0,0,73,13.636V4.545A4.6,4.6,0,0,0,68.356,0Z" transform="translate(-59.999)" fill="#202529"></path></svg>Drag &amp; drop here</h4>
                                            <div class="upload-wrapper">
                                                <input
                                                    type="file"
                                                    id="file"
                                                    name="file"
                                                    multiple
                                                    onChange={handleFileChange}

                                                />
                                                <i class="fa fa-upload me-2"></i>
                                                <span class="file-upload-text">Browse Files</span>
                                            </div>
                                        </div>
                                        <div class="image-preview-area"></div>
                                    </div>
                                </div>
                                <div className="mt-1">

                                    <div className="edit-pics d-flex align-items-center gap-3 ">
                                        {editTaskDetail?.documents.map((preview, index) => (
                                            <div key={`preview-${preview.id}`} className="pic_box position-relative mt-3">
                                                <img src={preview?.document_path} className="me-2" alt="user-pic" />
                                                <div className="cross-pic position-absolute" onClick={() => deleteImage(preview.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                                        <g id="Group_1589" data-name="Group 1589" transform="translate(-1173 -1120)">
                                                            <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#ccc" />
                                                            <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#fff" />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}

                                        {selectedFiles.map((file, index) => (
                                            <div key={`file-${index}`} className="pic_box position-relative mt-3">
                                                <img src={URL.createObjectURL(file)} className="me-2" alt="user-pic" />
                                                <div className="cross-pic position-absolute" onClick={() => deleteImage(index, true)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                                        <g id="Group_1589" data-name="Group 1589" transform="translate(-1173 -1120)">
                                                            <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#ccc" />
                                                            <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#fff" />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                <div className="edit-btns d-flex align-content-center justify-content-end gap-2 mt-5">
                                    <button className="btn-cancel">Cancel</button>
                                    <button className="btn-add" type="submit">Update</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 overflow-hidden">
                            <div class="right-task-edit overflow-y-scroll">

                                <div className=" col-md-12 mt-4 px-4">
                                    <label for="" className="fs-18 px-1">Interaction Type</label>
                                    {/* <select
                                        name="interaction_type_id"
                                        id="interaction_type_id"
                                        className="form-input bg-transparent fs-20 fw-bold px-1"
                                        value={taskDetails.interaction_type_id}
                                        onChange={(e) => handleFieldChange('interaction_type_id', parseInt(e.target.value, 10))}
                                    >
                                        <option value="0" disabled>Select</option>
                                        {interactionList && interactionList.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.name}
                                            </option>
                                        ))}
                                    </select> */}
                                    <div className="custom-select-container">

                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="form-input bg-transparent fs-20 fw-bold px-1"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />

                                        <select
                                            name="interaction_type_id"
                                            id="interaction_type_id"
                                            className="form-input bg-transparent fs-20 fw-bold px-1 mt-2"
                                            value={taskDetails.interaction_type_id}
                                            onChange={(e) => handleFieldChange('interaction_type_id', parseInt(e.target.value, 10))}
                                        >
                                            <option value="0" disabled>Select</option>
                                            {filteredList.map((data) => (
                                                <option key={data.id} value={data.id}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                </div>
                                <div className=" col-md-12 mt-4 px-4">
                                    <label className="fs-18 px-1">Priority</label>
                                    <select
                                        name="priority_id"
                                        id="priority_id"
                                        className="form-input bg-transparent fs-20 fw-bold px-1"
                                        value={taskDetails.priority_id}
                                        onChange={(e) => handleFieldChange('priority_id', parseInt(e.target.value, 10))}
                                    >
                                        <option value="0" disabled selected>Select</option>
                                        {priorityList && priorityList.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.name}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                                <div className="col-md-12 mt-4 px-4 relative">
                                    <label className="fs-18 px-1" onClick={toggleAssignOpen}>Assignee</label>
                                    <h6 className="bg-transparent fs-20 fw-bold px-1" onClick={toggleAssignOpen}>
                                        Unassigned
                                    </h6>
                                    {showEditAssignOpen && (
                                        <div className="assign-open bg-white rounded-4 p-3 mt-3 position-absolute end-0 start-0 mx-4">
                                            <ul className="ps-0">
                                                {assignData && assignData.map((staff) => (
                                                    <li key={staff.id} className="d-flex justify-content-between align-items-center my-1">
                                                        <label className="fw-semibold text-black">
                                                            <img src={require("../../../../assets/images/user-pic.png")} className="me-2" alt="user-pic" />
                                                            {staff?.name}
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            name="staff_id"
                                                            id="staff_id"
                                                            value={staff.id} // Ensure the value is correctly set to staff.id
                                                            checked={assigneeIds.includes(staff.id)}
                                                            onChange={() => toggleAssignee(staff.id)}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className=" col-md-12 mt-4 px-4">
                                    <label className="fs-18 px-1">Status</label>

                                    <select
                                        name="status_task_id"
                                        id="status_task_id"
                                        className="form-input bg-transparent fs-20 fw-bold px-1"
                                        value={taskDetails.status_task_id}
                                        onChange={(e) => handleFieldChange('status_task_id', parseInt(e.target.value, 10))}
                                    >
                                        <option value="0" disabled selected>Select</option>
                                        {StatusList && StatusList.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.name}
                                            </option>
                                        ))}


                                    </select>
                                </div>
                                <div class=" col-md-12 px-4 date mt-3">
                                    <h4 class="fs-18 px-1 fw-bold text-black">Start Date</h4>
                                    <div class="row py-2 px-0">
                                        <div class="col-md-7 ps-0 pe-2">
                                            <label>Date</label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                class="form-input mt-1"
                                                value={editTaskDetail?.start_date} // Ensure this is correctly bound
                                                onChange={(e) => {
                                                    setEditTaskDetail({ ...editTaskDetail, start_date: e.target.value });
                                                }}
                                            />
                                        </div>
                                        <div class="col-md-3 ps-0 pe-2">
                                            <label>Time</label>
                                            <InputMask
                                                mask="99:99"
                                                maskChar={null}
                                                name="start_time"
                                                className="form-input mt-1 bg-white"
                                                placeholder="HH:MM"

                                                value={editTaskDetail?.start_time} // Ensure this is correctly bound
                                                onChange={(e) => {
                                                    setEditTaskDetail({ ...editTaskDetail, start_time: e.target.value });
                                                }}
                                            />
                                        </div>
                                        <div class="col-md-2 ps-0 pe-2">
                                            <label>Format</label>
                                            <select
                                                className="form-input mt-1 px-3 bg-white"
                                                name="start_time_format"
                                                value={timeFormats.start_time_format}
                                                onChange={handleFormatChange}
                                            >
                                                <option value="PM">PM</option>
                                                <option value="AM">AM</option>
                                            </select>
                                        </div>
                                        <div class="col-md-12 ps-0 pe-2">
                                            <p className="text-danger text-end fs-14 mt-2 fw-bold">Clear</p>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-md-12 px-4 date mt-3">
                                    <h4 class="fs-16 px-1 fw-bold text-black">End Date</h4>
                                    <div class="row py-2 px-0">
                                        <div class="col-md-7 ps-0 pe-2">
                                            <label>Date</label>
                                            <input
                                                type="date"
                                                name="end_date"
                                                class="form-input mt-1"

                                                value={editTaskDetail?.end_date} // Ensure this is correctly bound
                                                onChange={(e) => {
                                                    setEditTaskDetail({ ...editTaskDetail, end_date: e.target.value });
                                                }}
                                            />
                                        </div>
                                        <div class="col-md-3 ps-0 pe-2">
                                            <label>Time</label>
                                            <InputMask
                                                mask="99:99"
                                                maskChar={null}
                                                name="end_time"
                                                className="form-input mt-1 bg-white"
                                                placeholder="HH:MM"
                                                value={editTaskDetail?.end_time}
                                                onChange={(e) => {
                                                    setEditTaskDetail({ ...editTaskDetail, end_time: e.target.value });
                                                }}
                                            />
                                        </div>
                                        <div class="col-md-2 ps-0 pe-2">
                                            <label>Format</label>
                                            <select
                                                class="form-input mt-1 px-3 bg-white"
                                                name="end_time_format"
                                                value={timeFormats.end_time_format}
                                                onChange={handleFormatChange}
                                            >
                                                <option value="PM">PM</option>
                                                <option value="AM">AM</option>
                                            </select>
                                        </div>
                                        <div class="col-md-12 ps-0 pe-2">
                                            <p className="text-danger text-end fs-14 mt-2 fw-bold">Clear</p>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-md-12 px-4 date mt-3">
                                    <h4 class="fs-16 px-1 fw-bold text-black">Estimation Time</h4>
                                    <div class="row py-2 px-0">
                                        <div class="col-md-12 ps-0 pe-2">
                                            <input
                                                type="text"
                                                name="estimation_time"
                                                class="form-input mt-1 bg-white"
                                                placeholder="1w 1d 1h 1m"
                                                value={editTaskDetail?.estimation_time}
                                                onChange={(e) => {
                                                    setEditTaskDetail({ ...editTaskDetail, estimation_time: e.target.value });
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-md-12 px-4 date mt-3">
                                    <h4 class="fs-16 px-1 fw-bold text-black">Time Spent</h4>
                                    <div class="row py-2 px-0">
                                        <div class="col-md-12 ps-0 pe-2">
                                            <input
                                                type="text"
                                                name="time_spent"
                                                class="form-input mt-1 bg-white"
                                                placeholder="1w 1d 1h 1m"
                                                value={editTaskDetail?.time_spent}
                                                onChange={(e) => {
                                                    setEditTaskDetail({ ...editTaskDetail, time_spent: e.target.value });
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </div >
    )
}
export default Edit_Task;