import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
const Add_Business_Priority = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showPriority, setShowPriority, handlePriorityData } = props;

    const addPriority = () => {
        http(
            {
                method: "POST",
                url: `priority/add`,
                isSecure: true,
                body: {
                    name: formValues.name,
                },
            },
            (res) => {
                toast.success("Add Priority Successfully")
                setShowPriority(false);
                handlePriorityData();
                reset();
            },
            (err) => {

            }
        );
    }
    return (
        <Modal
            show={showPriority}
            onHide={setShowPriority}
            centered

            contentClassName="mx-auto"
            className="modal pin-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header px-4 pt-4 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add Priority</h4>
                <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
            </div>

            <div class="services-form row px-2 mt-2">
                <div class="col-lg-12 mt-1">
                    <form onSubmit={handleSubmit(addPriority)}>
                        <div className="modal-footer border-0 justify-content-center p-0">
                            <input name="name" id="name" class="form-input mt-2 w-100" type="text" {...register("name", {})} />
                        </div>
                        <button type="submit" class="btn-blue mt-3 block ms-auto">Add</button>
                    </form>
                </div>
            </div>
        </Modal>
    )
}
export default Add_Business_Priority;