import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const getOtpHandle = () => {
    navigate("/admin/otp");
  };
  return (
    <div className="form-page forgotpassword">
      <div className="row m-auto">
        <div className="col-lg-6 bg-white formRight position-relative d-flex align-items-center justify-content-center py-5">
          <img
            src={require("../../../assets/images/assistlogo.png")}
            alt="assistlogo"
            className="assistlogo position-absolute pb-5"
          />
          <img
            src={require("../../../assets/images/form-top.png")}
            alt="formtop"
            className="formtop position-absolute"
          />
          <img
            src={require("../../../assets/images/form-bottom.png")}
            alt="formbottom"
            className="formbottom position-absolute bottom-0"
          />
          <div className="main-form font-poppins">
            <form action="#" method="post">
              <div className="form-head">
                <img
                  src={require("../../../assets/images/forgoticon.png")}
                  className="mb-5 lock-forgot"
                  alt=""
                />
                <h2 className="fw-bold">Forgot Password</h2>
                <h6 className="text-muted">
                  Please provide your email address. We will send you an email
                  containing instructions to recover your password.
                </h6>
              </div>

              <div className="form-area mt-4">
                <div className="form-group input-group position-relative">
                  <label for="email" className="text-muted">
                    Email address
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/email.png")}
                      alt="emailIcon"
                      className="position-absolute"
                    />
                    <input
                      type="email"
                      className="form-control border-0 py-3 w-100 bg-red"
                      placeholder="eg.@gmail.com"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="form-group mt-3 text-center">
                  <button
                    type="submit"
                    className="w-100  btn btn-primary  rounded-4  border-none border  py-3 text-white"
                    onClick={getOtpHandle}
                  >
                    Get The Code
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="col-lg-6 formLeft vh-100">
          <div className="left-text position-relative vh-100">
            <div className="text-bottom pe-5 lg:pe-0 mt-0 lg:mt-5 position-absolute">
              <h2 className="text-white font-impact mb-0 lg:mb-5">Assisterp</h2>
              <p className="fw-light mt-4 text-white font-poppins">
                It is a long-established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgetPassword;
