import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { GlobalContext } from "../../../App";
import { useNavigate, Link } from "react-router-dom";
import Auth from "../../../auth/Auth";
import { http } from "../../http/http";
import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
const AdminLogin = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({ mode: "all" });
  const formValues = watch();
  const { setShowLoader } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const [users, setUsers] = useState([]);


  const onSubmit = async () => {
    setShowLoader(true);
    const newUser = {
      ...formValues,
    };
    setUsers([...users, newUser]);

    http(
      {
        method: "POST",
        url: "login",
        isSecure: true,
        body: {
          email: formValues.email,
          password: formValues.password,
        },
      },
      (res) => {
        if (res) {
          Auth.login(res);
          setShowLoader(false);
          navigate("/admin/dashboard");
          toast.success(res.message);
        }
      },
      (err) => {
        console.log(err, "err");
        setShowLoader(false); // Hide loader on error
        toast.error(err.message); // Show error message
      }
    );
  };


  const isFormEmpty = () => {
    // Check if each field is empty
    return !formValues.email || !formValues.password;
  };

  return (
    <div className="form-page">
      <div className="row m-auto">
        <div className="col-lg-6 formLeft vh-100">
          <div className="left-text position-relative vh-100">
            <img
              src={require("../../../assets/images/assist-logo.png")}
              alt="logo"
              className="w-16"
            />

            <div className="text-bottom pe-5 lg:pe-0 mt-0 lg:mt-5 position-absolute">
              <h2 className="text-white font-impact mb-0 lg:mb-5">Assisterp</h2>
              <p className="fw-light mt-4 text-white font-poppins">
                It is a long-established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 bg-white formRight position-relative d-flex align-items-center justify-content-center py-5">
          <img
            src={require("../../../assets/images/form-top.png")}
            alt="formtop"
            className="formtop position-absolute"
          />
          <img
            src={require("../../../assets/images/form-bottom.png")}
            alt="formbottom"
            className="formbottom position-absolute bottom-0"
          />
          {/* FORM START */}
          <div className="main-form font-poppins">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-head">
                <h2>
                  Hello, <span className="fw-bold">Welcome Back!</span>
                </h2>
                <h6 className="text-muted">Login to your account below.</h6>
              </div>

              <div className="form-area mt-4">
                <div className="form-group input-group position-relative">
                  <label className="text-muted">
                    Email address
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/email.png")}
                      alt="emailIcon"
                      className="position-absolute"
                    />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control border-0 py-3 w-100 bg-red"
                      placeholder="eg.@gmail.com"
                      {...register("email", {
                        required: true,
                        pattern: /\S+@\S+\.\S+/,
                      })}
                    />
                    {errors.email?.type === "required" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1 -fs-7 ms-1"
                      >
                        Required
                      </p>
                    )}
                    {errors.email?.type === "pattern" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1 -fs-7 ms-1"
                      >
                        Incorrect email format
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group input-group position-relative mt-3">
                  <label className="text-muted">
                    Password
                  </label>
                  <div className="icon-input w-100 rounded mt-2 position-relative">
                    <img
                      src={require("../../../assets/images/lock.png")}
                      alt="emailIcon"
                      className="position-absolute"
                    />
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      className="form-control border-0 py-3 w-100 bg-red"
                      placeholder="**********"
                      {...register("password", {
                        required: true,
                      })}
                    />
                    {errors.password?.type === "required" && (
                      <p
                        role="alert"
                        className="text-danger mb-0 pt-1 -fs-7 ms-1"
                      >
                        Required
                      </p>
                    )}
                    {/* EYES ICON */}
                    <div className="eye-icons position-absolute">
                      <>
                        {showPassword ? (
                          <img
                            src={require("../../../assets/images/eye.png")}
                            className="me-2"
                            alt="emailIcon"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <img
                            src={require("../../../assets/images/eye-hide.png")}
                            className="me-2"
                            alt="emailIcon"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </>
                    </div>
                    {/* EYES ICON */}
                  </div>
                </div>

                <div className="form-group mt-3 text-end forgot-text">
                  <Link to="/admin/forgetpassword" className="text-black text-decoration-none">
                    Forgot Password?
                  </Link>
                </div>

                <div className="form-group mt-3 text-center">
                  <button
                    type="submit"
                    className=" w-100  btn btn-primary  rounded-4  border-none border  py-3 text-white"
                    disabled={isFormEmpty()}
                  >
                    Login
                  </button>
                </div>

                <div className="or-area d-flex align-items-center py-4">
                  <span className="d-block bg-gray w-100"></span>
                  <p className="m-0 px-2">or</p>
                  <span className="d-block bg-gray w-100"></span>
                </div>

                <div className="account-text text-center">
                  <h4>
                    Don't have an account?{" "}
                    <Link to="/admin/signin" className="text-decoration-none">
                      Create New
                    </Link>
                  </h4>
                </div>
              </div>
            </form>
          </div>
          {/* FORM END */}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
