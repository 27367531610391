
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { Authform_id, Authform_id_1013, Business_status } from "../../../helpers/staticList";
import Add_BusinessType from "./Add_BusinessType";
import Add_BusinessNature from "./Add_BusinessNature";
import Add_BusinessCate from "./Add_BusinessCate";
import Add_EnteryStyle from "./Add_EnteryStyle";
import Add_PlaningStatus from "./Add_PlaningStatus";

const Advance_Details = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const [usersAdvance, setUsersAdvance] = useState([]);
    const { fetchAdvData, fetchAdvanceData, advanceModal, setAdvanceModal, businessList, showHsBusinessTypeList, businessNatureList,
        showHsBusinessNatureList, businesCateList, showBusinessCateList, businessEnterStyleList, showBusinessEnteryList, businessplaningList, showBusinessPlaningList } = props;
    const [isBusinessTypeOpen, setIsBusinessTypeOpen] = useState(false);
    const [isBusinessNatureOpen, setBusinessNatureOpen] = useState(false);
    const [isBusinessCateOpen, setIsBusinessCateOpen] = useState(false);
    const [isEnteryStyleOpen, setIsEnetryStyleOpen] = useState(false);
    const [isPlaningStatusOpen, setIsPlaningStatusOpen] = useState(false);
    const onHideHandler = () => {
        setAdvanceModal(false);

    };

    const onSubmit = () => {
        const newUser = {
            ...formValues,
        };
        setUsersAdvance([...usersAdvance, newUser]);
        http(
            {
                method: "POST",
                url: `corporate/advance/${id}/add`,
                body: {
                    businesstype_id: formValues.businesstype_id,
                    businessnature_id: formValues.businessnature_id,
                    businesscompany_id: "1",
                    businesscategory_id: formValues.businesscategory_id,
                    business_no: formValues.business_no,
                    authform_id_59: formValues.authform_id_59,
                    authform_id_1013: formValues.authform_id_1013,
                    incorporation_date: formValues.incorporation_date,
                    data_entry_style_id: formValues.data_entry_style_id,
                    business_planing_id: formValues.business_planing_id,
                    date_planning: formValues.date_planning,
                    year_ending: formValues.year_ending,
                    hstyear_ending: formValues.hstyear_ending,
                    business_notes: formValues.business_notes

                },
                isSecure: true,
            },
            (res) => {
                setAdvanceModal(false);
                fetchAdvData();


                // Optionally, you can perform additional actions after the profile is updated
            },
            (err) => {
                console.error("Error updating profile:", err);
            }
        );
    }
    return (
        <Modal
            show={advanceModal}
            onHide={onHideHandler}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal operating-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >


            <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add Advance Details</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>
            <div className="modal-body px-4 pt-0 pb-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="advance-form row">
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="businesstype_id" className="fs-18">Business Type</label>
                            <select
                                name="businesstype_id"
                                id="businesstype_id"
                                className="form-input mt-2"
                                {...register("businesstype_id", {
                                    required: "Business Type is required",
                                })}
                            >
                                <option value="" selected disabled>Select</option>
                                {businessList && businessList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.businesstype_id && <span className="error">{errors.businesstype_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsBusinessTypeOpen(true)}>+ Add More</p>
                        </div>

                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="businessnature_id" className="fs-18">Nature of Business</label>
                            <select
                                name="businessnature_id"
                                id="businessnature_id"
                                className="form-input mt-2"
                                {...register("businessnature_id", { required: "Nature of Business is required" })}
                            >
                                <option value="" disabled selected>Select</option>
                                {businessNatureList && businessNatureList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.businessnature_id && <span className="error">{errors.businessnature_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setBusinessNatureOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="businesscategory_id" className="fs-18">Category</label>
                            <select
                                name="businesscategory_id"
                                id="businesscategory_id"
                                className="form-input mt-2"
                                {...register("businesscategory_id", { required: "Category is required" })}
                            >
                                <option value="" disabled selected>Select</option>
                                {businesCateList && businesCateList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.businesscategory_id && <span className="error">{errors.businesscategory_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsBusinessCateOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Business No.</label>
                            <input
                                name="business_no"
                                id="business_no"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("business_no", {

                                })}

                            />
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Authorization Form</label>
                            <div className="position-relative select-text">
                                <p>RC 59</p>
                                <select
                                    name="authform_id_59"
                                    id="authform_id_59"
                                    className="form-input mt-2"
                                    {...register("authform_id_59", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {Authform_id && Authform_id.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Authorization Form</label>
                            <div className="position-relative select-text">
                                <p>T1013</p>
                                <select
                                    name="authform_id_1013"
                                    id="authform_id_1013"
                                    className="form-input mt-2"
                                    {...register("authform_id_1013", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>

                                    {Authform_id_1013 && Authform_id_1013.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Incorporation Date</label>
                            <input
                                className="form-input mt-2"
                                name="incorporation_date"
                                id="incorporation_date"
                                type="date"
                                {...register("incorporation_date", {


                                })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="data_entry_style_id" className="fs-18">Data Entry Style</label>
                            <select
                                name="data_entry_style_id"
                                id="data_entry_style_id"
                                className="form-input mt-2"
                                {...register("data_entry_style_id", { required: "Data Entry Style is required" })}
                            >
                                <option value="" disabled selected>Select</option>
                                {businessEnterStyleList && businessEnterStyleList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.data_entry_style_id && <span className="error">{errors.data_entry_style_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsEnetryStyleOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="business_planing_id" className="fs-18">Business Planning Status</label>
                            <select
                                name="business_planing_id"
                                id="business_planing_id"
                                className="form-input mt-2"
                                {...register("business_planing_id", { required: "Business Planning Status is required" })}
                            >
                                <option value="" disabled selected>Select</option>
                                {businessplaningList && businessplaningList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.business_planing_id && <span className="error">{errors.business_planing_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsPlaningStatusOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Date of Business Planning</label>
                            <input
                                name="date_planning"
                                id="date_planning"
                                className="form-input mt-2"
                                type="date"
                                {...register("date_planning", {


                                })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Corporation Year Ending</label>
                            <input
                                name="year_ending"
                                id="year_ending"
                                className="form-input mt-2"
                                type="date"
                                {...register("year_ending", {


                                })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">HST Year Ending</label>
                            <input
                                name="hstyear_ending"
                                id="hstyear_ending"
                                className="form-input mt-2"
                                type="date"
                                {...register("hstyear_ending", {


                                })}

                            />
                        </div>
                        <div className="col-md-12 mt-4">
                            <label className="fs-18">Business Planning Session Notes</label>
                            <textarea
                                className="mt-2 fs-18"
                                name="business_notes"
                                id="business_notes"
                                cols="20"
                                rows="10"
                                placeholder="Type here..."
                                {...register("business_notes", {


                                })}
                            >
                            </textarea>
                        </div>

                    </div>

                    <div className="modal-footer border-0 px-0 pb-4 mt-4">
                        <button type="submit" className="btn-blue">Add</button>
                    </div>
                </form>



            </div>
            <Add_BusinessType
                isBusinessTypeOpen={isBusinessTypeOpen}
                setIsBusinessTypeOpen={setIsBusinessTypeOpen}
                showHsBusinessTypeList={showHsBusinessTypeList}
            />
            <Add_BusinessNature
                isBusinessNatureOpen={isBusinessNatureOpen}
                setBusinessNatureOpen={setBusinessNatureOpen}
                showHsBusinessNatureList={showHsBusinessNatureList}
            />
            <Add_BusinessCate
                isBusinessCateOpen={isBusinessCateOpen}
                setIsBusinessCateOpen={setIsBusinessCateOpen}
                showBusinessCateList={showBusinessCateList}
            />
            <Add_EnteryStyle
                isEnteryStyleOpen={isEnteryStyleOpen}
                setIsEnetryStyleOpen={setIsEnetryStyleOpen}
                showBusinessEnteryList={showBusinessEnteryList}
            />
            <Add_PlaningStatus
                isPlaningStatusOpen={isPlaningStatusOpen}
                setIsPlaningStatusOpen={setIsPlaningStatusOpen}
                showBusinessPlaningList={showBusinessPlaningList}
            />
        </Modal>
    )
}
export default Advance_Details;