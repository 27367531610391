import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../App";
import { toast } from "react-toastify";
import Auth from "../../../auth/Auth";
import { http } from "../../http/http";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const SuperAdminLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { setShowLoader } = useContext(GlobalContext);
    const [users, setUsers] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    //   const data = JSON.parse(localStorage.getItem("__assist_erp_super_admin"));

    const onSubmit = () => {
        setShowLoader(true);
        const newUser = {
            ...formValues,
        };
        setUsers([...users, newUser]);
        http(
            {
                method: "POST",
                url: "superadmin/login",
                isSecure: true,
                body: {
                    email: formValues.email,
                    password: formValues.password,
                },
            },
            (res) => {
                if (res) {
                    Auth.login(res);
                    localStorage.setItem("ProfileData", JSON.stringify(res));
                    setShowLoader(false);
                    navigate("/superAdmin/dashboard");
                    toast.success("Login Successfully");
                }
            },
            (err) => {
                setShowLoader(false); // Hide loader on error
                toast.error("Incorrect email or password"); // Show error message
            }
        );
    };

    const isFormEmpty = () => {
        // Check if each field is empty
        return !formValues.email || !formValues.password;
    };

    return (

        <div className="superadmin-login position-relative">
            <div className="up-logo">
                <img
                    src={require("../../../assets/images/assist-logosa.png")}
                    alt="assist-logosa"
                />
            </div>
            <div className="row sa-row">
                <div className="col-lg-5 bg-white main-saform font-poppins p-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-head">
                            <h2>
                                Hello,{" "}
                                <span className="fw-bold">Welcome Back!</span>
                            </h2>
                            <h6 className="text-muted mt-2">
                                Login to your account below.
                            </h6>
                        </div>

                        <div className="form-area mt-5">
                            <div className="form-group input-group position-relative">
                                <label for="email" className="mb-1">
                                    Email address
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/email.png")}
                                        alt="emailIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type="email"
                                        className="form-control border-0 py-3 w-100 bg-red"
                                        placeholder="eg.@gmail.com"
                                        id="email"
                                        name="email"
                                        {...register("email", {
                                            required: true,
                                            pattern: /\S+@\S+\.\S+/,
                                        })}
                                    />
                                    {errors.email?.type === "required" && (
                                        <p
                                            role="alert"
                                            className="text-danger mb-0 ps-2 -fs-7 ms-1"
                                        >
                                            Required
                                        </p>
                                    )}
                                    {errors.email?.type === "pattern" && (
                                        <p
                                            role="alert"
                                            className="text-danger mb-0 ps-2 -fs-7 ms-1"
                                        >
                                            Incorrect email format
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className="form-group input-group position-relative mt-3">
                                <label for="password" className="mb-1">
                                    Password
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/lock.png")}
                                        alt="emailIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        name="password"
                                        id="password"
                                        className="form-control border-0 py-3 w-100 bg-red"
                                        placeholder="**********"
                                        {...register("password", {
                                            required: true,
                                        })}
                                    />
                                    {errors.password?.type === "required" && (
                                        <p
                                            role="alert"
                                            className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                        >
                                            Required
                                        </p>
                                    )}
                                    {/* EYES ICON */}
                                    <div className="eye-icons position-absolute">
                                        <>
                                            {showPassword ? (
                                                <img
                                                    src={require("../../../assets/images/eye.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/eye-hide.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                />
                                            )}
                                        </>
                                    </div>
                                    {/* EYES ICON */}
                                </div>
                            </div>

                            <div className="form-group mt-3 text-end forgot-text">
                                <a
                                    href="#"
                                    class="text-black text-decoration-none"
                                >
                                    Forgot Password?
                                </a>
                            </div>

                            <div className="form-group mt-4 text-center">
                                <input
                                    type="submit"
                                    value="Login"
                                    class="w-100 sub-button rounded-4 border-none border py-3 text-white"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SuperAdminLogin;
