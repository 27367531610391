import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import tinycolor from 'tinycolor2';

const Add_Priority = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showPriority, setShowPriority, handlePriorityData } = props;
    const [color, setColor] = useState('#9a9e9e');
    const [rgbaColor, setRgbaColor] = useState('rgba(237, 238, 238, 0.4)');

    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setColor(newColor);
        const rgba = tinycolor(newColor).setAlpha(0.4).toRgbString();
        setRgbaColor(rgba);
    };

    const addPriority = () => {
        const selectedColor = color ? color : '#edeeee';
        http(
            {
                method: "POST",
                url: `priority/add`,
                isSecure: true,
                body: {
                    name: formValues.name,
                    color_code: selectedColor
                },
            },
            (res) => {
                toast.success("Add Priority Successfully")
                setShowPriority(false);
                handlePriorityData();
                reset();
            },
            (err) => {

            }
        );
    }
    return (
        <Modal
            show={showPriority}
            onHide={setShowPriority}
            centered

            contentClassName="mx-auto"
            className="modal pin-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header px-4 pt-4 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add Priority</h4>
                <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
            </div>

            <div className="services-form row px-2 mt-2">
                <div className="col-lg-12 mt-1">
                    <form onSubmit={handleSubmit(addPriority)}>

                        <div className="modal-footer border-0 justify-content-center p-0">
                            <input name="name" id="name" className="form-input mt-2 w-100" type="text" {...register("name", {})} />
                        </div>
                        <div className="mt-3">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <label>Select a Color:</label>
                                <input type='color' value={color} onChange={handleColorChange} />
                            </div>

                        </div>

                        <button type="submit" className="btn-blue mt-3 block ms-auto">Add</button>
                    </form>
                </div>
            </div>
            {/* <SketchPicker /> */}
        </Modal>
    )
}
export default Add_Priority;