const Footer = () => {
    return (
        <div className="footer position-absolute bottom-0 end-0 bg-white py-4 px-3">
            <div className="container-fluid">
                <div className="row justify-content-between">

                    <div className="col">
                        <div className="d-flex align-items-center justify-content-end">
                            {/* <p className="mb-0">Copyright © 2018 Concept. All rights reserved.
                        </p> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Footer;