// import React, { useEffect, useRef } from 'react';
// import { SurveyCreator } from 'survey-creator-react';
// import 'survey-creator-core/survey-creator-core.min.css';
// import { setLicenseKey } from 'survey-core';

// setLicenseKey('MTU5OGQ3ZTctM2VmMy00NjdiLTllMjctMWQ3Yzk1NGJkMzJiOzE9MjAyNS0wNy0xOA==');

// const CreatorComponent = ({ onSave }) => {
//     const creatorRef = useRef(null);

//     useEffect(() => {
//         const creatorOptions = {
//             showLogicTab: true,
//             // showTranslationTab: true,
//             isAutoSave: false,
//             showThemeTab: true
//         };

//         const surveyCreator = new SurveyCreator(creatorOptions);
//         surveyCreator.haveCommercialLicense = true;
//         creatorRef.current = surveyCreator;

//         surveyCreator.render('surveyCreatorContainer');

//         return () => {
//             surveyCreator.dispose();
//         };
//     }, []);

//     const handleSave = () => {
//         const surveyJson = creatorRef.current.JSON;
//         onSave(surveyJson);
//     };

import React, { useEffect, useRef } from 'react';
import { SurveyCreator } from 'survey-creator-react';
import 'survey-creator-core/survey-creator-core.min.css';
import { setLicenseKey } from 'survey-core';

setLicenseKey('MTU5OGQ3ZTctM2VmMy00NjdiLTllMjctMWQ3Yzk1NGJkMzJiOzE9MjAyNS0wNy0xOA==');

const CreatorComponent = ({ onSave, initialForm }) => {
    const creatorRef = useRef(null);
    const creator = new SurveyCreator();

    useEffect(() => {
        const creatorOptions = {
            showLogicTab: true,
            showTranslationTab: true,
            isAutoSave: false,
            showThemeTab: true
        };

        const surveyCreator = new SurveyCreator(creatorOptions);
        surveyCreator.haveCommercialLicense = true;

        // Load initial form JSON if provided
        // if (initialForm) {
        //     surveyCreator.text = JSON.stringify(initialForm);
        // }



        creatorRef.current = surveyCreator;
        surveyCreator.render('surveyCreatorContainer');

        return () => {
            surveyCreator.dispose();
        };
    }, [initialForm]); // Re-run effect if initialForm changes

    useEffect(() => {
        if (initialForm) {
            creator.JSON = initialForm;  // Load the initial form data into the creator
        }
    }, [initialForm]);

    const handleSave = () => {
        const surveyJson = creatorRef.current.JSON;
        onSave(surveyJson);
    };


    return (
        <div>
            <div id="surveyCreatorContainer" style={{ height: '600px' }} />
            <div className="w-100 d-flex justify-content-end">
                <button className='add-new mt-4' onClick={handleSave}>Save Form</button>
            </div>
        </div>
    );
};

export default CreatorComponent;
