import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Add_NewHst from "./Add_NewHst";
import Add_NewHstStyle from "./Add_NewHstStyle";
import Add_HstOpen from "./Add_HstOpen";
import Add_ArcSetup from "./Add_ArcSteup";
import Add_Payroll from "./Add_Payroll";
import Add_ArcAccess from "./Add_ArcAccess";
import Add_AdvanceFees from "./Add_AdvanceFees";
import Add_Agreement from "./Add_Agreement";
import Add_AgreementReson from "./Add_AgreementReson";
import Add_ArcGrid from "./Add_ArcGrid";

const Services = (props) => {
    const { id } = useParams();


    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { fetchSerData, servicesModal, setServicesModal, hstList, showHstList, hstStyleList,
        showHstStyle, showArsetupList, arcSetupList, showHstOpenList, hstOpenList, showPayRollList,
        payRollList, showArcAccessList, ArcAccessList, showAdvanceList, AdvanceFeeList, showAgreementList,
        AgreemetList, showArcGridList, arcGridList, showAgreementReasonList, AgreementReasonList } = props;
    const [serviceData, setServiceData] = useState([]);
    const [isHSTOpen, setIsHSTOpen] = useState(false);
    const [isHSTStyleOpen, setIsHSTStyleOpen] = useState(false);
    const [isHstOpenFirst, setIsHstOpenFirst] = useState(false);
    const [isArcOpen, setIsArcOpen] = useState(false);
    const [isPayRoolOpen, setIsPayRollOpen] = useState(false);
    const [isArcAccessOpen, setIsArcAccessOpen] = useState(false);
    const [isAdvanceFeeOpen, setIsAdvanceFeeOpen] = useState(false);
    const [isAgreementOpen, setIsAgreementOpen] = useState(false);
    const [isAgreementReasonOpen, setIsAgreementReasonOpen] = useState(false);
    const [isArcGridOpen, setIsArcGridOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customFormList, setCustomFormList] = useState([]);


    const handleCheckboxCustomForm = (event) => {
        const value = parseInt(event.target.value, 10); // Parse the value as an integer

        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const handleService = () => {
        console.log('Selected HST Open ID:', formValues.hst_open_id);
        // Create an array to hold the selected form IDs
        const formIds = [];

        // Populate formIds with the selected options
        selectedOptions.forEach(id => {
            formIds.push(id);
        });
        const newUser = {
            ...formValues,
            form_id: formIds, // Append formIds to the newUser object
        };
        // Add the new user to the existing service data array
        const updatedServiceData = [...serviceData, newUser];

        http(
            {
                method: "POST",
                url: `corporate/service/${id}/add`,
                isSecure: true,
                body: updatedServiceData, // Send the updated service data array
            },
            (res) => {
                setServiceData(res?.data?.data, "ServiceData");
                toast.success("Add Service Successfully");
                reset();
                fetchSerData();
            },
            (err) => {
                // Handle error
            }
        );
    };


    const handleCustomFormList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFormList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleCustomFormList();
    }, []);



    const onHideHandler = () => {
        setServicesModal(false);

    };
    return (
        <Modal
            show={servicesModal}
            onHide={onHideHandler}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal operating-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >


            <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add Service Details</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>
            <div className="modal-body px-4 pt-0 pb-0">
                <form
                    onSubmit={handleSubmit(handleService)}
                >
                    <div className="services-form row">
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">HST Open</label>
                            <select
                                name="hst_open_id"
                                className="form-input mt-2"
                                id="hst_open_id"
                                {...register("hst_open_id", {
                                    required: "Required"
                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {hstOpenList && hstOpenList.map((data) => (
                                    <option key={data.key} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.hst_open_id && <span className="error">{errors.hst_open_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsHstOpenFirst(true)}>+ Add More</p>

                        </div>
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">HST Return</label>
                            <select
                                name="hst_return_id"
                                className="form-input mt-2"
                                id="hst_return_id" {...register("hst_return_id", {
                                    required: "Required"
                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {hstList && hstList.map((data) => (
                                    <option key={data.key} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.hst_return_id && <span className="error">{errors.hst_return_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsHSTOpen(true)}>+ Add More</p>

                        </div>
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">HST Style</label>
                            <select
                                name="hst_style_id"
                                className="form-input mt-2"
                                id="hst_style_id"
                                {...register("hst_style_id", {
                                    required: "Required"
                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {hstStyleList && hstStyleList.map((data) => (
                                    <option key={data.key} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.hst_style_id && <span className="error">{errors.hst_style_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsHSTStyleOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">Payroll Account</label>
                            <select
                                name="payroll_account_id"
                                className="form-input mt-2"
                                id="payroll_account_id"
                                {...register("payroll_account_id", {

                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {payRollList && payRollList.map((data) => (
                                    <option key={data.key} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsPayRollOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">ARC Setup</label>
                            <select
                                name="arc_setup_id"
                                className="form-input mt-2"
                                id="arc_setup_id"
                                {...register("arc_setup_id", {

                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {arcSetupList && arcSetupList.map((data) => (
                                    <option key={data.key} value={data.id}>
                                        {data?.name}
                                    </option>
                                ))}
                            </select>
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsArcOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">ARC Access</label>
                            <select
                                name="arc_accesses_id"
                                className="form-input mt-2"
                                id="arc_accesses_id"
                                {...register("arc_accesses_id", {

                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {ArcAccessList && ArcAccessList.map((data) => (
                                    <option key={data.key} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsArcAccessOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">ARC Grid Saved</label>
                            <select
                                name="arc_grid_id"
                                className="form-input mt-2"
                                id="arc_grid_id"
                                {...register("arc_grid_id", {

                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {arcGridList && arcGridList.map((data) => (
                                    <option key={data.key} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsArcGridOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-12 col-md-12 mt-4">
                            <label className="fs-18">ARC Details</label>
                            <textarea
                                name="arc_detail"
                                id="arc_detail"
                                className="mt-2"
                                cols="20"
                                rows="10"

                                {...register("arc_detail", {

                                })}
                            ></textarea>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">Agreement Signed</label>
                            <select
                                name="agreement_sign_id"
                                className="form-input mt-2"
                                id="agreement_sign_id"
                                {...register("agreement_sign_id", {

                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {AgreemetList && AgreemetList.map((data) => (
                                    <option key={data.key} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsAgreementOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">Agreement Reason</label>
                            <select
                                name="agreement_reason"
                                className="form-input mt-2"
                                id="agreement_reason"
                                {...register("agreement_reason", {

                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {AgreementReasonList && AgreementReasonList.map((data) => (
                                    <option key={data.key} value={data.key}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsAgreementReasonOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">Advance Fee</label>
                            <select
                                name="advance_fee"
                                className="form-input mt-2"
                                id="advance_fee"
                                {...register("advance_fee", {

                                })}
                            >
                                <option value="" disabled="disabled" selected>Select</option>
                                {AdvanceFeeList && AdvanceFeeList.map((data) => (
                                    <option key={data.key} value={data.key}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            <p className="text-blue fw-bold mt-1 text-end" onClick={() => setIsAdvanceFeeOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4">
                            <label className="fs-18">Agreement Signing Amount</label>
                            <input
                                className="form-input mt-2"
                                type="text"
                                name="advance_amount"
                                id="advance_amount"
                                placeholder="Type here..."
                                {...register("advance_amount", {

                                })}
                            />
                        </div>
                        <div className="col-md-12 mt-4">
                            <label className="fs-18">Reason If Not Signed</label>
                            <textarea
                                name="advance_reason"
                                id="advance_reason"
                                className="mt-2"
                                cols="20"
                                rows="10"
                                {...register("advance_reason", {

                                })}
                            ></textarea>
                        </div>
                        <div className=" mt-3">
                            <label htmlFor="Required Forms" className="inv-title text-black mb-2">Required Forms</label>
                            {customFormList && customFormList.map((option) => (
                                <div key={option.id} className="d-flex align-items-center gap-2 mt-2">
                                    <input
                                        type="checkbox"
                                        value={option.id}
                                        checked={selectedOptions.includes(option.id)}
                                        onChange={handleCheckboxCustomForm}
                                    />
                                    <label className="text-black">{option.data[0]?.title}</label>
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer border-0 px-0 pb-4 mt-4">
                            <button className="btn-blue">Add</button>
                        </div>

                    </div>
                </form>
            </div>


            <Add_NewHst
                setIsHSTOpen={setIsHSTOpen}
                isHSTOpen={isHSTOpen}
                showHstList={showHstList}
            />
            <Add_NewHstStyle
                setIsHSTStyleOpen={setIsHSTStyleOpen}
                isHSTStyleOpen={isHSTStyleOpen}
                showHstStyle={showHstStyle}
            />
            <Add_HstOpen
                isHstOpenFirst={isHstOpenFirst}
                setIsHstOpenFirst={setIsHstOpenFirst}
                showHstOpenList={showHstOpenList}
            />
            <Add_ArcSetup
                isArcOpen={isArcOpen}
                setIsArcOpen={setIsArcOpen}
                showArsetupList={showArsetupList}
            />
            <Add_Payroll
                isPayRoolOpen={isPayRoolOpen}
                setIsPayRollOpen={setIsPayRollOpen}
                showPayRollList={showPayRollList}
            />
            <Add_ArcAccess
                isArcAccessOpen={isArcAccessOpen}
                setIsArcAccessOpen={setIsArcAccessOpen}
                showArcAccessList={showArcAccessList}
            />
            <Add_AdvanceFees
                isAdvanceFeeOpen={isAdvanceFeeOpen}
                setIsAdvanceFeeOpen={setIsAdvanceFeeOpen}
                showAdvanceList={showAdvanceList}
            />
            <Add_Agreement
                isAgreementOpen={isAgreementOpen}
                setIsAgreementOpen={setIsAgreementOpen}
                showAgreementList={showAgreementList}
            />
            <Add_AgreementReson
                isAgreementReasonOpen={isAgreementReasonOpen}
                setIsAgreementReasonOpen={setIsAgreementReasonOpen}
                showAgreementReasonList={showAgreementReasonList}
            />
            <Add_ArcGrid
                isArcGridOpen={isArcGridOpen}
                setIsArcGridOpen={setIsArcGridOpen}
                showArcGridList={showArcGridList}
            />

        </Modal>

    )
}
export default Services;