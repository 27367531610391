import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../http/http";
import { toast } from "react-toastify";

const Upload_Files = (props) => {
    const { uploadModal, setUploadModal } = props;
    const onHideHandler = () => {
        setUploadModal(false);

    };

    return (
        <Modal
            show={uploadModal}
            onHide={onHideHandler}
            centered
            className="modal operating-modal"
            backdrop="static"
            keyboard={false}
        >

            <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add Asssigend Member</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>
            <div className="modal-dialog modal-dialog-centered m-0">
                <div className="assigned-form row mt-1">
                    <div className="col-md-12 mt-1">
                        <div className="row">
                            <div className="col-lg-12 mt-1">
                                <div className="upload-input position-relative mt-2 text-center ">
                                    <div className="main-wrapper">
                                        <div className="upload-main-wrapper">
                                            <div className="upload-wrapper">
                                                <input type="file" id="upload-file" />
                                                <i className="fa fa-upload me-2"></i>
                                                <span className="file-upload-text">Browse Files</span>
                                            </div>
                                            <h4 className="fs-14 mt-2">Drag & drop here</h4>
                                            <p className="fs-14 text-muted">Supports: JPEG, JPG, PDF, XLS, DOC Max Size: 10 Mb</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-3">
                                <p className="text-muted">2 Files Uploading...</p>
                            </div>
                            <div className="col-md-12">
                                <div className="uploading mt-2 position-relative">
                                    <div className="l_upload d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                            <g id="Group_715" data-name="Group 715" transform="translate(-1374 -1270)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="20" cy="20" r="20" transform="translate(1374 1270)" fill="#bad5f3" />
                                                <g id="doc" transform="translate(1386.315 1279.725)">
                                                    <g id="Vrstva_x0020_1_4_" transform="translate(0 0)">
                                                        <path id="Path_3532" data-name="Path 3532" d="M102.432,0h6.876l5.821,6.073v11.8a2.673,2.673,0,0,1-2.673,2.673H102.432a2.673,2.673,0,0,1-2.673-2.673V2.673A2.673,2.673,0,0,1,102.432,0Z" transform="translate(-99.759 0)" fill="#0263d1" />
                                                        <g id="Group_705" data-name="Group 705" transform="translate(2.482 0)">
                                                            <path id="Path_3533" data-name="Path 3533" d="M467.212,0V6.023h5.829Z" transform="translate(-460.153)" fill="#fff" fillRule="evenodd" opacity="0.302" />
                                                            <path id="Path_3534" data-name="Path 3534" d="M195.356,435.657v-3.4h1.205a2.2,2.2,0,0,1,.673.1,1.852,1.852,0,0,1,.565.3,1.349,1.349,0,0,1,.4.532,2.064,2.064,0,0,1,0,1.529,1.349,1.349,0,0,1-.4.532,1.852,1.852,0,0,1-.565.3,2.2,2.2,0,0,1-.673.1Zm.851-.74h.252a1.462,1.462,0,0,0,.377-.047,1.078,1.078,0,0,0,.321-.155.674.674,0,0,0,.233-.3,1.108,1.108,0,0,0,.086-.46,1.131,1.131,0,0,0-.086-.463.681.681,0,0,0-.233-.3,1.015,1.015,0,0,0-.321-.155,1.462,1.462,0,0,0-.377-.047h-.252Zm4.2.779a1.74,1.74,0,0,1-1.272-.5,1.793,1.793,0,0,1,0-2.488,1.74,1.74,0,0,1,1.272-.5,1.721,1.721,0,0,1,1.258.5,1.8,1.8,0,0,1,0,2.488A1.721,1.721,0,0,1,200.409,435.7Zm-.654-1.022a.882.882,0,0,0,1.294,0,1.147,1.147,0,0,0,0-1.435.882.882,0,0,0-1.294,0,1.137,1.137,0,0,0,0,1.435Zm4.433,1.022a1.745,1.745,0,0,1-1.236-.46,1.9,1.9,0,0,1,.006-2.557,1.749,1.749,0,0,1,1.23-.463,1.729,1.729,0,0,1,1.086.327,1.222,1.222,0,0,1,.488.864l-.859.175a.769.769,0,0,0-.255-.454.7.7,0,0,0-.465-.172.8.8,0,0,0-.609.26,1.234,1.234,0,0,0,0,1.477.793.793,0,0,0,.612.263.706.706,0,0,0,.454-.15.657.657,0,0,0,.235-.4l.878.2a1.291,1.291,0,0,1-.535.8A1.757,1.757,0,0,1,204.188,435.7Z" transform="translate(-195.356 -420.993)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <div className="doc-name w-100 ms-2">
                                            <h5 className="fs-14 text-black">Uploading</h5>
                                            <div className="doc-info d-flex align-items-center justify-content-between">
                                                <span className="d-flex align-items-center">
                                                    <p className="fs-12">File Name.doc</p>
                                                    <hr />
                                                    <p className="fs-12">Size:200kb</p>
                                                </span>
                                                <p className="fs-12 text-black">36%</p>

                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <i className="fa fa-times"></i>
                                </div>
                                <div className="uploading mt-2 position-relative">
                                    <div className="l_upload d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                            <g id="Group_713" data-name="Group 713" transform="translate(-1374 -1380)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="20" cy="20" r="20" transform="translate(1374 1380)" fill="#f9c3c5" />
                                                <g id="pdf" transform="translate(1386.315 1390.007)">
                                                    <g id="Vrstva_x0020_1_15_" transform="translate(0 0)">
                                                        <path id="Path_3537" data-name="Path 3537" d="M102.448,0h6.871l5.823,6.07V17.875a2.673,2.673,0,0,1-2.67,2.675H102.448a2.674,2.674,0,0,1-2.675-2.675V2.675A2.674,2.674,0,0,1,102.448,0Z" transform="translate(-99.773 0)" fill="#e5252a" fillRule="evenodd" />
                                                        <g id="Group_706" data-name="Group 706" transform="translate(2.973 0)">
                                                            <path id="Path_3538" data-name="Path 3538" d="M467.219,0V6.024h5.828Z" transform="translate(-460.651)" fill="#fff" fillRule="evenodd" opacity="0.302" />
                                                            <path id="Path_3539" data-name="Path 3539" d="M214.278,449.713v-3.754h1.6a1.324,1.324,0,0,1,.945.329,1.253,1.253,0,0,1,0,1.746,1.324,1.324,0,0,1-.945.329h-.637v1.351Zm.96-2.167h.529a.473.473,0,0,0,.334-.1.4.4,0,0,0,0-.565.473.473,0,0,0-.334-.1h-.529Zm2.331,2.167v-3.754h1.33a2.322,2.322,0,0,1,.739.113,1.987,1.987,0,0,1,.626.334,1.474,1.474,0,0,1,.442.585,2.321,2.321,0,0,1,0,1.689,1.474,1.474,0,0,1-.442.585,1.988,1.988,0,0,1-.626.334,2.323,2.323,0,0,1-.739.113Zm.94-.816h.277a1.537,1.537,0,0,0,.416-.051,1.248,1.248,0,0,0,.354-.169.761.761,0,0,0,.257-.329,1.458,1.458,0,0,0,0-1.022.761.761,0,0,0-.257-.329,1.246,1.246,0,0,0-.354-.169,1.539,1.539,0,0,0-.416-.051h-.277Zm2.84.816v-3.754h2.67v.816h-1.71v.6h1.366v.811h-1.366v1.525Z" transform="translate(-214.278 -434.379)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <div className="doc-name w-100 ms-2">
                                            <h5 className="fs-14 text-black">Upload Completed</h5>
                                            <div className="doc-info d-flex align-items-center justify-content-between">
                                                <span className="d-flex align-items-center">
                                                    <p className="fs-12">File Name.pdf</p>
                                                    <hr />
                                                    <p className="fs-12">Size:1mb</p>
                                                </span>
                                                <p className="fs-12 text-black">100%</p>

                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <i className="fa fa-times"></i>
                                </div>
                                <div className="uploading mt-2 position-relative">
                                    <div className="l_upload d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                            <g id="Group_1038" data-name="Group 1038" transform="translate(-1374 -1490)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="20" cy="20" r="20" transform="translate(1374 1490)" fill="#bad9c9" />
                                                <g id="xls" transform="translate(1388.776 1502.891)">
                                                    <g id="Vrstva_x0020_1_30_" transform="translate(0 0)">
                                                        <path id="Path_3540" data-name="Path 3540" d="M101.741,0h5l4.233,4.418V13a1.944,1.944,0,0,1-1.942,1.947h-7.293A1.947,1.947,0,0,1,99.8,13V1.942A1.946,1.946,0,0,1,101.741,0Z" transform="translate(-99.799 0)" fill="#00733b" fillRule="evenodd" />
                                                        <g id="Group_707" data-name="Group 707" transform="translate(2.198 0)">
                                                            <path id="Path_3541" data-name="Path 3541" d="M467.218,0V4.38h4.238Z" transform="translate(-462.478)" fill="#fff" fillRule="evenodd" opacity="0.302" />
                                                            <path id="Path_3542" data-name="Path 3542" d="M218.655,433.712h-.725l-.5-.835-.5.835h-.731l.862-1.451L216.31,431h.731l.393.655.387-.655h.731l-.753,1.265Zm.256,0V431h.693v2.122h1.178v.589h-1.871Zm3.016.033a1.108,1.108,0,0,1-.72-.256.847.847,0,0,1-.333-.616l.595-.175a.538.538,0,0,0,.164.333.481.481,0,0,0,.327.131.433.433,0,0,0,.245-.065.21.21,0,0,0,.093-.175.2.2,0,0,0-.076-.153.694.694,0,0,0-.191-.1,2.521,2.521,0,0,0-.262-.076,1.827,1.827,0,0,1-.289-.1,1.017,1.017,0,0,1-.262-.147.568.568,0,0,1-.191-.24.791.791,0,0,1-.076-.355.673.673,0,0,1,.284-.556,1.062,1.062,0,0,1,.687-.224,1.275,1.275,0,0,1,.7.191.833.833,0,0,1,.371.507l-.622.262a.543.543,0,0,0-.158-.278.435.435,0,0,0-.289-.1.388.388,0,0,0-.207.055.16.16,0,0,0-.071.142.167.167,0,0,0,.1.142.911.911,0,0,0,.251.076,2.142,2.142,0,0,1,.322.082,1.986,1.986,0,0,1,.327.147.653.653,0,0,1,.245.267.882.882,0,0,1,.1.442.74.74,0,0,1-.295.611A1.219,1.219,0,0,1,221.928,433.745Z" transform="translate(-216.206 -422.83)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                        <div className="doc-name w-100 ms-2">

                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="doc-info">
                                                    <h5 className="fs-14 text-black">File Name.xls</h5>
                                                    <span className="d-flex align-items-center">
                                                        <p className="fs-12">File Name.doc</p>
                                                        <hr />
                                                        <p className="fs-12">Size:1mb</p>
                                                    </span>
                                                </div>
                                                <div className="delete-progress">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                        <g id="Group_1055" data-name="Group 1055" transform="translate(16079 13588)">
                                                            <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(-16079 -13588)" fill="#fad3d4" />
                                                            <path id="Path_3653" data-name="Path 3653" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-16469.299 -14914.999)" fill="#e5252a" fillRule="evenodd" />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer border-0 px-0 pb-4 mt-4">
                                <button className="btn-blue">Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Modal >
    )
}
export default Upload_Files;