import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { userProfileData, selectUser } from "../../../reduxTool/features/userSlice";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import SuperAdminHeader from "../header/SuperAdminHeader";
import SuperAdminTenant from "../dashboard/SuperAdminTenant";
import SuperAdminSideBar from "../sidebar/SuperAdminSideBar";
import Footer from "../../common/Footer";

const Edit_Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [picture, setPicture] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    password: "",
    password_confirmation: ""
  });
  const data = JSON.parse(localStorage.getItem("__assist_erp"));
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("ProfileData")) || null
  );
  const [formValues, setFormValues] = useState({
    first_name: userData?.user?.first_name || "",
    last_name: userData?.user?.last_name || "",
    name: userData?.user?.name || "",
    email: userData?.user?.email || "",
    image: userData?.user?.image || "",
    password: userData.user?.password || "",
    password_confirmation: userData.user?.password_confirmation || ""
  });

  useEffect(() => {
    localStorage.setItem(
      "ProfileData",
      JSON.stringify({ user: formValues })
    );
  }, [formValues]);

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("ProfileData"));
    if (storedUserData) {
      setUserData(storedUserData);
      setFormValues({
        first_name: storedUserData.user?.first_name || "",
        last_name: storedUserData.user?.last_name || "",
        name: storedUserData.user?.name || "",
        email: storedUserData.user?.email || "",
        image: storedUserData.user?.image || "",
        password: storedUserData.user?.password || "",
        password_confirmation: storedUserData.user?.password_confirmation || ""
      });
    }
  }, []);

  useEffect(() => {
    const storedImageUrl = localStorage.getItem("ProfileImageData");
    if (storedImageUrl) {
      setPicture({ url: storedImageUrl, file: null });
    }
  }, []);

  const onChangePicture = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPicture({
        url: imageUrl,
        file: file,
      });

      setFormValues({
        ...formValues,
        image: imageUrl,
      });

      localStorage.setItem("ProfileImageData", imageUrl);
    } else {
      const storedImageUrl = localStorage.getItem("ProfileImageData");
      if (storedImageUrl) {
        setPicture({ url: storedImageUrl, file: null });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    // Trigger validation on keyup event
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    if (value.trim() === "") {
      setErrorMessage({
        ...errorMessage,
        [fieldName]: "This field is required.",
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [fieldName]: "",
      });
    }
  };

  const validateForm = () => {
    let isValid = true;

    Object.values(errorMessage).forEach((error) => {
      if (error.trim() !== "") {
        isValid = false;
      }
    });

    return isValid;
  };

  const userUpdateProfile = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    if (picture.file) {
      formData.append("image", picture.file);
    }
    formData.append("first_name", formValues.first_name);
    formData.append("email", formValues.email);
    formData.append("last_name", formValues.last_name);
    formData.append("name", formValues.name);
    formData.append("password", formValues.password);
    formData.append(
      "password_confirmation",
      formValues.password_confirmation
    );

    const authToken = data?.access_token;

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/superadmin/profile-update`,
        formData,
        {
          headers: {
            ...headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status == 200) {
        dispatch(userProfileData(response.data));
        toast.success(response.data.message);
        navigate("/superAdmin/edit_profile");
      }
    } catch (error) {
      console.error(error);
    }

    // Call fetchData after saving changes
    // fetchData();
  };

  // fetchData function moved outside of useEffect
  useEffect(() => {
    const fetchData = async () => {
      const authToken = data?.access_token;

      if (!authToken) {
        console.error("No auth token available.");
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/superadmin/profile/1`,
          { headers }
        );
        dispatch(userProfileData(response.data));
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData(); // Call the function here
  }, [userProfileData]);

  return (
    <div className="maincontent">
      <SuperAdminHeader />
      <div id="content" className="content position-relative">
        <SuperAdminSideBar />
        <div id="maincontent" className="container-fluid maincontent p-2 p-sm-3">
          <div className="container-fluid maincontent p-2 p-sm-3 bg-white rounded-1 ">
            <div className="row h-100">
              <div className="col-12 h-100">
                <div className="heading d-sm-flex align-items-center justify-content-sm-between w-100 pt-3">
                  <div>
                    <h5 className="fw-semibold mb-3 pt-2">Profile Setting</h5>
                  </div>
                </div>

                <div className="p-2 p-sm-3 border rounded-1 overflow-auto d-flex align-items-center justify-content-center cal-90vh">
                  <form className="w-100" onSubmit={userUpdateProfile}>
                    <div className="row c-table w-100 mb-0">
                      <div className="col-12 col-lg-6 col-xl-5 col-xxl-4 offset-xxl-2 offset-xl-1">
                        <div className="custom-grid">
                          <div className="mb-3 grid_column d-block gap-3 align-items-center justify-content-center">
                            <label className="form-label mb-2 mb-sm-0 text-end text-nowrap -fs-7 ps-2">
                              First Name
                            </label>
                            <input
                              onChange={handleChange}
                              className="form-control rounded-pill -h-50"
                              type="text"
                              name="first_name"
                              value={formValues.first_name}
                              placeholder="First Name"
                            />
                            {errorMessage.first_name && (
                              <p className="text-danger">
                                {errorMessage.first_name}
                              </p>
                            )}
                          </div>
                          <div className="mb-3 grid_column d-block gap-3 align-items-center justify-content-center">
                            <label className="form-label mb-2 mb-sm-0 text-end text-nowrap -fs-7 ps-2">
                              Last Name
                            </label>
                            <input
                              onChange={handleChange}
                              className="form-control rounded-pill -h-50"
                              type="text"
                              name="last_name"
                              value={formValues.last_name}
                              placeholder="Last Name"
                            />
                            {errorMessage.last_name && (
                              <p className="text-danger">
                                {errorMessage.last_name}
                              </p>
                            )}
                          </div>
                          <div className="mb-3 grid_column d-block gap-3 align-items-center justify-content-center">
                            <label className="form-label mb-2 mb-sm-0 text-end text-nowrap -fs-7 ps-2">
                              Name
                            </label>
                            <input
                              onChange={handleChange}
                              className="form-control rounded-pill -h-50"
                              type="text"
                              name="name"
                              value={formValues.name}
                              placeholder="Name"
                            />
                            {errorMessage.name && (
                              <p className="text-danger">{errorMessage.name}</p>
                            )}
                          </div>
                          <div className="mb-3 grid_column d-block gap-3 align-items-center justify-content-center">
                            <label className="form-label mb-2 mb-sm-0 text-end text-nowrap -fs-7 ps-2">
                              Email
                            </label>
                            <input
                              onChange={handleChange}
                              className="form-control rounded-pill -h-50"
                              type="email"
                              name="email"
                              value={formValues.email}
                              placeholder="Email"
                            />
                            {errorMessage.email && (
                              <p className="text-danger">
                                {errorMessage.email}
                              </p>
                            )}
                          </div>
                          <div className="mb-3 grid_column d-block gap-3 align-items-center justify-content-center">
                            <label className="form-label mb-2 mb-sm-0 text-end text-nowrap -fs-7 ps-2">
                              Password
                            </label>
                            <div className="position-relative">
                              <input
                                onChange={handleChange}
                                className="form-control rounded-pill -h-50"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={formValues.password}
                                placeholder="Password"
                              />
                              {errorMessage.password && (
                                <p className="text-danger">
                                  {errorMessage.password}
                                </p>
                              )}
                              <div className="position-absolute top-50 end-0 translate-middle pe-1">
                                <>
                                  {showPassword ? (
                                    <i
                                      className="fas fa-eye-slash"
                                      onClick={() => setShowPassword(!showPassword)}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fas fa-eye"
                                      onClick={() => setShowPassword(!showPassword)}
                                    ></i>
                                  )}
                                </>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3 grid_column d-block gap-3 align-items-center justify-content-center">
                            <label className="form-label mb-2 mb-sm-0 text-end text-nowrap -fs-7 ps-2">
                              Confirm Password
                            </label>
                            <div className="position-relative">
                              <input
                                onChange={handleChange}
                                className="form-control rounded-pill -h-50"
                                type={showConfirmPassword ? "text" : "password"}
                                name="password_confirmation"
                                value={formValues.password_confirmation}
                                placeholder="Password Confirmation"
                              />
                              {errorMessage.password_confirmation && (
                                <p className="text-danger">
                                  {errorMessage.password_confirmation}
                                </p>
                              )}
                              <div className="position-absolute top-50 end-0 translate-middle pe-1">
                                <>
                                  {showConfirmPassword ? (
                                    <i
                                      className="fas fa-eye-slash"
                                      onClick={() =>
                                        setShowConfirmPassword(!showConfirmPassword)
                                      }
                                    ></i>
                                  ) : (
                                    <i
                                      className="fas fa-eye"
                                      onClick={() =>
                                        setShowConfirmPassword(!showConfirmPassword)
                                      }
                                    ></i>
                                  )}
                                </>
                              </div>
                            </div>
                          </div>

                          <div className="d-block gap-3 align-items-center justify-content-center w-100">
                            <button className="btn btn-primary py-2 w-100 -h-50 rounded-pill mt-2">
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 col-xl-5 col-xxl-4 text-center">
                        <div className="position-relative text-center w-100">
                          <img
                            src={require("../../../assets/images/profile-bg.png")}
                            width="290px"
                            className="mx-auto"
                          />
                          <div className="position-absolute top-50 start-50 translate-middle profile-dp overflow-hidden justify-content-center align-items-center d-flex">
                            {picture.url ? (
                              <img
                                width="100%"
                                src={picture.url}
                                className="bg-white"
                              />
                            ) : (
                              <img
                                src={require("../../../assets/images/beautiful-women.webp")}
                                alt=""
                                width="100%"
                                className="bg-white"
                              />
                            )}
                          </div>
                        </div>
                        <div className="text-center shape-x position-relative">
                          <h5 className="fw-semibold">
                            {userData?.user?.first_name} {userData?.user?.last_name}
                          </h5>
                        </div>
                        <div className="text-center">
                          <p className="-fs-8 -light-gray mb-3">
                            {userData?.user?.designation}
                          </p>
                        </div>
                        <div className="position-relative text-decoration-none d-flex align-items-center justify-content-center gap-2">
                          <input
                            type="file"
                            name="image"
                            accept="image/png, image/gif, image/jpeg"
                            className="cursor-pointer opacity-0 position-absolute top-0 bottom-0 start-0 end-0 m-auto input-file"
                            onChange={onChangePicture}
                          />
                          <div className="edit-image d-flex align-items-center justify-content-center -h-50 -w-50 rounded-pill text-white">
                            <i className="fas fa-edit fs-6"></i>
                          </div>
                          <p className="mb-0 -fs-7 -text-primary fw-semibold">
                            Edit Your Profile
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Edit_Profile;
